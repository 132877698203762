.container {
  background: var(--grey0);
  padding: 24px;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.title {
  justify-content: space-between;
}
.dividerBold {
  height: 2px;
  background: var(--grey10);
  width: 100%;
  margin: 2rem 0;
}
.divider {
  height: 1px;
  background: var(--grey10);
  width: 100%;
  margin: 1.25rem 0;
}
.container > div[class*="container"] {
  box-shadow: none;
}
.container > div[class*="container"] > label[class*="control"] {
  margin-left: 0 !important;
}

.email_checkbox_secondline {
  display: none;
}
.chips {
  margin-top: 1rem;
  width: 100% !important;
}
.title_wrapper {
  margin-bottom: 1rem;
}

@media screen and (min-width: 640px) {
  .title {
    display: flex;
  }
  .chips {
    width: fit-content !important;
  }
  .title_wrapper {
    margin-bottom: 0;
  }
  div[class*="_type_helperText"] {
    margin-top: 2px;
  }
}

@media screen and (min-width: 768px) {
  .email_checkbox_secondline {
    color: var(--grey60);
    display: inline;
  }
}
