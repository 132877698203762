.container {
  background: var(--grey0);
  border-radius: 0.5rem;
  padding: 40px 0;
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
}
.wrapper {
  width: 100vw;
  max-width: 448px;
  padding: 0 1.5rem;
}

.dropdown {
  max-width: 448px;
  width: calc(100vw - 3rem);
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.menu {
  max-width: 450px;
  width: calc(100vw - 3rem);
  margin-bottom: 0.25rem;
}

.link,
.link:hover {
  color: var(--blue50);
}
.line {
  flex: auto;
  background: var(--grey10);
  height: 1px;
  margin: 2rem 0;
}

.text_input {
  margin-bottom: 1.5rem;
}
.text_input_zip {
  margin-bottom: 1.5rem;
  width: 50%;
}

.card_cvc_wrapper {
  display: flex;
}
.card__exp {
  flex: 50%;
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
}

@media (min-width: 540px) {
  .wrapper {
    padding: 0;
  }
}
