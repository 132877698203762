.container {
  background: var(--grey0);
  border-radius: 0.5rem;
  padding: 40px 0;
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.wrapper {
  width: 100vw;
  max-width: 448px;
  padding: 0 1.5rem;
}
.card {
  margin-top: 1.5rem;
  box-shadow: none;
  background: transparent;
}
.symbol_counter {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1.5rem;
}
.additional_line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.75rem;
}

.no_account_wraper {
  display: flex;
  align-items: center;
  margin: 1.5rem 0 0 0;
  flex-direction: column;
}
.no_account_label {
  display: flex;
  align-items: center;
  width: 100%;
}
.no_account_line {
  flex: auto;
  background: var(--grey10);
  height: 1px;
}
.no_account_text {
  margin: 0 0.5rem;
}
.social_buttons {
  display: flex;
  margin: 1.5rem 0 0 0;
  justify-content: space-evenly;
  width: 100%;
}
@media (min-width: 540px) {
  .social_buttons {
    justify-content: space-between;
  }
  .wrapper {
    padding: 0;
  }
  .card {
    padding: 40px;
    background-color: var(--white);
    box-shadow: var(--shadow2dp);
    border: none;
  }
}
