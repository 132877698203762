.checkbox {
  padding: 0 !important;
  background-color: transparent !important;
  color: unset !important;
}

li[role="option"] {
  background: transparent !important;
}
li[role="option"]:hover {
  background: linear-gradient(
    90deg,
    var(--gradGreyRev, var(--gradient-prim-reverse))
  ) !important;
  color: var(--white) !important;
}
/*checkbox*/
.flat tr[aria-checked="false"]:hover svg,
.flat-head-unchecked,
.raised tr[aria-checked="false"]:hover svg,
.raised-head-unchecked,
.smooth tr[aria-checked="false"]:hover svg,
.smooth-head-unchecked {
  color: var(--flat-color-table, var(--flat-def-color-table)) !important;
}
.flat-head-checked svg,
.smooth-head-checked svg,
.raised-head-checked svg {
  color: var(--color100) !important;
}
.flat-body-checked svg,
.smooth-body-checked svg,
.raised-body-checked svg {
  color: var(--flat-color-checkbox, var(--flat-def-color-checkbox)) !important;
}
.flat-body-unchecked svg,
.flat-body-unchecked span,
.flat-body-checked span,
.smooth-body-unchecked svg,
.smooth-body-unchecked span,
.smooth-body-checked span,
.raised-body-unchecked svg,
.raised-body-unchecked span,
.raised-body-checked span {
  color: var(--grey30) !important;
}
.filled tr[aria-checked="false"]:hover svg,
.filled-head-unchecked,
.filled-head-checked svg,
.filled-body-checked svg,
.filled-body-checked span {
  color: var(--white) !important;
}
.filled-body-unchecked svg,
.filled-body-unchecked span {
  color: var(
    --filled-color-checkbox,
    var(--filled-def-color-checkbox)
  ) !important;
}

/*FLAT*/
.flat th {
  color: var(--flat-color-table, var(--flat-def-color-table)) !important;
}
.flat thead th {
  border-bottom-color: var(
    --flat-border2-table,
    var(--flat-def-border2-table)
  ) !important;
}
.flat td {
  border-bottom: 1px solid
    var(--flat-border-table, var(--flat-def-border-table)) !important;
  color: var(--grey100) !important;
}
.flat tbody > tr:hover {
  background-color: var(--grey0) !important;
}
.flat tbody > tr:last-of-type > td {
  border-bottom: 1px solid
    var(--flat-borderlast-table, var(--flat-def-borderlast-table)) !important;
}
.dense.flat td {
  border-bottom: 1px solid
    var(--flat-bg-table-hover, var(--flat-def-bg-table-hover));
  color: var(--grey100) !important;
}

.flat tr[aria-checked="true"] td,
.flat tr[aria-checked="true"]:hover td {
  background: var(
    --flat-bg-table-hover,
    var(--flat-def-bg-table-hover)
  ) !important;
  border-bottom: 1px solid
    var(--flat-borderlast-table, var(--flat-def-borderlast-table)) !important;
}

/*FILLED*/
.filled thead {
  background-color: var(
    --filled-bg-head-table,
    var(--filled-def-bg-head-table)
  ) !important;
  border-radius: 4px !important;
}
.filled thead th:last-of-type {
  border-radius: 0px 4px 0px 0px !important;
}
.filled thead th:first-of-type {
  border-radius: 4px 0px 0px 0px !important;
}
.filled th {
  color: var(--white) !important;
}
.filled thead span:hover {
  color: var(--white) !important;
}
.filled td {
  color: var(--white) !important;
}
.filled tr > td {
  border-bottom: 1px solid
    var(--filled-border-table, var(--filled-def-border-table)) !important;
  /*color: var(--white) !important;*/
}
.filled tr:last-of-type > td {
  border-bottom: 1px solid
    var(--filled-border-table, var(--filled-def-border-table)) !important;
  /*color: var(--white) !important;*/
}
.filled tbody > tr:hover td {
  border-bottom: 1px solid
    var(--filled-border-table, var(--filled-def-border-table)) !important;
  /*border-top: 1px solid
    var(--filled-border-table, var(--filled-def-border-table)) !important;*/
}
.filled thead th {
  border-bottom-color: var(
    --filled-border2-table,
    var(--filled-def-border2-table)
  ) !important;
}
.filled tbody > tr:hover {
  background-color: var(
    --filled-bg-table-hover,
    var(--filled-def-bg-table-hover)
  ) !important;
}
.filled tbody > tr {
  background-color: var(
    --filled-bg-table,
    var(--filled-def-bg-table)
  ) !important;
}
.filled tr[aria-checked="true"] td,
.filled tr[aria-checked="true"]:hover td {
  background-color: var(
    --filled-bg-table-hover,
    var(--filled-def-bg-table-hover)
  ) !important;
  border-bottom: 1px solid
    var(--filled-border-table, var(--filled-def-border-table)) !important;
}

.filled tbody > tr:last-of-type > td,
.smooth tbody > tr:last-of-type > td,
.raised tbody > tr:last-of-type > td {
  /* border: none !important;*/
}
.filled tbody > tr:last-of-type > td:last-of-type,
.smooth tbody > tr:last-of-type > td:last-of-type,
.raised tbody > tr:last-of-type > td:last-of-type {
  border-radius: 0 0 4px 0 !important;
}
.filled tbody > tr:last-of-type > td:first-of-type,
.smooth tbody > tr:last-of-type > td:first-of-type,
.raised tbody > tr:last-of-type > td:first-of-type {
  border-radius: 0 0 0 4px !important;
}

.pagination tbody > tr:last-of-type > td:last-of-type {
  border-radius: 0 !important;
}
.pagination tbody > tr:last-of-type > td:first-of-type {
  border-radius: 0 !important;
}
/*SMOOTH*/
.smooth thead {
  background-color: var(
    --smooth-bg-head-table,
    var(--smooth-def-bg-head-table)
  ) !important;
  border-radius: 4px !important;
}
.smooth thead th:last-of-type {
  border-radius: 0px 4px 0px 0px !important;
}
.smooth thead th:first-of-type {
  border-radius: 4px 0px 0px 0px !important;
}
.smooth thead th {
  border-bottom-color: var(
    --smooth-border2-table,
    var(--smooth-def-border2-table)
  ) !important;
  color: var(--smooth-color-table, var(--smooth-def-color-table)) !important;
}
.smooth td {
  border-bottom: 1px solid
    var(--smooth-border-table, var(--smooth-def-border-table)) !important;
  color: var(--grey100) !important;
}
.smooth tbody > tr {
  background-color: var(
    --smooth-bg-table,
    var(--smooth-def-bg-table)
  ) !important;
}
.smooth tbody > tr:hover td {
  background-color: var(--white) !important;
}
.smooth tr:last-of-type td {
  border-bottom: 1px solid
    var(--smooth-border-table, var(--smooth-def-border-table)) !important;
}
.smooth tr[aria-checked="true"] td,
.smooth tr[aria-checked="true"]:hover td {
  background: var(--smooth-bg-active, var(--smooth-def-bg-active)) !important;
  border-bottom: 1px solid
    var(--smooth-border2-table, var(--smooth-def-border2-table)) !important;
}

/*RAISED*/
.raised thead {
  background-color: var(--white) !important;
  border-radius: 4px !important;
}
.raised thead th:last-of-type {
  border-radius: 0px 4px 0px 0px !important;
}
.raised thead th:first-of-type {
  border-radius: 4px 0px 0px 0px !important;
}
.raised thead th {
  border-bottom-color: var(
    --raised-border2-table,
    var(--raised-def-border2-table)
  ) !important;
  color: var(--raised-color-table, var(--raised-def-color-table)) !important;
}
.raised td {
  border-bottom: 1px solid
    var(--raised-border-table, var(--raised-def-border-table)) !important;
  color: var(--grey100) !important;
}
.dense.raised td {
  border-bottom: 1px solid
    var(--flat-bg-table-hover, var(--flat-def-bg-table-hover));
  color: var(--grey100) !important;
}
.raised tbody > tr {
  background-color: var(--white) !important;
}
.raised tbody > tr:hover {
  background-color: var(--grey0) !important;
}
.raised tr:last-of-type td {
  border-bottom: 1px solid
    var(--raised-border-table, var(--raised-def-border-table)) !important;
}
.dense.raised tr:last-of-type td {
  border-bottom: 1px solid
    var(--flat-bg-table-hover, var(--flat-def-bg-table-hover));
}
.raised tr[aria-checked="true"] td,
.raised tr[aria-checked="true"]:hover td {
  background: var(--smooth-bg-table, var(--smooth-def-bg-table)) !important;
  border-bottom: 1px solid
    var(--raised-border2-table, var(--raised-def-border2-table)) !important;
}

.flat thead span:hover,
.smooth thead span:hover,
.raised thead span:hover {
  color: var(--color100) !important;
}

.flat tr:hover td,
.smooth tr:hover td,
.raised tr:hover td {
  border-bottom: 1px solid
    var(--raised-border-table, var(--raised-def-border-table)) !important;
}

/*SORTING STYLE*/
/*Smooth, raised, flat*/
.smooth.sorted_head,
.flat.sorted_head,
.raised.sorted_head {
  background: var(--grey0) !important;
  border-bottom: 2px solid var(--smooth-bg-active, var(--smooth-def-bg-active)) !important;
}
.dense_smooth.sorted_head,
.dense_flat.sorted_head,
.dense_raised.sorted_head {
  background: var(--grey0) !important;
  border-bottom: 1px solid var(--smooth-bg-active, var(--smooth-def-bg-active)) !important;
}
.smooth.sorted_body,
.flat.sorted_body,
.raised.sorted_body {
  background: var(--grey0) !important;
  border-color: var(--grey0) !important;
  border-radius: 0px !important;
}
.dense_smooth.sorted_body,
.dense_flat.sorted_body,
.dense_raised.sorted_body {
  background: var(--grey0) !important;
  border-bottom: 1px solid var(--grey0) !important;
  border-radius: 0px !important;
}

.smooth.sorted_head span,
.flat.sorted_head span,
.raised.sorted_head span,
.dense_smooth.sorted_head span,
.dense_flat.sorted_head span,
.dense_raised.sorted_head span {
  color: var(--color100) !important;
}
.smooth.sorted_head svg,
.flat.sorted_head svg,
.raised.sorted_head svg,
.dense_smooth.sorted_head svg,
.dense_flat.sorted_head svg,
.dense_raised.sorted_head svg {
  color: var(--color100) !important;
}
/*Filled*/
.filled.sorted_head {
  background: var(
    --filled-head-table-sort,
    var(--filled-def-head-table-sort)
  ) !important;
  border-bottom: 2px solid var(--filled-bg-table, var(--filled-def-bg-table)) !important;
}
.dense_filled.sorted_head {
  background: var(
    --filled-head-table-sort,
    var(--filled-def-head-table-sort)
  ) !important;
  border-bottom: 1px solid var(--filled-bg-table, var(--filled-def-bg-table)) !important;
}
.filled.sorted_head span,
.dense_filled.sorted_head span {
  color: var(--white) !important;
}
.filled.sorted_head svg,
.dense_filled.sorted_head svg {
  color: var(--white) !important;
}
.filled.sorted_body,
.dense_filled.sorted_body {
  border-color: var(
    --filled-bg-table-hover,
    var(--filled-def-bg-table-hover)
  ) !important;
  background: var(
    --filled-bg-table-hover,
    var(--filled-def-bg-table-hover)
  ) !important;
  border-radius: 0px !important;
}

/*PAGINATION*/
/*Flat*/
.flat-pag span {
  color: var(--grey60) !important;
}
.flat-pag svg,
.flat-pag button span {
  color: var(
    --flat-color-table-pag,
    var(--flat-def-color-table-pag)
  ) !important;
}
.flat-pag div > div > div {
  color: var(
    --flat-color-table-pag,
    var(--flat-def-color-table-pag)
  ) !important;
}
.flat-pag div > div > div:focus,
.filled-pag div > div > div:focus,
.smooth-pag div > div > div:focus,
.raised-pag div > div > div:focus {
  background-color: transparent !important;
}
.flat-pag button:hover {
  background-color: var(--white) !important;
  box-shadow: var(--raised-shadow, var(--raised-def-shadow)) !important;
}
/*Filled*/
.filled-pag {
  background-color: var(
    --filled-bg-table,
    var(--filled-def-bg-table)
  ) !important;
  /* border-top: 1px solid
    var(--filled-border-table, var(--filled-def-border-table)) !important;*/
  border-radius: 0 0 4px 4px !important;
  color: var(--white) !important;
}
.filled-pag svg {
  color: var(--white) !important;
}
/*Smooth*/
.smooth-pag {
  background-color: var(
    --smooth-bg-table,
    var(--smooth-def-bg-table)
  ) !important;
  /* border-top: 1px solid
    var(--smooth-border-table, var(--smooth-def-border-table)) !important;*/
  border-radius: 0 0 4px 4px !important;
  color: var(
    --smooth-color-table-pag,
    var(--smooth-def-color-table-pag)
  ) !important;
}
.smooth-pag svg {
  color: var(
    --smooth-color-table-pag,
    var(--smooth-def-color-table-pag)
  ) !important;
}
.def.smooth-pag button {
  background-color: var(--white) !important;
  border: 1px solid var(--color30) !important;
}
/*.dense.smooth-pag button {
  border: none !important;
}*/
.smooth-pag button:hover {
  border-color: var(
    --smooth-color-table-pag,
    var(--smooth-def-color-table-pag)
  ) !important;
}
/*Raised*/
.raised-pag {
  /* border-top: 1px solid
    var(--raised-border-table, var(--raised-def-border-table)) !important;*/
  color: var(
    --smooth-color-table-pag,
    var(--smooth-def-color-table-pag)
  ) !important;
}
.raised-pag svg {
  color: var(
    --smooth-color-table-pag,
    var(--smooth-def-color-table-pag)
  ) !important;
}
.raised-pag button:hover {
  background-color: var(--color5) !important;
}

/*TRASH BUTTON*/
.flat-trash,
.smooth-trash,
.raised-trash {
  color: var(
    --filled-bg-head-table,
    var(--filled-def-bg-head-table)
  ) !important;
}
.flat-trash-btn,
.smooth-trash-btn,
.raised-trash-btn {
  color: var(--red50) !important;
}
.flat-trash-btn:hover,
.smooth-trash-btn:hover,
.raised-trash-btn:hover {
  background-color: transparent !important;
  color: var(--red60) !important;
}

.filled-trash,
.filled-trash-btn {
  color: var(--white) !important;
}
