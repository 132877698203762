.container {
  width: 100%;
  max-width: 90vw;
  overflow-x: auto;
  box-shadow: none !important;
}

@media (min-width: 640px) {
  .container {
    max-width: initial;
  }
}
