.container {
  display: flex;
  width: 100%;
}

.wrapper {
  height: 400px;
  margin-bottom: 2rem; /*mobile*/
}
.wrapper svg > g:hover {
  cursor: pointer;
}
.table_wrapper {
  width: calc(100% - 1rem);
  margin: auto;
  padding: 0 0.5rem;
}
.table_wrapper > div {
  width: calc(100%);
  overflow-y: hidden;
}
.table_wrapper > div > table {
  overflow-x: scroll;
}

.legend {
  justify-content: space-around;
  flex-direction: column;
}
.legend,
.legend > div {
  display: flex;
  align-items: center;
}
.legend > div {
  padding: 0.75rem;
}
.legend > div > div {
  margin-right: 20px;
}
.legend .dot {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 0.5rem;
}

div[class*="google-visualization-tooltip"] {
  border: none;
  box-shadow: none;
  background: transparent;
  animation: fade-in-bck 0.25s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  height: 1px;
  width: 1px;
  /* position: relative; */
}
.tooltip {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: var(--shadow);
  border-radius: 1rem;
  padding: 0.75rem 1rem;
  margin: -1rem;
  background: var(--bg);
  color: var(--color);
  overflow: hidden;
  position: relative;
  /* top: 36px; */
  width: fit-content;
}
.tooltip > .label {
  font-weight: normal;
  font-size: 20px;
  color: var(--grey100);
  letter-spacing: 0.0075em;
}
.tooltip > .value {
  font-size: 34px;
  font-family: Inter;
  font-weight: bold;
  line-height: 128%;
}

@keyframes fade-in-bck {
  0% {
    transform: translateZ(80px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0);
    opacity: 1;
  }
}

@media (min-width: 640px) {
  .table_wrapper {
    padding: 0 1rem;
  }
}
