.container {
  margin: 2.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.up {
  color: var(--green70);
}
.down {
  color: var(--red70);
}
.cards_row {
  display: flex;
  margin: 1rem;
}
.cards_row > div {
  flex: 1 1 auto;
}
.item__icon {
  color: var(--color40);
  justify-content: flex-start;
  display: flex;
}
.card_row_add {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* max-width: 290px; */
  margin: 1rem 3rem;
}
.card_row_add > div {
  margin-bottom: 0.5rem;
}
