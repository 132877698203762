.container {
  padding-right: 1rem;
  padding-left: 1rem;
}
.sub_title {
  padding-top: 1rem;
}
.sub_title__item {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}
.h4_title {
  padding-top: 1rem;
}
.h6_title {
  padding-top: 1rem;
}
.text {
  padding-top: 0.5rem;
}
.link,
.link:active {
  color: var(--blue60);
  margin: 0 0.25rem;
  text-decoration: underline;
}
.link:hover {
  color: var(--blue70);
}
.promo_group {
  display: flex;
  flex-direction: column;
}
.promo_item {
  width: 100%;
}
.item_img {
  width: 100%;
}
.item_img_container {
  margin-top: auto;
  margin-bottom: 0;
  border-radius: 0.5rem;
  border: 1px solid;
  padding: 0.25rem;
}
.text_warning {
  color: var(--yellow80);
  font-weight: var(--font_weight_body_medium);
}

@media screen and (min-width: 640px) {
  .promo_group {
    display: flex;
    flex-direction: initial;
    justify-content: space-between;
  }
  .promo_item {
    width: calc(50% - 0.75rem);
    display: flex;
    flex-direction: column;
  }
  .h6_title {
    padding-top: 1.5rem;
  }
}
