/*NAVBAR*/
.flat {
  box-shadow: none;
  background: var(--white);
}
.smooth {
  box-shadow: none;
  background: var(--color5);
}
.raised {
  box-shadow: var(--shadow2dp);
  background: var(--white);
}
.filled {
  box-shadow: var(--shadow2dp);
  background: var(--color70);
}
