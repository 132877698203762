.container {
  width: 100%;
  overflow-x: auto;
  max-width: 90vw;
  margin: 1rem 0 5rem;
  box-shadow: none !important;
}
.container_title {
  display: flex;
  justify-content: space-between;
  margin-top: 2.5rem;
}

@media (min-width: 640px) {
  .container {
    max-width: initial;
  }
}
