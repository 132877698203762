/* ICON ANIMATION*/

/*.leftSidebar li[class*="selected"] > div:first-of-type > span[class*="icon"] {
  animation: shake 180ms ease-in;
}
@keyframes shake {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(-1);
  }
}*/

.leftSidebar {
  padding-left: 0;
  padding-right: 0;
  margin-right: 0.75rem;
  margin-left: 0.25rem;
}
