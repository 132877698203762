/*FLAT STYLE*/
.flat {
  color: var(--color100);
}
.flat div[class*="DayPicker-Day"] > div[class*="day-wrapper"] {
  border: none !important;
  padding: unset;
}
.flat div[class*="DayPicker-Day"][class*="DayPicker-Day--selected"],
.flat div[class*="DayPicker-Day"][class*="DayPicker-Day--selected"]:hover {
  background: var(--color5);
  color: var(--color100);
  border-radius: 50%;
}

.flat div[class*="divider"] {
  margin: 0.5rem;
  border-right: 1px solid var(--color5);
  border-bottom: 1px solid var(--color5);
}

/*today*/
.flat div[class*="DayPicker-Day--isToday"] {
  color: var(--color100);
}
.flat div[class*="DayPicker-Day--isToday"] {
  box-shadow: inset 0 0 0 1px var(--color30);
}
.flat div[class*="DayPicker-Day--selected"] {
  background-color: var(--color5);
  box-shadow: inset 0 0 0 2px var(--color60);
}
/*end today*/

/*shortcut*/
.flat ul[class*="daterangepicker-shortcuts"] a[class*="menu-item"] {
  color: var(--color70);
}
.flat
  ul[class*="daterangepicker-shortcuts"]
  a[class*="menu-item"][class*="active"],
.flat ul[class*="daterangepicker-shortcuts"] a[class*="menu-item"]:hover {
  background: var(--color5);
}
.flat
  ul[class*="daterangepicker-shortcuts"]
  a[class*="menu-item"][class*="active"] {
  font-weight: 500;
}
/*end*/

/*navbar*/
.flat div[class*="datepicker-navbar"] > button {
  width: 2.5rem;
  border-radius: 50%;
  color: var(--color70);
}
.flat div[class*="datepicker-navbar"] > button:hover {
  color: var(--color100);
  box-shadow: var(--shadow2dp);
}
/*end*/

/*month selector*/
.flat div[class*="datepicker-month-select"] > select,
.flat div[class*="datepicker-year-select"] > select {
  color: var(--color70);
}
.flat div[class*="datepicker-month-select"] > select:hover,
.flat div[class*="datepicker-year-select"] > select:hover {
  background: var(--color5);
  color: var(--color100);
}
.flat div[class*="datepicker-month-select"] > span[class*="icon"],
.flat div[class*="datepicker-year-select"] > span[class*="icon"] {
  color: var(--color70);
}
/*end*/

/*footer*/
.flat div[class*="datepicker-footer"] > button:hover {
  background: var(--color5);
}
/*end*/

.flat div[class*="DayPicker-Day--outside"] > div[class*="day-wrapper"] {
  color: var(--color40);
}
.flat div[class*="DayPicker-Day"]:hover {
  background: var(--color0);
}
/*END STYLE*/

/*SMOOTH STYLE*/
.smooth {
  color: var(--color100);
  background: var(--color0);
}
.smooth div[class*="DayPicker-Day"] > div[class*="day-wrapper"] {
  border: none !important;
  padding: unset;
}
.smooth div[class*="DayPicker-Day"][class*="DayPicker-Day--selected"],
.smooth div[class*="DayPicker-Day"][class*="DayPicker-Day--selected"]:hover {
  background: var(--color5);
  color: var(--color100);
  border-radius: 50%;
}

.smooth div[class*="divider"] {
  margin: 0.5rem;
  border-right: 1px solid var(--color5);
  border-bottom: 1px solid var(--color5);
}

/*today*/
.smooth div[class*="DayPicker-Day--isToday"] {
  color: var(--color100);
}
.smooth div[class*="DayPicker-Day--isToday"] {
  box-shadow: inset 0 0 0 1px var(--color30);
}
.smooth div[class*="DayPicker-Day--selected"] {
  background-color: var(--color5);
  box-shadow: inset 0 0 0 2px var(--color60);
}
/*end today*/

/*shortcut*/
.smooth ul[class*="daterangepicker-shortcuts"] {
  background: var(--color0);
}
.smooth ul[class*="daterangepicker-shortcuts"] a[class*="menu-item"] {
  color: var(--color70);
}
.smooth
  ul[class*="daterangepicker-shortcuts"]
  a[class*="menu-item"][class*="active"],
.smooth ul[class*="daterangepicker-shortcuts"] a[class*="menu-item"]:hover {
  background: var(--color0);
  box-shadow: 0 0 0 1px var(--color30);
}
.smooth
  ul[class*="daterangepicker-shortcuts"]
  a[class*="menu-item"][class*="active"] {
  font-weight: 500;
}
/*end*/

/*navbar*/
.smooth div[class*="datepicker-navbar"] > button {
  width: 2.5rem;
  border-radius: 50%;
  color: var(--color70);
}
.smooth div[class*="datepicker-navbar"] > button:hover {
  color: var(--color100);
  box-shadow: var(--shadow2dp);
}
/*end*/

/*month selector*/
.smooth div[class*="datepicker-month-select"] > select,
.smooth div[class*="datepicker-year-select"] > select {
  color: var(--color70);
}
.smooth div[class*="datepicker-month-select"] > select:hover,
.smooth div[class*="datepicker-year-select"] > select:hover {
  background: var(--color5);
  color: var(--color100);
}
.smooth div[class*="datepicker-month-select"] > span[class*="icon"],
.smooth div[class*="datepicker-year-select"] > span[class*="icon"] {
  color: var(--color70);
}
/*end*/

/*footer*/
.smooth div[class*="datepicker-footer"] > button:hover {
  background: var(--color5);
}
/*end*/

.smooth div[class*="DayPicker-Day--outside"] > div[class*="day-wrapper"] {
  color: var(--color40);
}
.smooth div[class*="DayPicker-Day"]:hover {
  background: var(--color0);
}
/* END STYLE */

/* OUTLINED STYLE*/
.outlined {
}

/* END STYLE */

/* RAISED STYLE */
.raised {
  color: var(--color100);
  background: var(--white);
  box-shadow: var(--shadow16dp);
}
.raised div[class*="DayPicker-Day"] > div[class*="day-wrapper"] {
  border: none !important;
  padding: unset;
}
.raised div[class*="DayPicker-Day"][class*="DayPicker-Day--selected"],
.raised div[class*="DayPicker-Day"][class*="DayPicker-Day--selected"]:hover {
  background: var(--color5);
  color: var(--color100);
  border-radius: 50%;
}

.raised div[class*="divider"] {
  margin: 0.5rem;
  border-right: 1px solid var(--color5);
  border-bottom: 1px solid var(--color5);
}

/*today*/
.raised div[class*="DayPicker-Day--isToday"] {
  color: var(--color100);
}
.raised div[class*="DayPicker-Day--isToday"] {
  box-shadow: inset 0 0 0 1px var(--color30);
}
.raised div[class*="DayPicker-Day--selected"] {
  background-color: var(--color5);
  box-shadow: inset 0 0 0 2px var(--color60);
}
/*end today*/

/*shortcut*/
.raised ul[class*="daterangepicker-shortcuts"] a[class*="menu-item"] {
  color: var(--color70);
}
.raised
  ul[class*="daterangepicker-shortcuts"]
  a[class*="menu-item"][class*="active"],
.raised ul[class*="daterangepicker-shortcuts"] a[class*="menu-item"]:hover {
  background: var(--color0);
  box-shadow: var(--shadow4dp);
}
.raised
  ul[class*="daterangepicker-shortcuts"]
  a[class*="menu-item"][class*="active"] {
  font-weight: 500;
}
/*end*/

/*navbar*/
.raised div[class*="datepicker-navbar"] > button {
  width: 2.5rem;
  border-radius: 50%;
  color: var(--color70);
}
.raised div[class*="datepicker-navbar"] > button:hover {
  color: var(--color100);
  box-shadow: var(--shadow2dp);
}
/*end*/

/*month selector*/
.raised div[class*="datepicker-month-select"] > select,
.raised div[class*="datepicker-year-select"] > select {
  color: var(--color70);
}
.raised div[class*="datepicker-month-select"] > select:hover,
.raised div[class*="datepicker-year-select"] > select:hover {
  background: var(--color5);
  color: var(--color100);
}
.raised div[class*="datepicker-month-select"] > span[class*="icon"],
.raised div[class*="datepicker-year-select"] > span[class*="icon"] {
  color: var(--color70);
}
/*end*/

/*footer*/
.raised div[class*="datepicker-footer"] > button:hover {
  background: var(--color0);
  box-shadow: var(--shadow4dp);
}
/*end*/

.raised div[class*="DayPicker-Day--outside"] > div[class*="day-wrapper"] {
  color: var(--color40);
}
.raised div[class*="DayPicker-Day"]:hover {
  background: var(--color0);
}
/* END STYLE */
