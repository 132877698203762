/**CALLOUT*/

.def[class*="callout"] {
  padding: 0.75rem 1rem 0.75rem 3.5rem;
  border-radius: 0.5rem;
  font-size: var(--font_size_body);
}
.dense[class*="callout"] {
  padding: 0.5rem 0.75rem 0.5rem 3rem;
  border-radius: 0.25rem;
  font-size: var(--font_size_body_small);
}
.def[class*="callout"] span[class*="icon"]:first-child {
  float: left;
  position: absolute;
  top: 1.125rem;
  left: 1.375rem;
}
.dense[class*="callout"] span[class*="icon"]:first-child {
  float: left;
  position: absolute;
  top: 0.875rem;
  left: 1.125rem;
}
.def[class*="callout"] h4[class*="heading"] {
  font-size: var(--font_size_h5);
  font-family: var(--font_primary);
  font-weight: var(--font_weight_h5);
  letter-spacing: var(--letter_spacing_h5);
  line-height: 2.25rem;
  margin-bottom: 0.5rem;
}
.dense[class*="callout"] h4[class*="heading"] {
  font-size: var(--font_size_h6);
  font-family: var(--font_primary);
  font-weight: var(--font_weight_h6);
  letter-spacing: var(--letter_spacing_h6);
  line-height: 1.875rem;
  margin-bottom: 0.25rem;
}
