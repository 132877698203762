.container {
  padding-right: 1rem;
  padding-left: 1rem;
}
.sub_title {
  padding-top: 1rem;
}
.h4_title {
  padding-top: 1rem;
}
.h6_title {
  padding-top: 1.5rem;
}
.text {
  padding-top: 0.5rem;
}
.link,
.link:active {
  color: var(--blue60);
  margin: 0 0.25rem;
  text-decoration: underline;
}
.link:hover {
  color: var(--blue70);
}
.promo_group {
  display: flex;
  flex-direction: column;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.promo_item {
  width: 100%;
  padding-bottom: 1.5rem;
}
.item_img_container {
  border-radius: 0.5rem;
  padding: 0.25rem;
  border: 1px solid;
}
.item_img {
  width: 100%;
}

@media screen and (min-width: 640px) {
  .promo_group {
    display: flex;
    flex-direction: initial;
    justify-content: space-between;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .promo_item {
    width: calc(25% - 0.75rem);
    padding-bottom: 1.5rem;
  }
}
