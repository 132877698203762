:root {
  --transitionFunc: cubic-bezier(0, 0, 0, 1);
}

.wrapper {
  width: fit-content;
  height: fit-content;
  /* background: var(--color10);*/
  padding: 1rem;
  cursor: pointer;
}
/*--card--*/
.round {
  border-radius: var(--s8);
}
/*--circle--*/
.circle {
  border-radius: 50%;
}

.hamburger {
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: var(--transitionFunc);
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.hamburger.is-active .hamburger-bar {
  background-color: currentcolor;
}

.hamburger-bar {
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}
.hamburger-bar,
.hamburger-bar--top,
.hamburger-bar--bottom {
  width: 100%;
  height: inherit;
  background-color: currentcolor;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: var(--transitionFunc);
}
div [class*="round"] .hamburger-bar,
div [class*="round"] .hamburger-bar--top,
div [class*="round"] .hamburger-bar--bottom {
  border-radius: 0.25rem;
}

.hamburger-bar--top,
.hamburger-bar--bottom {
  content: "";
  display: block;
  position: absolute;
}

/** CSS animations from https://jonsuh.com/hamburgers/ */
/** Slider  */
.hamburger--slider .hamburger-bar {
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}
.hamburger--slider.is-active .hamburger-bar {
  transform: translate(-0.5rem, 0px);
  opacity: 0;
}

/** Squeeze */
.hamburger--squeeze .hamburger-bar {
  opacity: 1;
  transition: opacity 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze.is-active .hamburger-bar {
  opacity: 0;
  transition: opacity 0.075s 0.12s ease;
}

/** Elastic  */
.hamburger--elastic .hamburger-bar {
  transition: opacity 0.125s 0.275s ease;
}
.hamburger--elastic .hamburger-bar--bottom,
.hamburger--elastic .hamburger-bar--top {
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55),
    top 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55),
    bottom 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger--elastic.is-active .hamburger-bar {
  transition-delay: 0s;
  opacity: 0;
}

/** Emphatic  */
.hamburger--emphatic {
  overflow: hidden;
}
.hamburger--emphatic .hamburger-bar {
  transition: background-color 0.125s 0.175s ease-in;
}
.hamburger--emphatic.is-active .hamburger-bar {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important;
}

/** Spin   */
.hamburger--spin .hamburger-bar {
  opacity: 1;
  transition: opacity 0.1s 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin.is-active .hamburger-bar {
  opacity: 0;
  transition: opacity 0.1s 0.12s ease-out;
}
/*END*/
