.wrapper {
  padding: 0 0.5rem;
  padding-bottom: 6rem;
}

.container {
  display: flex;
  padding: 0 1rem;
  flex-direction: column;
}

.sidebar {
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  max-width: 100%;
}
.avatar {
  width: 236px;
  max-width: 236px;
  height: 236px;
  position: relative;
  margin: auto;
}
.avatar > .button {
  position: absolute;
  bottom: 0;
  right: 0;
}
.desc {
  margin-top: 8px;
}

.buttons-group {
  margin-top: 1.5rem;
}

.divider {
  height: 1px;
  background: var(--grey10);
  margin: 2rem 0;
}

.label {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.label > .icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: var(--color5);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
}

.section {
  display: flex;
  flex: auto;
  flex-direction: column;
}

.section .ref_switches {
  display: flex;
  margin-top: 1.5rem;
  flex-direction: column;
}
.section .ref_switches .column {
  margin-right: 1rem;
}
.section .ref_switches div[class*="container"] {
  margin-bottom: 1rem;
}

@media screen and (min-width: 640px) {
  .container {
    flex-direction: row;
  }
  .sidebar {
    max-width: 236px;
  }
  .avatar {
    margin: 0;
  }
  .section .ref_switches {
    flex-direction: row;
  }
  .section .ref_switches .column {
    flex: auto;
  }
}
