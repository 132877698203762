.color0 {
  background: var(--color0);
}
.color5 {
  background: var(--color5);
}
.color10 {
  background: var(--color10);
}
.color20 {
  background: var(--color20);
}
.color30 {
  background: var(--color30);
}
.color40 {
  background: var(--color40);
}
.color50 {
  background: var(--color50);
}
.color60 {
  background: var(--color60);
}
.color70 {
  background: var(--color70);
}
.color80 {
  background: var(--color80);
}
.color90 {
  background: var(--color90);
}
.color100 {
  background: var(--color100);
}
