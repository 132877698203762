.container {
  padding-right: 1rem;
  padding-left: 1rem;
}
.sub_title {
  padding-top: 1rem;
}
.h4_title {
  padding-top: 1rem;
}
.h6_title {
  padding-top: 1.5rem;
}
.text {
  padding-top: 0.5rem;
}
.text_warning {
  color: var(--yellow80);
  font-weight: var(--font_weight_body_medium);
}
.text_primary {
  color: var(--blue80);
  font-weight: var(--font_weight_body_medium);
}
.text_command__code {
  color: var(--blue100);
  background: var(--blue5);
  border-radius: 0.25rem;
  width: fit-content;
  padding: 0 0.75rem;
  line-height: 2.5rem;
  margin-top: 0.5rem;
}
.text_command__success {
  color: var(--green100);
  background: var(--green5);
  border-radius: 0.25rem;
  /*height: 2.5rem;*/
  width: fit-content;
  padding: 0 0.75rem;
  line-height: 2.5rem;
  margin-top: 0.5rem;
}
.text_command__warning {
  color: var(--yellow100);
  background: var(--yellow5);
  border-radius: 0.25rem;
  /* height: 2.5rem;*/
  width: fit-content;
  padding: 0 0.75rem;
  line-height: 2.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}
.link,
.link:active {
  color: var(--blue60);
  margin: 0 0.25rem;
  text-decoration: underline;
}
.link:hover {
  color: var(--blue70);
}
