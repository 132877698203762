.container {
  padding: 0 1rem;
}

.section_title {
  padding-top: var(--s40);
  padding-bottom: 1rem;
  text-transform: capitalize;
}
.text {
  padding-top: 1rem;
}

.icons_section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
}
.icon_item {
  height: 10rem;
  display: grid;
  color: var(--grey40);
  background: var(--grey0);
  border-radius: 0.5rem;
}
.icon_svg {
  color: var(--color70);
  padding-bottom: 1rem;
  text-align: center;
}
.icon_name {
  text-align: center;
  margin-top: auto;
}
.icon_item_container {
  height: fit-content;
  margin-top: auto;
  margin-bottom: auto;
}
.icon_name:after {
  content: attr(data-name);
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}

@media screen and (min-width: 640px) {
  .icons_section {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
  }
  .icon_name {
    margin-bottom: 1rem;
  }
  .icon_svg {
    height: fit-content;
    margin-top: auto;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .icon_item_container {
    height: 100%;
    display: grid;
  }
  .icon_item:hover {
    transform: scale(1.06, 1.04);
    box-shadow: var(--greyShadow4dp);
    cursor: pointer;
    transition: all 0.1s cubic-bezier(0.4, 1, 0.75, 0.9);
  }
  .icon_item {
    transition: all 0.1s cubic-bezier(0.4, 1, 0.75, 0.9);
  }

  .icon_item:hover .icon_name:after {
    content: "Click to copy";
    color: var(--grey60);
  }
}

@media screen and (min-width: 1024px) {
  .icons_section {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
  }
}

@media screen and (min-width: 1440px) {
  .icons_section {
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
  }
}
