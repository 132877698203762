.container {
  padding: 1rem;
  margin: 1rem;
  border-radius: 0.5rem;
  border: 1px solid var(--grey20);
}

.caption_wrapper {
  display: flex;
  justify-content: space-between;
}

.caption_dropdown {
  display: none;
}

div[class*="google-visualization-tooltip"] {
  border: none;
  box-shadow: none;
  background: transparent;
  animation: fade-in-bck 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
.tooltip {
  display: none;
  width: max-content;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-shadow: var(--shadow);
  border-radius: 1rem;
  padding: 0.75rem 1rem;
  margin: -1rem;
  background: var(--bg);
  color: var(--color);
  overflow: hidden;
}
.tooltip > .value {
  font-size: 34px;
  font-family: Inter;
  font-weight: bold;
  line-height: 128%;
}
.tooltip > .label {
  font-weight: normal;
  font-size: 20px;
  color: var(--grey100);
  letter-spacing: 0.0075em;
}
.tooltip > .year {
  font-weight: normal;
  font-size: 1rem;
  letter-spacing: 0.0275em;
  color: var(--grey60);
}
@keyframes fade-in-bck {
  0% {
    transform: translateZ(80px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0);
    opacity: 1;
  }
}
@media (min-width: 768px) {
  .caption_dropdown {
    display: block;
  }
  .tooltip {
    display: flex;
  }
}
