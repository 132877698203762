* {
  box-sizing: border-box;
}
html,
body {
  margin: 0;
  padding: 0;
}
.ava_menu {
  border: none;
  background: none;
  height: 2.5rem;
}
.navbar_ava span[class*="popover-wrapper"] {
  display: flex;
  align-items: center;
  height: 2.5rem;
}
.navbar_ava span[class*="popover-wrapper"] > span {
  /* height: 2.5rem; */
}
.ava_menu:after {
  content: none !important;
}
/*MOBILE*/
.container {
  height: 100vh;
  display: grid;
  grid-template-areas:
    "header"
    "."
    "content"
    "."
    "footer";
  grid-template-columns: 1fr;
  grid-template-rows: 6rem 1rem auto;
}

.header {
  grid-area: header;
}
.sidebar {
  grid-area: sidebar;
  height: fit-content;
  width: 240px;
  z-index: 5;
  display: block;
  transform: translateX(-100%);
  transition: all 250ms ease-in-out;
  display: contents;
  background: var(--grey0);
  border-radius: 0 0.5rem 0.5rem 0;
}
.content {
  grid-area: content;
  margin-left: 0;
  margin-right: 0;
  overflow-y: unset;
}
.content-component {
  display: grid;
  padding-right: 1rem;
  padding-left: 1rem;
}

.footer {
  grid-area: footer;
  background-color: var(--grey0);
  letter-spacing: var(--letter-spacing-body);
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  bottom: 0;
  height: 5.5rem;
  z-index: 9;
  box-shadow: 0px 8px 16px rgba(58, 58, 68, 0.12),
    0px 16px 32px rgba(90, 91, 106, 0.12);
  border-radius: 8px 8px 0px 0px;
}
.footer_links {
  color: var(--grey70);
  font-weight: 500;
  display: flex;
  font-size: var(--font_size_caption_small);
  letter-spacing: var(--letter_spacing_caption_small);
  justify-content: space-around;
}

.footer_links a {
  color: var(--grey70);
  text-decoration: none;
  margin-right: 1rem;
  text-align: center;
}
.footer_links a:last-of-type {
  margin-right: 0;
}
.footer_links a:hover {
  color: var(--blue70);
  text-decoration: none;
}
.footer_break {
  display: block;
  font-size: var(--font_size_caption);
  letter-spacing: var(--letter_spacing_caption);
}
.footer_text {
  text-align: center;
  line-height: 3rem;
  color: var(--grey100);
  font-size: var(--font_size_caption);
  letter-spacing: var(--letter_spacing_caption);
}
.props_button {
  position: fixed;
  bottom: 6.5rem;
  right: 1.5rem;
  z-index: 10;
}
.nowrap {
  white-space: nowrap;
}
.right_sidebar_backdrop {
  background-color: unset;
}
div[role="tablist"][class*="tab-list"] {
  margin-left: auto;
  margin-right: auto !important;
  width: fit-content;
}
/*-----640PX-----*/
@media screen and (min-width: 640px) {
  .container {
    height: calc(100vh);
    display: grid;
    grid-template-areas:
      "header"
      ". "
      "content "
      ". "
      "footer";
    grid-template-columns: 1fr;
    grid-template-rows: 4rem 1rem auto 1rem 5.5rem;
  }

  .content-component {
    display: grid;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-bottom: 5rem;
  }
  .content {
    margin-right: 0;
    overflow-y: unset;
    width: 100%;
  }
}
.ava_name {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 0.75rem;
}
/*-----1024PX-----*/
@media screen and (min-width: 1024px) {
  .container {
    height: 100vh;
    display: grid;
    grid-template-areas:
      "header header"
      ". ."
      "sidebar content"
      ". ."
      "footer footer";
    grid-template-columns: 240px 5fr;
    grid-template-rows: 4.5rem 1rem auto 1rem 4rem;
  }
  .sidebar {
    grid-area: sidebar;
    display: block;
    max-width: 240px;
    width: 240px;
    top: 4.5rem;
    transform: unset;
    box-shadow: none;
    height: auto;
    background: none;
  }

  .content-component {
    display: grid;
    width: 100%;
    float: left;
  }
  .content {
    margin-right: 0;
    margin-left: 0;
    width: 100%;
    padding: 0 1rem;
  }
  .nowrap {
    white-space: initial;
  }
  .footer {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    height: 4rem;
    position: absolute;
    width: 100vw;
    left: 0;
  }
  .props_button {
    bottom: 5.5rem;
  }
}

@media screen and (min-width: 1440px) {
  div[id="root"] {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
  }
  .container {
    height: calc(100vh);
    display: grid;
    grid-template-areas:
      "header header"
      ". ."
      "sidebar content"
      ". ."
      "footer footer";
    grid-template-columns: 200px 1fr;
    grid-template-rows: 4.5rem 1rem auto 0 4rem;
  }
  .sidebar {
    width: 200px;
    max-width: 200px;
  }
  .content {
    padding: 0;
    padding-left: 0.75rem;
  }
  .content-component {
    width: calc(100% - 200px);
    padding: 0;
  }
}
