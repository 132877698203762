.container {
  background: var(--grey0);
  border-radius: 0.5rem;
  padding: 1rem 0;
}
.separator {
  border: 1px solid var(--grey10);
}
.data {
  display: flex;
  justify-content: space-around;
  margin: 1rem 1rem 0 1rem;
}
.data > .item {
  flex: 1 1 auto;
  margin-bottom: 0.5rem;
}
