@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,400;0,500;1,500&display=swap);
:root {
  /***NEW PALLETE SCHEME***/
  --black: #000000;
  --white: #ffffff;
  /*PRIMARY COLORS*/
  /*Grey*/
  --grey0: #f7f7fa;
  --grey5: #ededf0;
  --grey10: #e1e1e3;
  --grey20: #d2d2d6;
  --grey30: #b4b4bb;
  --grey40: #9696a0;
  --grey50: #787885;
  --grey60: #5a5b6a;
  --grey70: #4a4b57;
  --grey80: #3a3a44;
  --grey90: #292a31;
  --grey100: #19191d;
  /*Blue*/

  --blue0: #f5f8ff;
  --blue5: #ebf2ff;
  --blue10: #d8e6ff;
  --blue20: #c4daff;
  --blue30: #9dc2ff;
  --blue40: #76a9ff;
  --blue50: #4f91ff;
  --blue60: #2979ff;
  --blue70: #2264d1;
  --blue80: #1b4ea3;
  --blue90: #133774;
  --blue100: #0c2146;
  /*Green*/
  --green0: #f5faf5;
  --green5: #ecf7ed;
  --green10: #dceddd;
  --green20: #cbe5cc;
  --green30: #a9d3ab;
  --green40: #87c289;
  --green50: #65b168;
  --green60: #43a047;
  --green70: #37833b;
  --green80: #2b662e;
  --green90: #1f4921;
  --green100: #132c14;
  /*Yellow*/
  --yellow0: #fff8eb;
  --yellow5: #ffefd1;
  --yellow10: #ffe5b3;
  --yellow20: #ffd98f;
  --yellow30: #f5ce84;
  --yellow40: #ebbf67;
  --yellow50: #e5ae40;
  --yellow60: #d6981b;
  --yellow70: #b88217;
  --yellow80: #8f6512;
  --yellow90: #66480d;
  --yellow100: #463209;
  /*Red*/
  --red0: #fef2f1;
  --red5: #fee8e7;
  --red10: #fddcda;
  --red20: #fccbc8;
  --red30: #faa9a3;
  --red40: #f8877f;
  --red50: #f6655a;
  --red60: #f44336;
  --red70: #c8372d;
  --red80: #9c2b23;
  --red90: #6f1f19;
  --red100: #43130f;
  /*SECONDARY COLORS*/
  /*Indigo*/
  --indigo0: #f7f8fc;
  --indigo5: #eff0fa;
  --indigo10: #e1e4f3;
  --indigo20: #d2d6ed;
  --indigo30: #b4bbe2;
  --indigo40: #97a0d6;
  --indigo50: #7985cb;
  --indigo60: #5c6bc0;
  --indigo70: #4c589e;
  --indigo80: #3b457b;
  --indigo90: #2a3158;
  --indigo100: #1a1e35;
  /*Teal*/
  --teal0: #f0fafa;
  --teal5: #e5f5f5;
  --teal10: #d1ebec;
  --teal20: #bbe2e2;
  --teal30: #8dcfcf;
  --teal40: #60bcbc;
  --teal50: #33a9a9;
  --teal60: #069697;
  --teal70: #057b7c;
  --teal80: #046061;
  --teal90: #034545;
  --teal100: #02292a;
  /*Orange*/
  --orange0: #fff6ed;
  --orange5: #ffefde;
  --orange10: #fee7cd;
  --orange20: #fddcb9;
  --orange30: #fdc68b;
  --orange40: #fcaf5c;
  --orange50: #fb982e;
  --orange60: #fb8200;
  --orange70: #ce6b00;
  --orange80: #a05300;
  --orange90: #733c00;
  --orange100: #452400;
  /*Pink*/
  --pink0: #fff7fa;
  --pink5: #fdedf2;
  --pink10: #fbdce6;
  --pink20: #f9cada;
  --pink30: #f6a8c2;
  --pink40: #f285aa;
  --pink50: #ef6292;
  --pink60: #ec407a;
  --pink70: #c23564;
  --pink80: #97294e;
  --pink90: #6c1e38;
  --pink100: #411222;

  /* SHADOWS */
  --greyShadow2dp: 0px 2px 4px rgba(90, 91, 106, 0.24),
    0px 1px 2px rgba(58, 58, 68, 0.24);
  --greyShadow4dp: 0px 4px 8px rgba(90, 91, 106, 0.2),
    0px 2px 4px rgba(58, 58, 68, 0.2);
  --greyShadow8dp: 0px 8px 16px rgba(90, 91, 106, 0.16),
    0px 4px 8px rgba(58, 58, 68, 0.16);
  --greyShadow16dp: 0px 16px 32px rgba(90, 91, 106, 0.12),
    0px 8px 16px rgba(58, 58, 68, 0.12);
  --greyShadow24dp: 0px 24px 48px rgba(90, 91, 106, 0.08),
    0px 12px 24px rgba(58, 58, 68, 0.08);

  --blueShadow2dp: 0px 2px 4px rgba(41, 121, 255, 0.24),
    0px 1px 2px rgba(27, 78, 163, 0.24);
  --blueShadow4dp: 0px 4px 8px rgba(41, 121, 255, 0.2),
    0px 2px 4px rgba(27, 78, 163, 0.2);
  --blueShadow8dp: 0px 8px 16px rgba(41, 121, 255, 0.16),
    0px 4px 8px rgba(27, 78, 163, 0.16);
  --blueShadow16dp: 0px 16px 32px rgba(41, 121, 255, 0.12),
    0px 8px 16px rgba(27, 78, 163, 0.12);
  --blueShadow24dp: 0px 24px 48px rgba(41, 121, 255, 0.08),
    0px 12px 24px rgba(27, 78, 163, 0.08);

  --redShadow2dp: 0px 2px 4px rgba(252, 203, 200, 0.24),
    0px 1px 2px rgba(156, 43, 35, 0.24);
  --redShadow4dp: 0px 4px 8px rgba(244, 67, 54, 0.2),
    0px 2px 4px rgba(156, 43, 35, 0.2);
  --redShadow8dp: 0px 8px 16px rgba(244, 67, 54, 0.16),
    0px 4px 8px rgba(156, 43, 35, 0.16);
  --redShadow16dp: 0px 16px 32px rgba(244, 67, 54, 0.12),
    0px 8px 16px rgba(156, 43, 35, 0.12);
  --redShadow24dp: 0px 24px 48px rgba(244, 67, 54, 0.08),
    0px 12px 24px rgba(156, 43, 35, 0.08);

  --greenShadow2dp: 0px 2px 4px rgba(67, 160, 71, 0.24),
    0px 1px 2px rgba(43, 102, 46, 0.24);
  --greenShadow4dp: 0px 4px 8px rgba(67, 160, 71, 0.2),
    0px 2px 4px rgba(43, 102, 46, 0.2);
  --greenShadow8dp: 0px 8px 16px rgba(67, 160, 71, 0.16),
    0px 4px 8px rgba(43, 102, 46, 0.16);
  --greenShadow16dp: 0px 16px 32px rgba(67, 160, 71, 0.12),
    0px 8px 16px rgba(43, 102, 46, 0.12);
  --greenShadow24dp: 0px 24px 48px rgba(67, 160, 71, 0.08),
    0px 12px 24px rgba(43, 102, 46, 0.08);

  --yellowShadow2dp: 0px 2px 4px rgba(214, 152, 27, 0.24),
    0px 1px 2px rgba(143, 101, 18, 0.24);
  --yellowShadow4dp: 0px 4px 8px rgba(214, 152, 27, 0.2),
    0px 2px 4px rgba(143, 101, 18, 0.2);
  --yellowShadow8dp: 0px 8px 16px rgba(214, 152, 27, 0.16),
    0px 4px 8px rgba(143, 101, 18, 0.16);
  --yellowShadow16dp: 0px 16px 32px rgba(214, 152, 27, 0.12),
    0px 8px 16px rgba(143, 101, 18, 0.12);
  --yellowShadow24dp: 0px 24px 48px rgba(214, 152, 27, 0.08),
    0px 12px 24px rgba(143, 101, 18, 0.08);

  --indigoShadow2dp: 0px 2px 4px rgba(92, 107, 192, 0.24),
    0px 1px 2px rgba(59, 69, 123, 0.24);
  --indigoShadow4dp: 0px 4px 8px rgba(92, 107, 192, 0.2),
    0px 2px 4px rgba(59, 69, 123, 0.2);
  --indigoShadow8dp: 0px 8px 16px rgba(92, 107, 192, 0.16),
    0px 4px 8px rgba(59, 69, 123, 0.16);
  --indigoShadow16dp: 0px 16px 32px rgba(92, 107, 192, 0.12),
    0px 8px 16px rgba(59, 69, 123, 0.12);
  --indigoShadow24dp: 0px 24px 48px rgba(92, 107, 192, 0.08),
    0px 12px 24px rgba(59, 69, 123, 0.08);

  --tealShadow2dp: 0px 2px 4px rgba(6, 150, 151, 0.24),
    0px 1px 2px rgba(4, 96, 97, 0.24);
  --tealShadow4dp: 0px 4px 8px rgba(6, 150, 151, 0.2),
    0px 2px 4px rgba(4, 96, 97, 0.2);
  --tealShadow8dp: 0px 8px 16px rgba(6, 150, 151, 0.16),
    0px 4px 8px rgba(4, 96, 97, 0.16);
  --tealShadow16dp: 0px 16px 32px rgba(6, 150, 151, 0.12),
    0px 8px 16px rgba(4, 96, 97, 0.12);
  --tealShadow24dp: 0px 24px 48px rgba(6, 150, 151, 0.08),
    0px 12px 24px rgba(4, 96, 97, 0.08);

  --orangeShadow2dp: 0px 2px 4px rgba(251, 130, 0, 0.24),
    0px 1px 2px rgba(160, 83, 0, 0.24);
  --orangeShadow4dp: 0px 4px 8px rgba(251, 130, 0, 0.2),
    0px 2px 4px rgba(160, 83, 0, 0.2);
  --orangeShadow8dp: 0px 8px 16px rgba(251, 130, 0, 0.16),
    0px 4px 8px rgba(160, 83, 0, 0.16);
  --orangeShadow16dp: 0px 16px 32px rgba(251, 130, 0, 0.12),
    0px 8px 16px rgba(160, 83, 0, 0.12);
  --orangeShadow24dp: 0px 24px 48px rgba(251, 130, 0, 0.08),
    0px 12px 24px rgba(160, 83, 0, 0.08);

  --pinkShadow2dp: 0px 2px 4px rgba(236, 64, 122, 0.24),
    0px 1px 2px rgba(151, 41, 78, 0.24);
  --pinkShadow4dp: 0px 4px 8px rgba(236, 64, 122, 0.2),
    0px 2px 4px rgba(151, 41, 78, 0.2);
  --pinkShadow8dp: 0px 8px 16px rgba(236, 64, 122, 0.16),
    0px 4px 8px rgba(151, 41, 78, 0.16);
  --pinkShadow16dp: 0px 16px 32px rgba(236, 64, 122, 0.12),
    0px 8px 16px rgba(151, 41, 78, 0.12);
  --pinkShadow24dp: 0px 24px 48px rgba(236, 64, 122, 0.08),
    0px 12px 24px rgba(151, 41, 78, 0.08);

  /*NEW GRADIENTS*/
  --gradBlue: var(--blue60) 0%, var(--indigo70) 100%;
  --gradBlueRev: var(--indigo70) 0%, var(--blue60) 100%;
  --gradRed: var(--red60) 0%, var(--pink70) 100%;
  --gradRedRev: var(--pink70) 0%, var(--red60) 100%;
  --gradGreen: var(--green60) 0%, var(--teal70) 100%;
  --gradGreenRev: var(--teal70) 0%, var(--green60) 100%;
  --gradYellow: var(--yellow60) 0%, var(--orange70) 100%;
  --gradYellowRev: var(--orange70) 0%, var(--yellow60) 100%;
  --gradGrey: var(--grey40) 0%, var(--grey70) 100%;
  --gradGreyRev: var(--grey70) 0%, var(--grey40) 100%;

  --gradIndigo: var(--indigo60) 0%, var(--blue70) 100%;
  --gradIndigoRev: var(--blue70) 0%, var(--indigo60) 100%;
  --gradPink: var(--pink60) 0%, var(--red70) 100%;
  --gradPinkRev: var(--red70) 0%, var(--pink60) 100%;
  --gradTeal: var(--teal60) 0%, var(--green70) 100%;
  --gradTealRev: var(--green70) 0%, var(--teal60) 100%;
  --gradOrange: var(--orange60) 0%, var(--yellow70) 100%;
  --gradOrangeRev: var(--yellow70) 0%, var(--orange60) 100%;

  /*Ligth*/
  --gradBlueL: var(--blue10) 0%, var(--indigo30) 100%;
  --gradBlueRevL: var(--indigo30) 0%, var(--blue10) 100%;
  --gradRedL: var(--red10) 0%, var(--pink30) 100%;
  --gradRedRevL: var(--pink30) 0%, var(--red10) 100%;
  --gradGreenL: var(--green10) 0%, var(--teal30) 100%;
  --gradGreenRevL: var(--teal30) 0%, var(--green10) 100%;
  --gradYellowL: var(--yellow10) 0%, var(--orange30) 100%;
  --gradYellowRevL: var(--orange30) 0%, var(--yellow10) 100%;
  --gradGreyL: var(--grey10) 0%, var(--grey40) 100%;
  --gradGreyRevL: var(--grey40) 0%, var(--grey10) 100%;
}

html,
body {
  font-size: 100%;
  /*font-size: var(--font-size-Body1);*/
  font-family: var(--font-primary);
  font-style: normal;
  line-height: normal;
  line-height: initial;
  letter-spacing: var(--letter-spacing-Body1);
  font-weight: var(--font-weight-Body1);
  color: var(--grey100);
}
button {
  font-family: var(--font-primary);
}

/*Remove blue background on click*/
input,
textarea,
button,
select,
label,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
/**/
* {
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: none;
  outline-offset: 0;
}

.bp3-control input:focus ~ .bp3-control-indicator {
  outline: none;
  outline-offset: 0;
}

.bp3-button:not([class*="bp3-intent-"]) {
  box-shadow: none;
  background-color: unset;
  background-image: none;
  color: unset;
}

.bp3-button:not([class*="bp3-intent-"]):hover {
  box-shadow: none;
  background-clip: unset;
  background-color: unset;
}

.bp3-button .bp3-icon {
  color: unset;
}

label.bp3-label {
  font-size: 1rem;
  font-weight: bold;
  text-align: left;
  text-align: initial;
  margin-bottom: 0.625rem;
  margin-right: 1rem;
}

@charset "UTF-8";

:root {
  --roboto: "Roboto", sans-serif;
  --quicksand: "Quicksand", sans-serif;
  --inter: "Inter", sans-serif;
  --plex: "IBM Plex Sans", sans-serif;
  --lato: "Lato", sans-serif;
  --manrope: "Manrope", sans-serif;

  /*Font sizes*/
  --font_size_h1: 6rem;
  --font_size_h2: 3.75rem;
  --font_size_h3: 3rem;
  --font_size_h4: 2.125rem;
  --font_size_h5: 1.5rem;
  --font_size_h6: 1.25rem;
  --font_size_body: 1rem;
  --font_size_body_large: 1.25rem;
  --font_size_body_small: 0.875rem;
  --font_size_button: 0.875rem;
  --font_size_caption: 0.75rem;
  --font_size_caption_small: 0.625rem;
  --font_size_overline: 0.75rem;
  --font_size_overline_small: 0.625rem;

  /*Font weight*/
  --font_weight_h1: 700;
  --font_weight_h2: 700;
  --font_weight_h3: 700;
  --font_weight_h4: 700;
  --font_weight_h5: 700;
  --font_weight_h6: 700;
  --font_weight_body: 400;
  --font_weight_body_medium: 500;
  --font_weight_button: 700;
  --font_weight_caption: 400;
  --font_weight_caption_small: 500;
  --font_weight_overline: 600;

  /*Letter spacing*/
  --letter_spacing_h1: -0.026em;
  --letter_spacing_h2: -0.025em;
  --letter_spacing_h3: -0.01em;
  --letter_spacing_h4: 0;
  --letter_spacing_h5: 0;
  --letter_spacing_h6: 0.0075em;
  --letter_spacing_body: 0.0275em;
  --letter_spacing_body_bold: 0.022em;
  --letter_spacing_body_large: 0.0075em;
  --letter_spacing_body_large_bold: 0.004em;
  --letter_spacing_body_small: 0.018em;
  --letter_spacing_button: 0.054em;
  --letter_spacing_caption: 0.033em;
  --letter_spacing_caption_small: 0.06em;
  --letter_spacing_overline: 0.0168em;
  --letter_spacing_overline_small: 0.15em;
}

.roboto {
  font-family: "Roboto", sans-serif;
  font-family: var(--roboto);
}
.quicksand {
  font-family: "Quicksand", sans-serif;
  font-family: var(--quicksand);
}
.inter {
  font-family: "Inter", sans-serif;
  font-family: var(--inter);
}
.lato {
  font-family: "Lato", sans-serif;
  font-family: var(--lato);
}
.plex {
  font-family: "IBM Plex Sans", sans-serif;
  font-family: var(--plex);
}
.monrope {
  font-family: "Manrope", sans-serif;
  font-family: var(--manrope);
}

.h1,
.H1 {
  font-weight: 700;
  font-weight: var(--font_weight_h1);
  font-size: 6rem;
  font-size: var(--font_size_h1);
  letter-spacing: -0.026em;
  letter-spacing: var(--letter_spacing_h1);
}

.h2,
.H2 {
  font-weight: 700;
  font-weight: var(--font_weight_h2);
  font-size: 3.75rem;
  font-size: var(--font_size_h2);
  letter-spacing: -0.025em;
  letter-spacing: var(--letter_spacing_h2);
}

.h3,
.H3 {
  font-weight: 700;
  font-weight: var(--font_weight_h3);
  font-size: 3rem;
  font-size: var(--font_size_h3);
  letter-spacing: -0.01em;
  letter-spacing: var(--letter_spacing_h3);
}

.h4,
.H4 {
  font-weight: 700;
  font-weight: var(--font_weight_h4);
  font-size: 2.125rem;
  font-size: var(--font_size_h4);
  letter-spacing: 0;
  letter-spacing: var(--letter_spacing_h4);
}

.h5,
.H5 {
  font-weight: 700;
  font-weight: var(--font_weight_h5);
  font-size: 1.5rem;
  font-size: var(--font_size_h5);
  letter-spacing: 0;
  letter-spacing: var(--letter_spacing_h5);
}

.h6,
.H6 {
  font-weight: 700;
  font-weight: var(--font_weight_h6);
  font-size: 1.25rem;
  font-size: var(--font_size_h6);
  letter-spacing: 0.0075em;
  letter-spacing: var(--letter_spacing_h6);
}

.body,
.BodyBig {
  font-weight: 400;
  font-weight: var(--font_weight_body);
  font-size: 1rem;
  font-size: var(--font_size_body);
  letter-spacing: 0.0275em;
  letter-spacing: var(--letter_spacing_body);
}

.button_text {
  font-weight: 700;
  font-weight: var(--font_weight_button);
  font-size: 0.875rem;
  font-size: var(--font_size_button);
  letter-spacing: 0.054em;
  letter-spacing: var(--letter_spacing_button);
}

.caption {
  font-weight: 400;
  font-weight: var(--font_weight_caption);
  font-size: 0.75rem;
  font-size: var(--font_size_caption);
  letter-spacing: 0.033em;
  letter-spacing: var(--letter_spacing_caption);
}

.overline {
  font-weight: 600;
  font-weight: var(--font_weight_overline);
  font-size: 0.75rem;
  font-size: var(--font_size_overline);
  letter-spacing: 0.0168em;
  letter-spacing: var(--letter_spacing_overline);
  text-transform: uppercase;
}

:root {
  --step: 0.25rem;
  /* BREAKPOINTS */
  --sm: 640px;
  --md: 768px;
  --lg: 1024px;
  --xl: 1280px;

  /* SIZE STEPS */
  --s0: 0;
  --s1px: 1px;
  --sAuto: auto;
  --s100vh: 100vh;
  --s100vw: 100vw;
  --sFull: 100%;

  /*Percents*/
  --s10p: 10%;
  --s20p: 20%;
  --s25p: 25%;
  --s30p: 30%;
  --s33p: 33.333333%;
  --s40p: 40%;
  --s50p: 50%;
  --s60p: 60%;
  --s66p: 66.666667%;
  --s70p: 70%;
  --s75p: 75%;
  --s80p: 80%;
  --s90p: 90%;

  /*Steps*/
  --s4: 0.25rem; /*4px*/
  --s8: 0.5rem; /*8px*/
  --s12: 0.75rem;
  --s16: 1rem;
  --s20: 1.25rem;
  --s24: 1.5rem;
  --s28: 1.75rem;
  --s32: 2rem;
  --s36: 2.25rem;
  --s40: 2.5rem;
  --s44: 2.75rem;
  --s48: 3rem;
  --s52: 3.25rem;
  --s56: 3.5rem;
  --s60: 3.75rem;
  --s64: 4rem;
  --s68: 4.25rem;
  --s72: 4.5rem;
  --s76: 4.75rem;
  --s80: 5rem;
  --s84: 5.25rem;
  --s88: 5.5rem;
  --s92: 5.75rem;
  --s96: 6rem;
  --s100: 6.25rem; /*100px*/
  --s128: 8rem;
  --s160: 10rem;
}

:root {
  /*Fonts*/
  --font-primary: var(--inter);
  --font_primary: var(--inter);

  --font-secondary: var(--inter); /** Used for captions */
  --font_secondary: var(--inter);

  --font-family-button: var(--inter);
  --font_family_button: var(--inter);
}

/*CSS for Demo app*/

.demo-content-component {
  height: auto;
  display: block;
  padding: 1rem 0.5rem;
}

.demo-name {
  font-family: var(--inter);
  margin-bottom: 0.5rem;
}

.demo-desc {
  font-family: var(--inter);
  margin-bottom: 0.5rem;
}

.demo-api {
  margin-top: 1.5rem;
  margin-bottom: 3rem;
}
@media screen and (min-width: 640px) {
  .demo-api div[role="tablist"][class*="tab-list"] {
    margin-left: 0;
  }
}
.demo-props {
  padding: 0 0.5rem 1rem 0rem;
}
.demo-type-group,
.demo-view-group,
.demo-color-group {
  padding: 0 0.5rem 1rem 0;
}

.demo-type-group label[class*="label"],
.demo-view-group label[class*="label"],
.demo-color-group label[class*="label"] {
  margin-left: 0;
}

.demo-slider {
  margin: 1rem auto;
  width: 80%;
}

code {
  color: var(--grey60);
  font-family: var(--inter);
}

pre {
  background: var(--blue5);
  white-space: normal;
  white-space: initial;
  padding: 0.5rem;
  border-radius: 0.25rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 1rem;
  letter-spacing: var(--letter_spacing_body);
  font-family: "IBM Plex Mono", monospace;
}

/*API TAB*/
/*.api-container {
}*/

.api-prop-container {
  margin-top: 1.5rem;
}
.api-prop-name {
  border: 1px solid var(--grey30);
  border-radius: 0.5rem;
  padding: 0.25rem 0.625rem;
  color: var(--grey70);
  font-size: 1rem;
  letter-spacing: var(--letter_spacing_body);
  font-family: "IBM Plex Mono", monospace;
  line-height: 2rem;
}
.api-prop-type {
  font-size: 1rem;
  letter-spacing: var(--letter_spacing_body_bold);
  font-family: "IBM Plex Mono", monospace;
  line-height: 1rem;
  font-weight: var(--font_weight_body_medium);
  margin-top: 0.5rem;
}
.api-prop-desc {
  margin-top: 0.5rem;
  color: var(--grey60);
  font-family: var(--inter);
  letter-spacing: var(--letter_spacing_body);
  font-size: var(--font_size_body);
}

/*CODE TAB*/
.source-code-block {
  background: var(--blue5);
  border-radius: 0.25rem;
  margin: 0;
}
.source-code-block pre {
  white-space: pre-wrap;
  font-weight: var(--font_weight_body_medium);
  margin: 0;
  padding: 0;
  margin-top: -1.75rem;
  position: relative;
}

* {
  box-sizing: border-box;
}
html,
body {
  margin: 0;
  padding: 0;
}
/*body {
  overflow-y: scroll;
}*/
text {
  font-family: "Roboto";
}
/*MOBILE*/
.style_container__3XYH9 {
  height: 100vh;
  display: grid;
  grid-template-areas:
    "header"
    "."
    "content"
    "."
    "footer";
  grid-template-columns: 1fr;
  grid-template-rows: 6rem 1rem auto 1rem 3rem;
}

.style_header__17zNC {
  grid-area: header;
}
.style_sidebar__2WGPj {
  grid-area: sidebar;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 240px;
  z-index: 5;
  display: block;
  transform: translateX(-100%);
  transition: all 250ms ease-in-out;
  display: contents;
  /*overflow-y: auto;*/
  background: var(--grey0);
  border-radius: 0 0.5rem 0.5rem 0;
}
.style_content__3PqLI {
  grid-area: content;
  margin-left: 0;
  margin-right: 0;
  overflow-y: unset;
}
.style_content-component__FUzG7 {
  display: grid;
  padding-right: 1rem;
  padding-left: 1rem;
  /* padding-bottom: 5rem;*/
}
.style_right_sidebar__2FeCc {
  grid-area: props;
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
  padding-left: 1rem;
  padding-right: 0.25rem;
  background: var(--grey0);
  border-radius: 0.5rem 0 0 0.5rem;
}
.style_footer__2wO0c {
  grid-area: footer;
  background-color: var(--grey0);
  padding: 0.625rem 0.5rem;
  letter-spacing: var(--letter-spacing-body);
  font-size: 0.875rem;
  display: inline-flex;
  position: relative;
  width: 100%;
  bottom: 0;
  height: 3rem;
  z-index: 9;
}
.style_footer_links__1vdAD {
  color: var(--grey70);
  font-weight: 500;
  display: flex;
  font-size: var(--font_size_caption_small);
  letter-spacing: var(--letter_spacing_caption_small);
  margin-left: auto;
}
.style_footer_links__1vdAD a {
  color: var(--grey70);
  text-decoration: none;
  margin-right: 1rem;
  text-align: center;
}
.style_footer_links__1vdAD a:last-of-type {
  margin-right: 0;
}
.style_footer_links__1vdAD a:hover {
  color: var(--blue70);
  text-decoration: none;
}
.style_footer_break__2poPD {
  display: block;
  font-size: var(--font_size_caption);
  letter-spacing: var(--letter_spacing_caption);
}
.style_footer_text__-E47R {
  text-align: left;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  float: left;
  color: var(--grey50);
  font-size: var(--font_size_caption);
  letter-spacing: var(--letter_spacing_caption);
}
.style_props_button__WhUNS {
  position: fixed;
  bottom: 4rem;
  right: 1rem;
  z-index: 10;
}
.style_nowrap__2O6LF {
  white-space: nowrap;
}
.style_right_sidebar_backdrop__6mQhT {
  background-color: unset;
}
div[role="tablist"][class*="tab-list"] {
  margin-left: auto;
  margin-right: auto !important;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
/*-----640PX-----*/
@media screen and (min-width: 640px) {
  .style_container__3XYH9 {
    height: calc(100vh);
    display: grid;
    grid-template-areas:
      "header"
      ". "
      "content "
      ". "
      "footer";
    grid-template-columns: 1fr;
    grid-template-rows: 4rem 1rem auto 1rem 3rem;
  }
  .style_right_sidebar__2FeCc {
    /*display: grid;*/
    width: 180px;
    height: calc(100vh - 8rem);
    overflow-y: auto;
    overflow-x: hidden;
    position: fixed;
    top: 4.5rem;
    right: 0;
  }
  .style_content-component__FUzG7 {
    display: grid;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-bottom: 5rem;
  }
  .style_content__3PqLI {
    margin-right: 0;
    overflow-y: unset;
    width: calc(100% - 180px);
  }
  .style_footer__2wO0c {
    padding: 0.25rem 0 0.25rem 1rem;
  }
  .style_footer_text__-E47R {
    width: auto;
    text-align: left;
    float: left;
    color: var(--grey50);
    font-size: var(--font_size_body);
    line-height: 24px;
    height: 24px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .style_footer_break__2poPD {
    display: inline-block;
    font-size: var(--font_size_body);
  }
  .style_footer_links__1vdAD {
    padding-top: 0;
    padding-top: initial;
    float: right;
    font-size: var(--font_size_caption);
    letter-spacing: var(--letter_spacing_caption);
  }
  .style_footer_links__1vdAD,
  .style_footer_links__1vdAD a {
    margin-right: 1.5rem;
    margin-bottom: auto;
    margin-top: auto;
  }
  .style_footer_links__1vdAD svg {
    width: 1.25rem;
    height: 1.25rem;
  }
  .style_props_button__WhUNS {
    display: none;
  }
}
/*-----1024PX-----*/
@media screen and (min-width: 1024px) {
  .style_container__3XYH9 {
    height: 100vh;
    display: grid;
    grid-template-areas:
      "header header header"
      ". . ."
      "sidebar content props"
      ". . ."
      "footer footer footer";
    grid-template-columns: 240px 5fr 200px;
    grid-template-rows: 4.5rem 1rem auto 1rem 3rem;
  }
  .style_sidebar__2WGPj {
    grid-area: sidebar;
    display: block;
    max-width: 240px;
    width: 240px;
    top: 4.5rem;
    transform: unset;
    box-shadow: none;
    height: auto;
    background: none;
  }
  .style_right_sidebar__2FeCc {
    width: 200px;
    height: calc(100vh - 9.5rem);
    top: 5.5rem;
    background: none;
    padding-bottom: 0;
    padding-top: 0;
  }
  .style_content-component__FUzG7 {
    display: grid;
    width: 100%;
    float: left;
  }
  .style_content__3PqLI {
    margin-right: 0;
    margin-left: 0;
    width: 100%;
    padding: 0 1rem;
  }
  .style_nowrap__2O6LF {
    white-space: normal;
    white-space: initial;
  }
}

@media screen and (min-width: 1440px) {
  /*TEST*/
  div[id="root"] {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
  }
  .style_container__3XYH9 {
    height: calc(100vh);
    display: grid;
    grid-template-areas:
      "header header"
      ". . ."
      "sidebar content"
      ". . ."
      "footer footer";
    grid-template-columns: 200px 1fr;
    grid-template-rows: 4.5rem 1rem auto 0 3rem;
  }
  .style_sidebar__2WGPj {
    width: 200px;
    max-width: 200px;
  }
  .style_content__3PqLI {
    padding: 0;
    padding-left: 0.75rem;
  }
  .style_content-component__FUzG7 {
    width: calc(100% - 200px);
    padding: 0;
  }
  .style_right_sidebar__2FeCc {
    position: relative;
    top: 0;
  }
  .style_footer__2wO0c {
    position: absolute;
    left: 0;
  }
}

* {
  box-sizing: border-box;
}
html,
body {
  margin: 0;
  padding: 0;
}
.style_ava_menu__3lO0p {
  border: none;
  background: none;
  height: 2.5rem;
}
.style_navbar_ava__2vvjV span[class*="popover-wrapper"] {
  display: flex;
  align-items: center;
  height: 2.5rem;
}
.style_navbar_ava__2vvjV span[class*="popover-wrapper"] > span {
  /* height: 2.5rem; */
}
.style_ava_menu__3lO0p:after {
  content: none !important;
}
/*MOBILE*/
.style_container__3XDC4 {
  height: 100vh;
  display: grid;
  grid-template-areas:
    "header"
    "."
    "content"
    "."
    "footer";
  grid-template-columns: 1fr;
  grid-template-rows: 6rem 1rem auto;
}

.style_header__3f1j- {
  grid-area: header;
}
.style_sidebar__7-N-f {
  grid-area: sidebar;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 240px;
  z-index: 5;
  display: block;
  transform: translateX(-100%);
  transition: all 250ms ease-in-out;
  display: contents;
  background: var(--grey0);
  border-radius: 0 0.5rem 0.5rem 0;
}
.style_content__2t22e {
  grid-area: content;
  margin-left: 0;
  margin-right: 0;
  overflow-y: unset;
}
.style_content-component__2cawk {
  display: grid;
  padding-right: 1rem;
  padding-left: 1rem;
}

.style_footer__cSaSZ {
  grid-area: footer;
  background-color: var(--grey0);
  letter-spacing: var(--letter-spacing-body);
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  bottom: 0;
  height: 5.5rem;
  z-index: 9;
  box-shadow: 0px 8px 16px rgba(58, 58, 68, 0.12),
    0px 16px 32px rgba(90, 91, 106, 0.12);
  border-radius: 8px 8px 0px 0px;
}
.style_footer_links__fRoJd {
  color: var(--grey70);
  font-weight: 500;
  display: flex;
  font-size: var(--font_size_caption_small);
  letter-spacing: var(--letter_spacing_caption_small);
  justify-content: space-around;
}

.style_footer_links__fRoJd a {
  color: var(--grey70);
  text-decoration: none;
  margin-right: 1rem;
  text-align: center;
}
.style_footer_links__fRoJd a:last-of-type {
  margin-right: 0;
}
.style_footer_links__fRoJd a:hover {
  color: var(--blue70);
  text-decoration: none;
}
.style_footer_break__2SVi8 {
  display: block;
  font-size: var(--font_size_caption);
  letter-spacing: var(--letter_spacing_caption);
}
.style_footer_text__1wm8L {
  text-align: center;
  line-height: 3rem;
  color: var(--grey100);
  font-size: var(--font_size_caption);
  letter-spacing: var(--letter_spacing_caption);
}
.style_props_button__1oL_0 {
  position: fixed;
  bottom: 6.5rem;
  right: 1.5rem;
  z-index: 10;
}
.style_nowrap__VaJGf {
  white-space: nowrap;
}
.style_right_sidebar_backdrop__2tw7m {
  background-color: unset;
}
div[role="tablist"][class*="tab-list"] {
  margin-left: auto;
  margin-right: auto !important;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
/*-----640PX-----*/
@media screen and (min-width: 640px) {
  .style_container__3XDC4 {
    height: calc(100vh);
    display: grid;
    grid-template-areas:
      "header"
      ". "
      "content "
      ". "
      "footer";
    grid-template-columns: 1fr;
    grid-template-rows: 4rem 1rem auto 1rem 5.5rem;
  }

  .style_content-component__2cawk {
    display: grid;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-bottom: 5rem;
  }
  .style_content__2t22e {
    margin-right: 0;
    overflow-y: unset;
    width: 100%;
  }
}
.style_ava_name__3ttEf {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 0.75rem;
}
/*-----1024PX-----*/
@media screen and (min-width: 1024px) {
  .style_container__3XDC4 {
    height: 100vh;
    display: grid;
    grid-template-areas:
      "header header"
      ". ."
      "sidebar content"
      ". ."
      "footer footer";
    grid-template-columns: 240px 5fr;
    grid-template-rows: 4.5rem 1rem auto 1rem 4rem;
  }
  .style_sidebar__7-N-f {
    grid-area: sidebar;
    display: block;
    max-width: 240px;
    width: 240px;
    top: 4.5rem;
    transform: unset;
    box-shadow: none;
    height: auto;
    background: none;
  }

  .style_content-component__2cawk {
    display: grid;
    width: 100%;
    float: left;
  }
  .style_content__2t22e {
    margin-right: 0;
    margin-left: 0;
    width: 100%;
    padding: 0 1rem;
  }
  .style_nowrap__VaJGf {
    white-space: normal;
    white-space: initial;
  }
  .style_footer__cSaSZ {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    height: 4rem;
    position: absolute;
    width: 100vw;
    left: 0;
  }
  .style_props_button__1oL_0 {
    bottom: 5.5rem;
  }
}

@media screen and (min-width: 1440px) {
  div[id="root"] {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
  }
  .style_container__3XDC4 {
    height: calc(100vh);
    display: grid;
    grid-template-areas:
      "header header"
      ". ."
      "sidebar content"
      ". ."
      "footer footer";
    grid-template-columns: 200px 1fr;
    grid-template-rows: 4.5rem 1rem auto 0 4rem;
  }
  .style_sidebar__7-N-f {
    width: 200px;
    max-width: 200px;
  }
  .style_content__2t22e {
    padding: 0;
    padding-left: 0.75rem;
  }
  .style_content-component__2cawk {
    width: calc(100% - 200px);
    padding: 0;
  }
}

/* BUTTONS TYPES*/
/*--DEFAULT--*/
._type_default__KmFtD {
  font-size: var(--font_size_button);
  font-family: var(--font_family_button);
  font-weight: bold;
  text-align: center;
  letter-spacing: var(--letter-spacing-button);
  min-width: var(--s40);
  height: var(--s40);
  min-height: unset;
  padding: 0 1rem 0 1rem;
  line-height: var(--s16);
  border-radius: var(--s4);
  text-transform: capitalize;
}
._type_default__KmFtD._type_dense__1n160 {
  font-weight: normal;
  letter-spacing: var(--letter_spacing_body_small);
  height: var(--s28);
  padding: 0 0.75rem 0 0.75rem;
}
/*--card--*/
._type_card__1yrjD {
  font-size: var(--font_size_caption);
  font-family: var(--font_family_button);
  line-height: var(--s16);
  text-align: center;
  letter-spacing: var(--letter_spacing_caption);
  text-transform: none;
  min-width: var(--s80);
  height: var(--s56);
  display: inline;
  display: initial;
  word-break: break-all;
  padding: var(--s8) 0;
  border-radius: var(--s4);
  text-transform: capitalize;
}
._type_card__1yrjD p {
  margin: 0;
}
._type_card__1yrjD > span {
  margin-right: 0;
}
._type_card__1yrjD > span[class*="icon"] {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  margin-bottom: 0.25rem;
}
._type_card__1yrjD[class*="button"] > div[class*="spinner"] {
  position: static;
  position: initial;
  margin-top: 0.625rem;
}
._type_dense__1n160._type_card__1yrjD {
  min-width: var(--s56);
  height: var(--s40);
  padding: var(--s6) 0;
  font-size: var(--font_size_caption_small);
  letter-spacing: var(--letter_spacing_caption_small);
}
._type_dense__1n160._type_card__1yrjD > span[class*="icon"] {
  margin-bottom: 0;
}
._type_dense__1n160._type_card__1yrjD[class*="button"] > div[class*="spinner"] {
  margin-top: 0.375rem;
}
/*--circle--*/
._type_circle__34Ci- {
  height: var(--s56);
  width: var(--s56);
  border-radius: 99px;
}

._type_dense__1n160._type_circle__34Ci- {
  height: var(--s40);
  width: var(--s40);
}
/*--ACTION--*/
._type_action__jnZ5L {
  font-family: var(--font_family_button);
  font-style: normal;
  font-weight: var(--font_weight_h6);
  font-size: var(--font_size_h6);
  letter-spacing: var(--letter_spacing_h6);
  height: var(--s56);
  border-radius: 99px;
  padding: 0.75rem 1rem;
  text-transform: capitalize;
}
._type_action__jnZ5L span {
  margin: 0;
}
._type_action__jnZ5L span:first-child[class*="icon"] {
  text-align: left;
  margin-right: 0.75rem;
}
._type_action__jnZ5L span:last-child[class*="icon"] {
  margin-left: 0.75rem;
}
._type_dense__1n160._type_action__jnZ5L {
  font-weight: normal;
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body_bold);
  height: var(--s40);
  border-radius: 99px;
  padding: 0.5rem 0.75rem;
}

._type_dense__1n160._type_action__jnZ5L span:first-child[class*="icon"] {
  margin-right: 0.625rem;
}
._type_dense__1n160._type_action__jnZ5L span:last-child[class*="icon"] {
  margin-left: 0.625rem;
}
/*--ICON--*/
._type_icon__2tQg6 {
  width: var(--s36);
  height: var(--s36);
  min-width: 0;
  min-height: 0;
  border-radius: 99px;
  padding: 0;
}
._type_dense__1n160._type_icon__2tQg6 {
  width: var(--s28);
  height: var(--s28);
}

/*FLAT STYLE*/
._view_flat__2CEXK,
._view_flat__2CEXK[class*="button"],
._view_flat__2CEXK:visited {
  color: var(--color70);
  background: none;
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._view_flat__2CEXK:hover,
._view_flat__2CEXK[class*="button"]:hover,
._view_flat__2CEXK:hover {
  box-shadow: var(--shadow2dp);
  background: none;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_flat__2CEXK:active,
._view_flat__2CEXK[class*="button"]:active,
._view_flat__2CEXK[class*="button"][class*="active"],
._view_flat__2CEXK[class*="button"]._view_focused__bDTfm {
  background: var(--color5);
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_flat__2CEXK[class*="disabled"]:disabled,
._view_flat__2CEXK[class*="disabled"]:disabled > span > svg,
._view_flat__2CEXK[class*="disabled"]:disabled:hover,
._view_flat__2CEXK[class*="disabled"]:disabled:active {
  color: var(--color30);
  opacity: 1;
  background-color: var(--color0);
  box-shadow: none;
}
/*END STYLE*/

/* FILLED STYLE*/
._view_filled__4f7Pz[class*="button"],
._view_filled__4f7Pz[class*="button"]:visited {
  box-shadow: var(--shadow4dp);
  background: var(--color60);
  color: var(--color0);
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._view_filled__4f7Pz[class*="button"]:hover {
  background: var(--color70);
  box-shadow: var(--shadow8dp);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_filled__4f7Pz[class*="button"]:active,
._view_filled__4f7Pz[class*="button"][class*="active"]:active,
._view_filled__4f7Pz[class*="button"]._view_focused__bDTfm {
  background: var(--color80);
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_filled__4f7Pz[class*="disabled"]:disabled {
  color: var(--color40);
  background-color: var(--color5);
  box-shadow: none;
  opacity: 1;
}
/* END STYLE */

/*SMOOTH STYLE*/
._view_smooth__1SMqI[class*="button"],
._view_smooth__1SMqI[class*="button"]:visited {
  color: var(--color70);
  background: var(--color5);
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._view_smooth__1SMqI[class*="button"]:hover {
  color: var(--color100);
  background: var(--color10);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_smooth__1SMqI[class*="button"]:active,
._view_smooth__1SMqI[class*="button"]:active,
._view_smooth__1SMqI[class*="button"][class*="active"],
._view_smooth__1SMqI[class*="button"]._view_focused__bDTfm {
  background: var(--color20);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_smooth__1SMqI[class*="disabled"]:disabled,
._view_smooth__1SMqI[class*="disabled"]:disabled:hover {
  color: var(--color30);
  background-color: var(--color0);
  opacity: 1;
}
/* END STYLE */

/* OUTLINED STYLE*/
._view_outlined__3H9IA[class*="button"],
._view_outlined__3H9IA[class*="button"]:visited {
  background: none;
  box-shadow: 0 0 0 1px var(--color30); /*border: 1px solid var(--color30);*/
  color: var(--color70);
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._view_outlined__3H9IA[class*="button"]:hover {
  box-shadow: 0 0 0 2px var(--color30);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_outlined__3H9IA[class*="button"]:active,
._view_outlined__3H9IA[class*="button"]._view_focused__bDTfm {
  color: var(--color100);
  background-color: var(--color5);
  box-shadow: 0 0 0 2px var(--color50);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_outlined__3H9IA[class*="disabled"]:disabled {
  background-color: unset;
  color: var(--color30);
  box-shadow: 0 0 0 1px var(--color10);
}
/* END STYLE */

/* RAISED STYLE */
._view_raised__lbrvP[class*="button"],
._view_raised__lbrvP[class*="button"]:visited {
  background: var(--color0);
  color: var(--color70);
  box-shadow: var(--shadow4dp);
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._view_raised__lbrvP[class*="button"]:hover {
  background: var(--white);
  color: var(--color100);
  box-shadow: var(--shadow8dp);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_raised__lbrvP[class*="button"]:active,
._view_raised__lbrvP[class*="button"]._view_focused__bDTfm {
  box-shadow: var(--shadow2dp);
  background-color: var(--white);
  color: var(--color70);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_raised__lbrvP[class*="disabled"]:disabled {
  color: var(--color40);
  background-color: var(--color5);
  box-shadow: none;
}
/* END STYLE */

/* COLORS*/
:root {
  --color0: var(--grey0);
  --color5: var(--grey5);
  --color10: var(--grey10);
  --color20: var(--grey20);
  --color30: var(--grey30);
  --color40: var(--grey40);
  --color50: var(--grey50);
  --color60: var(--grey60);
  --color70: var(--grey70);
  --color80: var(--grey80);
  --color90: var(--grey90);
  --color100: var(--grey100);

  --shadow2dp: var(--greyShadow2dp);
  --shadow4dp: var(--greyShadow4dp);
  --shadow8dp: var(--greyShadow8dp);
  --shadow16dp: var(--greyShadow16dp);
  --shadow24dp: var(--greyShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradGrey);
  --gradient-reverse: var(--gradGreyRev);
  /*-----------------*/

  /*TABLE*/
  --flat-def-bg-table-hover: var(--color5);
  --flat-def-color-table: var(--color50);
  --flat-def-border-table: var(--color10);
  --flat-def-border2-table: var(--color50);
  --flat-def-borderlast-table: var(--color40);
  --flat-def-color-table-pag: var(--color60);
  --flat-def-color-checkbox: var(--color70);

  --filled-def-bg-table: var(--color40);
  --filled-def-bg-table-hover: var(--color50);
  --filled-def-bg-head-table: var(--color70);
  --filled-def-border-table: var(--color30);
  --filled-def-border2-table: var(--color10);
  --filled-def-head-table-sort: var(--color60);
  --filled-def-color-checkbox: var(--color20);

  --smooth-def-bg-head-table: var(--color10);
  --smooth-def-bg-table: var(--color0);
  --smooth-def-border-table: var(--color10);
  --smooth-def-border2-table: var(--color50);
  --smooth-def-color-table: var(--color50);
  --smooth-def-color-table-pag: var(--color60);

  --raised-def-border-table: var(--color10);
  --raised-def-border2-table: var(--color50);
  --raised-def-prim-color-table: var(--color50);
  /*-------------------*/
}

.color_select__3Rg8Y {
  /*TABLE*/
  --flat-bg-table-hover: var(--color5);
  --flat-color-table: var(--color50);
  --flat-border-table: var(--color10);
  --flat-border2-table: var(--color50);
  --flat-borderlast-table: var(--color40);
  --flat-color-table-pag: var(--color60);
  --flat-color-checkbox: var(--color70);

  --filled-bg-table: var(--color40);
  --filled-bg-table-hover: var(--color50);
  --filled-bg-head-table: var(--color70);
  --filled-border-table: var(--color30);
  --filled-border2-table: var(--color10);
  --filled-head-table-sort: var(--color60);
  --filled-color-checkbox: var(--color20);

  --smooth-bg-head-table: var(--color10);
  --smooth-bg-table: var(--color0);
  --smooth-border-table: var(--color10);
  --smooth-border2-table: var(--color50);
  --smooth-color-table: var(--color50);
  --smooth-color-table-pag: var(--color60);

  --raised-border-table: var(--color10);
  --raised-border2-table: var(--color50);
  --raised-prim-color-table: var(--color50);
  /*-------------------*/
}
.color_primary__3FwI9 {
  --color0: var(--blue0);
  --color5: var(--blue5);
  --color10: var(--blue10);
  --color20: var(--blue20);
  --color30: var(--blue30);
  --color40: var(--blue40);
  --color50: var(--blue50);
  --color60: var(--blue60);
  --color70: var(--blue70);
  --color80: var(--blue80);
  --color90: var(--blue90);
  --color100: var(--blue100);

  --shadow2dp: var(--blueShadow2dp);
  --shadow4dp: var(--blueShadow4dp);
  --shadow8dp: var(--blueShadow8dp);
  --shadow16dp: var(--blueShadow16dp);
  --shadow24dp: var(--blueShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradBlue);
  --gradient-reverse: var(--gradBlueRev);
  /*-----*/
}

.color_danger__7O8v_ {
  --color0: var(--red0);
  --color5: var(--red5);
  --color10: var(--red10);
  --color20: var(--red20);
  --color30: var(--red30);
  --color40: var(--red40);
  --color50: var(--red50);
  --color60: var(--red60);
  --color70: var(--red70);
  --color80: var(--red80);
  --color90: var(--red90);
  --color100: var(--red100);

  --shadow2dp: var(--redShadow2dp);
  --shadow4dp: var(--redShadow4dp);
  --shadow8dp: var(--redShadow8dp);
  --shadow16dp: var(--redShadow16dp);
  --shadow24dp: var(--redShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradRed);
  --gradient-reverse: var(--gradRedRev);
  /*-----*/
}
.color_success__3smDZ {
  --color0: var(--green0);
  --color5: var(--green5);
  --color10: var(--green10);
  --color20: var(--green20);
  --color30: var(--green30);
  --color40: var(--green40);
  --color50: var(--green50);
  --color60: var(--green60);
  --color70: var(--green70);
  --color80: var(--green80);
  --color90: var(--green90);
  --color100: var(--green100);

  --shadow2dp: var(--greenShadow2dp);
  --shadow4dp: var(--greenShadow4dp);
  --shadow8dp: var(--greenShadow8dp);
  --shadow16dp: var(--greenShadow16dp);
  --shadow24dp: var(--greenShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradGreen);
  --gradient-reverse: var(--gradGreenRev);
  /*-----------------*/
}
.color_warning__4eeIP {
  --color0: var(--yellow0);
  --color5: var(--yellow5);
  --color10: var(--yellow10);
  --color20: var(--yellow20);
  --color30: var(--yellow30);
  --color40: var(--yellow40);
  --color50: var(--yellow50);
  --color60: var(--yellow60);
  --color70: var(--yellow70);
  --color80: var(--yellow80);
  --color90: var(--yellow90);
  --color100: var(--yellow100);

  --shadow2dp: var(--yellowShadow2dp);
  --shadow4dp: var(--yellowShadow4dp);
  --shadow8dp: var(--yellowShadow8dp);
  --shadow16dp: var(--yellowShadow16dp);
  --shadow24dp: var(--yellowShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradYellow);
  --gradient-reverse: var(--gradYellowRev);
  /*-----------------*/
}
.color_default__P6ti3 {
  --color0: var(--grey0);
  --color5: var(--grey5);
  --color10: var(--grey10);
  --color20: var(--grey20);
  --color30: var(--grey30);
  --color40: var(--grey40);
  --color50: var(--grey50);
  --color60: var(--grey60);
  --color70: var(--grey70);
  --color80: var(--grey80);
  --color90: var(--grey90);
  --color100: var(--grey100);

  --shadow2dp: var(--greyShadow2dp);
  --shadow4dp: var(--greyShadow4dp);
  --shadow8dp: var(--greyShadow8dp);
  --shadow16dp: var(--greyShadow16dp);
  --shadow24dp: var(--greyShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradGrey);
  --gradient-reverse: var(--gradGreyRev);
  /*-----------------*/
}

.color_primary_alt__1rdDI {
  --color0: var(--indigo0);
  --color5: var(--indigo5);
  --color10: var(--indigo10);
  --color20: var(--indigo20);
  --color30: var(--indigo30);
  --color40: var(--indigo40);
  --color50: var(--indigo50);
  --color60: var(--indigo60);
  --color70: var(--indigo70);
  --color80: var(--indigo80);
  --color90: var(--indigo90);
  --color100: var(--indigo100);

  --shadow2dp: var(--indigoShadow2dp);
  --shadow4dp: var(--indigoShadow4dp);
  --shadow8dp: var(--indigoShadow8dp);
  --shadow16dp: var(--indigoShadow16dp);
  --shadow24dp: var(--indigoShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradIndigo);
  --gradient-reverse: var(--gradIndigoRev);
  /*-----*/
}

.color_danger_alt__3m-wJ {
  --color0: var(--pink0);
  --color5: var(--pink5);
  --color10: var(--pink10);
  --color20: var(--pink20);
  --color30: var(--pink30);
  --color40: var(--pink40);
  --color50: var(--pink50);
  --color60: var(--pink60);
  --color70: var(--pink70);
  --color80: var(--pink80);
  --color90: var(--pink90);
  --color100: var(--pink100);

  --shadow2dp: var(--pinkShadow2dp);
  --shadow4dp: var(--pinkShadow4dp);
  --shadow8dp: var(--pinkShadow8dp);
  --shadow16dp: var(--pinkShadow16dp);
  --shadow24dp: var(--pinkShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradPink);
  --gradient-reverse: var(--gradPinkRev);
  /*-----*/
}
.color_success_alt__2Bror {
  --color0: var(--teal0);
  --color5: var(--teal5);
  --color10: var(--teal10);
  --color20: var(--teal20);
  --color30: var(--teal30);
  --color40: var(--teal40);
  --color50: var(--teal50);
  --color60: var(--teal60);
  --color70: var(--teal70);
  --color80: var(--teal80);
  --color90: var(--teal90);
  --color100: var(--teal100);

  --shadow2dp: var(--tealShadow2dp);
  --shadow4dp: var(--tealShadow4dp);
  --shadow8dp: var(--tealShadow8dp);
  --shadow16dp: var(--tealShadow16dp);
  --shadow24dp: var(--tealShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradTeal);
  --gradient-reverse: var(--gradTealRev);
  /*-----------------*/
}
.color_warning_alt__2DTPZ {
  --color0: var(--orange0);
  --color5: var(--orange5);
  --color10: var(--orange10);
  --color20: var(--orange20);
  --color30: var(--orange30);
  --color40: var(--orange40);
  --color50: var(--orange50);
  --color60: var(--orange60);
  --color70: var(--orange70);
  --color80: var(--orange80);
  --color90: var(--orange90);
  --color100: var(--orange100);

  --shadow2dp: var(--orangeShadow2dp);
  --shadow4dp: var(--orangeShadow4dp);
  --shadow8dp: var(--orangeShadow8dp);
  --shadow16dp: var(--orangeShadow16dp);
  --shadow24dp: var(--orangeShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradOrange);
  --gradient-reverse: var(--gradOrangeRev);
  /*-----------------*/
}

/* END COLORS*/

/**NAVBAR*/
/* div[class*="align-left"] {
  margin-right: auto;
} */
/* div[class*="navbar-heading"] {
  margin-right: auto;
} */
._type_desktop__24XkX {
  display: flex;
  height: 4.5rem;
  border-radius: 0 0 0.25rem 0.25rem;
  padding: 0 1rem;
  justify-content: space-between;
}
._type_desktop__24XkX div[class*="navbar-group"] {
  height: 4.5rem;
}

._type_tablet__1BwYO {
  display: flex;
  height: 3.5rem;
  border-radius: 0 0 0.25rem 0.25rem;
  padding: 0 1rem;
  justify-content: space-between;
}
._type_tablet__1BwYO div[class*="navbar-group"] {
  height: 3.5rem;
}

._type_mobile__1y1Vw {
  height: 6rem;
  border-radius: 0 0 0.25rem 0.25rem;
  padding: 0.625rem 0.5rem;
}

/*GROUP ALIGN CENTER*/
._type_center__2O3QC {
  align-content: space-between;
  /* display: flex; */
  /* margin-left: auto;
  margin-right: auto; */
}
._type_center__2O3QC a:not(:first-of-type) {
  text-decoration: none;
  padding-left: 0.75rem;
}
._type_mobile__1y1Vw div[class*="navbar-group"] {
  height: auto;
  height: initial;
}
._type_mobile__1y1Vw div[class*="center"] {
  position: absolute;
  top: 100%;
  left: 0;
  margin-right: 0;
  transform: translate(0%, -100%);
  /*overflow-x: auto;*/
  width: 100%;
  overflow-y: hidden;
  padding: 0 0.5rem 0.625rem 0.5rem;
}

/**/

/*NAVBAR*/
._view_flat__34fOv {
  box-shadow: none;
  background: var(--white);
}
._view_smooth__3xG6s {
  box-shadow: none;
  background: var(--color5);
}
._view_raised__UXm74 {
  box-shadow: var(--shadow2dp);
  background: var(--white);
}
._view_filled__3pOTu {
  box-shadow: var(--shadow2dp);
  background: var(--color70);
}

/* TREE TYPES*/

._type_def__2qUBp li[class*="tree-node"] {
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body);
  font-weight: var(--font_weight_body);
  transition: all 100ms ease-in-out;
}
._type_dense__3QkMC li[class*="tree-node"] {
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  font-weight: var(--font_weight_body);
  transition: all 100ms ease-in-out;
}

._type_def__2qUBp li[class*="tree-node-expanded"],
._type_dense__3QkMC li[class*="tree-node-expanded"] {
  font-weight: var(--font_weight_body_medium);
  transition: all 150ms ease-in;
}

._type_def__2qUBp li[class*="selected"],
._type_dense__3QkMC li[class*="selected"] {
  font-weight: var(--font_weight_body_medium);
  transition: all 150ms ease-in;
}

._type_def__2qUBp li > div[class*="node-content"] {
  min-height: 2.5rem;
  height: unset;
  margin-bottom: 0.25rem;
  box-shadow: none;
  border-radius: 0.25rem;
  transition: all 200ms ease-in-out;
}
._type_dense__3QkMC li > div[class*="node-content"] {
  min-height: 1.75rem;
  height: unset;
  margin-bottom: 0.125rem;
  box-shadow: none;
  border-radius: 0.125rem;
  transition: all 200ms ease-in-out;
}

._type_def__2qUBp
  li
  > div[class*="node-content"]
  > span[class*="node-caret"]:not([class*="node-caret-none"]) {
  width: 2rem;
  height: 2rem;
  min-width: unset;
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  border-radius: 100%;
  padding: 0;
}

._type_dense__3QkMC li > div[class*="node-content"] > span[class*="node-caret"] {
  /*width: 1.25rem;
  height: 1.25rem;*/
  min-width: unset;
  margin-left: 0.375rem;
  margin-right: 0.5rem;
  padding: 0;
}
._type_def__2qUBp li > div[class*="node-content"] > span[class*="node-caret"] > svg,
._type_def__2qUBp span[class*="node-caret-none"] ~ span[class*="node-icon"] > svg {
  width: 1.25rem;
  height: 1.25rem;
  margin: auto;
}
._type_def__2qUBp li > div[class*="node-content"] > span[class*="node-caret"] > svg {
  margin-top: 0.375rem;
}
._type_def__2qUBp span[class*="node-caret-none"] {
  min-width: 0.625rem;
  margin-left: 0.625rem;
}
._type_dense__3QkMC span[class*="node-caret-none"] {
  margin-left: 0 !important;
}

._type_def__2qUBp div[with-secondary-label] {
  height: 2.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
._type_def__2qUBp div[with-secondary-label] > div {
  /* font-size: var(--font-size-Body2);
  letter-spacing: var(--letter-spacing-Body2);*/
  margin-top: 0.125rem;
}

._type_def__2qUBp li[class*="disabled"] > div > span > div[with-secondary-label] > div {
  /* font-size: var(--font-size-Body2);
  letter-spacing: var(--letter-spacing-Body2);*/
}

._type_def__2qUBp
  li[class*="disabled"]
  > div
  > span
  > div[with-secondary-label]
  > div
  > span {
  display: inline-block;
}

/*Secondary icon*/
._type_def__2qUBp span[class*="node-secondary-label"] > span[class*="icon"] > svg {
  width: 1.25rem;
  height: 1.25rem;
}
/*----*/

/*TREE*/

._view_flat__2-LN9 {
  color: var(--color100);
}
._view_flat__2-LN9 span > svg {
  color: var(--color50);
}
._view_flat__2-LN9 li > div:first-of-type:hover {
  box-shadow: var(--shadow2dp);
  transition: all 150ms ease-in;
}
._view_flat__2-LN9 li > div:hover {
  background-color: unset;
}
._view_flat__2-LN9 li[class*="selected"] > div:first-of-type {
  background-color: var(--color5);
  color: var(--color100);
  box-shadow: none;
  transition: all 150ms ease-in;
}
._view_flat__2-LN9 li[class*="selected"] svg {
  color: var(--color70);
  transition: all 150ms ease-in;
}

._view_flat__2-LN9 li > div:first-of-type:hover > span:first-of-type {
  background: var(--color10);
  transition: all 150ms ease-in;
}
._view_flat__2-LN9[class*="dense"] li > div:first-of-type:hover > span:first-of-type {
  background: none;
  transition: all 150ms ease-in;
}
._view_flat__2-LN9
  li:not([class*="disabled"])
  > div:first-of-type:hover
  > span[class*="node-caret"]:first-of-type
  > svg {
  color: var(--color100);
  transition: all 150ms ease-in;
}
._view_flat__2-LN9 li:hover > div:first-of-type > span[class*="none"] {
  background: none;
  transition: all 150ms ease-in;
}
._view_flat__2-LN9 li:hover {
  cursor: pointer;
}
._view_flat__2-LN9 span[class*="node-caret-closed"] > svg {
  color: var(--color40);
  transition: all 150ms ease-in;
}
._view_flat__2-LN9 span[class*="node-caret-open"] > svg {
  color: var(--color70);
  transition: all 150ms ease-in;
}
._view_flat__2-LN9 div[with-secondary-label] > div {
  color: var(--color60);
}

/*DISABLED*/
._view_flat__2-LN9 li[class*="disabled"],
._view_flat__2-LN9 li[class*="disabled"] > div:first-of-type,
._view_flat__2-LN9 li[class*="disabled"] > div:first-of-type:hover > span,
._view_flat__2-LN9 li[class*="disabled"] > div:first-of-type:hover > span > svg,
._view_flat__2-LN9 li[class*="disabled"] > div:first-of-type > span > svg {
  color: var(--color20);
  box-shadow: none;
  background: unset;
}
/*Custom*/
._view_flat__2-LN9 li[class*="disabled"] > div > span > div[with-secondary-label] > div {
  color: var(--yellow70);
}
._view_flat__2-LN9
  li[class*="disabled"]
  > div
  > span
  > div[with-secondary-label]
  > div
  > span
  > svg {
  color: var(--yellow70);
}

/* ICON ANIMATION*/

/*.leftSidebar li[class*="selected"] > div:first-of-type > span[class*="icon"] {
  animation: shake 180ms ease-in;
}
@keyframes shake {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(-1);
  }
}*/

.demo_leftSidebar__2ngo9 {
  padding-left: 0;
  padding-right: 0;
  margin-right: 0.75rem;
  margin-left: 0.25rem;
}

/*CHECKBOX SHAPE CSS MODULE*/

/*CONTAINER*/
._type_container__2C_dQ {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  box-shadow: none;
  padding: 0;
  margin: 0;
}
._type_container__2C_dQ label[class*="control"] {
  display: flex;
  padding: 0;
  margin: 0;
}
._type_container__2C_dQ._type_fill__1ptX0 {
  width: 100%;
}
._type_box_layout__3x03c {
  position: absolute;
  border-radius: 0.25rem;
}
._type_text__2oXV3 {
  width: 100%;
  white-space: nowrap;
}
/*to RIGHT*/
._type_container__2C_dQ label[class*="control"]._type_right__1ib0n {
  flex-direction: row-reverse;
}
/*---------------------------------------*/

/*DEFAULT*/
._type_def__3oef- {
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body);
  height: 2.5rem;
  line-height: 2.5rem;
  border-radius: 0.25rem;
}
._type_def__3oef-._type_with_helper__sYH17 {
  height: 2.75rem;
  line-height: 2.75rem;
}
._type_def__3oef- ._type_box_layout__3x03c {
  width: 2.5rem;
  height: 2.5rem;
}
._type_def__3oef-._type_with_helper__sYH17 ._type_box_layout__3x03c {
  top: 0.125rem;
}
._type_def__3oef- ._type_text__2oXV3 {
  margin-left: 0.5rem;
  margin-right: 0.75rem;
}
._type_def__3oef-[class*="control"] span[class*="control-indicator"],
._type_def__3oef-[class*="control"] span[class*="control-indicator"]::before {
  padding: 0;
  margin: auto 0.6875rem;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 0.125rem;
  z-index: 1;
}
._type_def__3oef-._type_checked__39Gtv,
._type_dense__1KEK1._type_checked__39Gtv {
  font-weight: var(--font_weight_body_medium);
  transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
}
._type_def__3oef-._type_checked__39Gtv input:checked ~ span[class*="control-indicator"]::before {
  margin: auto;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath  d='M4.1,12.7 9,17.6 20.3,6.3' fill='none' stroke-width ='2px' stroke='white'/%3e%3c/svg%3e");
}
._type_def__3oef-._type_checked__39Gtv input:indeterminate ~ span[class*="control-indicator"]::before,
._type_def__3oef- input:indeterminate ~ span[class*="control-indicator"]::before {
  margin: auto;
}
/**-------------------------*/

/*DENSE*/
._type_dense__1KEK1 {
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  height: 1.75rem;
  line-height: 1.75rem;
  border-radius: 0.25rem;
}
._type_dense__1KEK1._type_with_helper__sYH17 {
  height: 2.5rem;
  line-height: 2.5rem;
}
._type_dense__1KEK1 ._type_box_layout__3x03c {
  width: 1.75rem;
  height: 1.75rem;
}
._type_dense__1KEK1._type_with_helper__sYH17 ._type_box_layout__3x03c {
  top: 0.375rem;
}
._type_dense__1KEK1 ._type_text__2oXV3 {
  margin-left: 0.375rem;
  margin-right: 0.5rem;
}
._type_dense__1KEK1[class*="control"] span[class*="control-indicator"],
._type_dense__1KEK1[class*="control"] span[class*="control-indicator"]:before {
  padding: 0;
  margin: auto 0.40625rem;
  width: 0.9375rem;
  height: 0.9375rem;
  border-radius: 0.125rem;
  z-index: 1;
}
._type_dense__1KEK1._type_checked__39Gtv input:checked ~ span::before {
  margin: auto;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath  d='M4.1,12.7 9,17.6 20.3,6.3' fill='none' stroke-width ='2px' stroke='white'/%3e%3c/svg%3e") !important;
}
._type_dense__1KEK1._type_checked__39Gtv input:indeterminate ~ span[class*="control-indicator"]::before,
._type_dense__1KEK1 input:indeterminate ~ span[class*="control-indicator"]::before {
  margin: auto;
}
/**--------------------------*/

/*HELPER TEXT*/
._type_helperBox__biAU6 {
  line-height: normal;
  line-height: initial;
  margin-top: auto;
  margin-bottom: auto;
}
._type_helperBox__biAU6 > ._type_helperText__3oAtN {
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  font-weight: normal;
}
/*--------------------------------------------*/

/*RTL*/
._type_rtl__26uIr {
  text-align: right;
}
/*----------------------------------*/

/*FLAT*/
._view_flat__iO-RM {
  color: var(--color100);
}
._view_flat__iO-RM ._view_box_layout__1qfFf {
  background: transparent;
  transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_flat__iO-RM:hover ._view_box_layout__1qfFf {
  background: var(--color5);
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_flat__iO-RM[class*="control"] span[class*="control-indicator"] {
  background-color: transparent !important;
  background-image: none;
  box-shadow: none !important;
  border: 2px solid var(--color70);
}
._view_flat__iO-RM._view_checked__VWfOu {
  background: var(--color5);
}
._view_flat__iO-RM._view_checked__VWfOu span[class*="control-indicator"] {
  border: none;
  box-shadow: none !important;
  background-image: none;
  background-color: var(--color70) !important;
}
._view_flat__iO-RM._view_checked__VWfOu input:checked ~ span::before,
._view_flat__iO-RM._view_checked__VWfOu._view_indeterminate__8tmD8 span[class*="control-indicator"]::before {
  opacity: 1;
  transition: opacity linear 180ms;
}
._view_flat__iO-RM:hover input:checked ~ span[class*="control-indicator"] {
  background-color: var(--color70);
  box-shadow: none;
}
._view_flat__iO-RM input:not(:checked) ~ span[class*="control-indicator"]::before {
  opacity: 0;
  transition: opacity linear 180ms;
}
/*disabled*/
._view_flat__iO-RM._view_disabled__3SRxp {
  color: var(--color40) !important;
}
._view_flat__iO-RM._view_checked__VWfOu._view_disabled__3SRxp span[class*="control-indicator"] {
  background-color: var(--color40) !important;
}
._view_flat__iO-RM._view_disabled__3SRxp span[class*="control-indicator"] {
  border-color: var(--color40) !important;
}
._view_flat__iO-RM._view_disabled__3SRxp._view_checked__VWfOu span {
  border: none !important;
  background-color: none !important;
}
._view_flat__iO-RM._view_disabled__3SRxp:hover input:checked ~ span {
  background-color: none !important;
}
._view_flat__iO-RM._view_disabled__3SRxp:hover ._view_box_layout__1qfFf,
._view_flat__iO-RM._view_checked__VWfOu._view_disabled__3SRxp {
  background: transparent;
}
/*helper*/
._view_flat__iO-RM ._view_helperText__2o-Yx,
._view_flat__iO-RM._view_disabled__3SRxp ._view_helperText__2o-Yx {
  color: var(--color60);
}
/*--------------------------------*/
/*SMOOTH*/
._view_smooth__3uFDx {
  color: var(--color100);
  background: var(--color0);
}
._view_smooth__3uFDx ._view_box_layout__1qfFf {
  background: transparent;
  transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_smooth__3uFDx:hover {
  background: var(--color5);
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_smooth__3uFDx[class*="control"] span[class*="control-indicator"] {
  background-color: transparent !important;
  background-image: none;
  box-shadow: none !important;
  border: 2px solid var(--color70);
}
._view_smooth__3uFDx._view_checked__VWfOu {
  background: var(--color10);
}
._view_smooth__3uFDx._view_checked__VWfOu span[class*="control-indicator"] {
  border: none;
  box-shadow: none !important;
  background-image: none;
  background-color: var(--color70) !important;
}
._view_smooth__3uFDx._view_checked__VWfOu input:checked ~ span::before,
._view_smooth__3uFDx._view_checked__VWfOu._view_indeterminate__8tmD8 span[class*="control-indicator"]::before {
  opacity: 1;
  transition: opacity linear 180ms;
}
._view_smooth__3uFDx:hover input:checked ~ span[class*="control-indicator"] {
  background-color: var(--color70);
  box-shadow: none;
}
._view_smooth__3uFDx input:not(:checked) ~ span[class*="control-indicator"]::before {
  opacity: 0;
  transition: opacity linear 180ms;
}
/*disabled*/
._view_smooth__3uFDx._view_disabled__3SRxp,
._view_smooth__3uFDx._view_checked__VWfOu._view_disabled__3SRxp {
  color: var(--color40) !important;
  background: var(--color0);
}
._view_smooth__3uFDx._view_checked__VWfOu._view_disabled__3SRxp span[class*="control-indicator"] {
  background-color: var(--color40) !important;
}
._view_smooth__3uFDx._view_disabled__3SRxp span[class*="control-indicator"] {
  border-color: var(--color40) !important;
}
._view_smooth__3uFDx._view_disabled__3SRxp._view_checked__VWfOu span {
  border: none !important;
  background-color: none !important;
}
._view_smooth__3uFDx._view_disabled__3SRxp:hover input:checked ~ span {
  background-color: none !important;
}
._view_smooth__3uFDx._view_disabled__3SRxp:hover ._view_box_layout__1qfFf {
  background: transparent;
}
/*helper*/
._view_smooth__3uFDx ._view_helperText__2o-Yx,
._view_smooth__3uFDx._view_disabled__3SRxp ._view_helperText__2o-Yx {
  color: var(--color60);
}
/*--------------------------------*/
/*OUTLINED*/
._view_outlined__17Zmu {
  color: var(--color100);
  box-shadow: 0 0 0 1px var(--color20);
}
._view_outlined__17Zmu ._view_box_layout__1qfFf {
  background: transparent;
  transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_outlined__17Zmu:hover {
  box-shadow: 0 0 0 1px var(--color40);
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_outlined__17Zmu[class*="control"] span[class*="control-indicator"] {
  background-color: transparent !important;
  background-image: none;
  box-shadow: none !important;
  border: 2px solid var(--color70);
}
._view_outlined__17Zmu._view_checked__VWfOu {
  box-shadow: 0 0 0 2px var(--color40);
}
._view_outlined__17Zmu._view_checked__VWfOu span[class*="control-indicator"] {
  border: none;
  box-shadow: none !important;
  background-image: none;
  background-color: var(--color70) !important;
}
._view_outlined__17Zmu._view_checked__VWfOu input:checked ~ span::before,
._view_outlined__17Zmu._view_checked__VWfOu._view_indeterminate__8tmD8 span[class*="control-indicator"]::before {
  opacity: 1;
  transition: opacity linear 180ms;
}
._view_outlined__17Zmu:hover input:checked ~ span[class*="control-indicator"] {
  background-color: var(--color70);
  box-shadow: none;
}
._view_outlined__17Zmu input:not(:checked) ~ span[class*="control-indicator"]::before {
  opacity: 0;
  transition: opacity linear 180ms;
}
/*disabled*/
._view_outlined__17Zmu._view_disabled__3SRxp,
._view_outlined__17Zmu._view_checked__VWfOu._view_disabled__3SRxp {
  color: var(--color40) !important;
  box-shadow: 0 0 0 1px var(--color10);
}
._view_outlined__17Zmu._view_checked__VWfOu._view_disabled__3SRxp span[class*="control-indicator"] {
  background-color: var(--color40) !important;
}
._view_outlined__17Zmu._view_disabled__3SRxp span[class*="control-indicator"] {
  border-color: var(--color40) !important;
}
._view_outlined__17Zmu._view_disabled__3SRxp._view_checked__VWfOu span {
  border: none !important;
  background-color: none !important;
}
._view_outlined__17Zmu._view_disabled__3SRxp:hover input:checked ~ span {
  background-color: none !important;
}
._view_outlined__17Zmu._view_disabled__3SRxp:hover ._view_box_layout__1qfFf {
  background: transparent;
}
/*helper*/
._view_outlined__17Zmu ._view_helperText__2o-Yx,
._view_outlined__17Zmu._view_disabled__3SRxp ._view_helperText__2o-Yx {
  color: var(--color60);
}
/*--------------------------------*/
/*RAISED*/
._view_raised__3HRol {
  color: var(--color100);
  background: var(--color0);
  box-shadow: var(--shadow2dp);
}
._view_raised__3HRol ._view_box_layout__1qfFf {
  background: transparent;
  transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_raised__3HRol:hover {
  background: var(--white);
  box-shadow: var(--shadow4dp);
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_raised__3HRol[class*="control"] span[class*="control-indicator"] {
  background-color: transparent !important;
  background-image: none;
  box-shadow: none !important;
  border: 2px solid var(--color70);
}
._view_raised__3HRol._view_checked__VWfOu {
  background: var(--color5);
  box-shadow: none;
}
._view_raised__3HRol._view_checked__VWfOu span[class*="control-indicator"] {
  border: none;
  box-shadow: none !important;
  background-image: none;
  background-color: var(--color70) !important;
}
._view_raised__3HRol._view_checked__VWfOu input:checked ~ span::before,
._view_raised__3HRol._view_checked__VWfOu._view_indeterminate__8tmD8 span[class*="control-indicator"]::before {
  opacity: 1;
  transition: opacity linear 180ms;
}
._view_raised__3HRol:hover input:checked ~ span[class*="control-indicator"] {
  background-color: var(--color70);
  box-shadow: none;
}
._view_raised__3HRol input:not(:checked) ~ span[class*="control-indicator"]::before {
  opacity: 0;
  transition: opacity linear 180ms;
}
/*disabled*/
._view_raised__3HRol._view_disabled__3SRxp,
._view_raised__3HRol._view_checked__VWfOu._view_disabled__3SRxp {
  color: var(--color40) !important;
  background: var(--color0);
  box-shadow: none;
}
._view_raised__3HRol._view_checked__VWfOu._view_disabled__3SRxp span[class*="control-indicator"] {
  background-color: var(--color40) !important;
}
._view_raised__3HRol._view_disabled__3SRxp span[class*="control-indicator"] {
  border-color: var(--color40) !important;
}
._view_raised__3HRol._view_disabled__3SRxp._view_checked__VWfOu span {
  border: none !important;
  background-color: none !important;
}
._view_raised__3HRol._view_disabled__3SRxp:hover input:checked ~ span {
  background-color: none !important;
}
._view_raised__3HRol._view_disabled__3SRxp:hover ._view_box_layout__1qfFf {
  background: transparent;
}
/*helper*/
._view_raised__3HRol ._view_helperText__2o-Yx,
._view_raised__3HRol._view_disabled__3SRxp ._view_helperText__2o-Yx {
  color: var(--color60);
}
/*--------------------------------*/

/*RADIO SHAPE CSS MODULE*/
/*CONTAINER*/
._type_container__1dh0n {
  align-items: center;
  box-shadow: none;
  border-radius: 99px;
}

/*---------------------------------------*/

._type_def__3LqNg,
._type_dense__27H99 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0;
  display: flex;
}
._type_inline__YU53J._type_def__3LqNg,
._type_inline__YU53J._type_dense__27H99 {
  display: inline-flex;
}
._type_def__3LqNg._type_fill__1HMNn,
._type_dense__27H99._type_fill__1HMNn {
  width: 100%;
}
._type_def__3LqNg {
  height: 2.5rem;
  line-height: 2.5rem;
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body);
}
._type_def__3LqNg[class*="container"] > label[class*="control"],
._type_dense__27H99[class*="container"] > label[class*="control"] {
  padding: 0;
  margin: 0;
  display: flex;
  margin-right: 1rem;
}
._type_def__3LqNg[class*="container"] > label[class*="control"] > span[class*="indicator"] {
  margin: auto 1.125rem auto 0.625rem;
}
._type_def__3LqNg input:checked ~ span[class*="indicator"]::before,
._type_def__3LqNg input:not(:checked) ~ span[class*="indicator"]::before {
  height: 0.5rem;
  width: 0.5rem;
  margin: auto;
  margin-top: 0.25rem;
  border-radius: 99px;
}
._type_def__3LqNg input:checked ~ div {
  letter-spacing: var(--letter_spacing_body_bold);
  font-weight: var(--font_weight_body_medium);
}
/*Dense*/
._type_dense__27H99 {
  height: 1.75rem;
  line-height: 1.75rem;
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
}

._type_dense__27H99[class*="container"]
  > label[class*="control"]
  > span[class*="indicator"] {
  margin: auto 0.75rem auto 0.375rem;
}
._type_dense__27H99 input:checked ~ span[class*="indicator"]::before,
._type_dense__27H99 input:not(:checked) ~ span[class*="indicator"]::before {
  height: 0.375rem;
  width: 0.375rem;
  margin: auto;
  margin-top: 0.1875rem;
  border-radius: 99px;
}
._type_dense__27H99 input:checked ~ div {
  letter-spacing: var(--letter_spacing_body_small);
  font-weight: var(--font_weight_body_medium);
}

._type_def__3LqNg._type_with_helper__GGpl2 {
  height: 2.75rem;
  line-height: 2.75rem;
}

._type_dense__27H99._type_with_helper__GGpl2 {
  height: 2.5rem;
  line-height: 2.5rem;
}

/*HELPER TEXT*/
._type_helperBox__1XsUn {
  line-height: normal;
  line-height: initial;
  margin-top: auto;
  margin-bottom: auto;
}
._type_helperBox__1XsUn > ._type_helperText__2Jw37 {
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  font-weight: normal;
}

/*-----------------------------------------*/

/*LEFT*/
._type_leftPosition__2ifQg[class*="container"] > label[class*="control"] {
  flex-direction: row-reverse;
  margin-left: 1rem;
  margin-right: 0;
}
._type_def__3LqNg._type_leftPosition__2ifQg[class*="container"]
  > label[class*="control"]
  > span[class*="indicator"] {
  margin: auto 0.625rem auto 1.125rem;
}
._type_dense__27H99._type_leftPosition__2ifQg[class*="container"]
  > label[class*="control"]
  > span[class*="indicator"] {
  margin: auto 0.375rem auto 0.75rem;
}

/*------------------------*/

/*RTL*/
._type_rtl__3n6dr > div {
  text-align: right;
}

/*----------------------------------*/

/*RADIO*/

/*FLAT*/
._view_flat__8CgHn {
  color: var(--color100);
}
._view_flat__8CgHn > label[class*="control"] span[class*="indicator"] {
  background-color: transparent;
  background-color: initial;
  background-image: none;
  box-shadow: none;
  border: 2px solid var(--color70);
}
._view_flat__8CgHn:hover:not(._view_disabled__3tlIw)
  > label[class*="control"]
  > span[class*="indicator"] {
  background-color: var(--color5);
  box-shadow: 0 0 0 0.625rem var(--color5);
  transition: box-shadow ease 150ms;
}
._view_flat__8CgHn[class*="dense"]:hover
  > label[class*="control"]
  > span[class*="indicator"],
._view_flat__8CgHn._view_checked__1URSY[class*="dense"]
  > label[class*="control"]
  > span[class*="indicator"] {
  box-shadow: 0 0 0 0.375rem var(--color5);
  background-color: var(--color5);
}
._view_flat__8CgHn._view_checked__1URSY {
  background: var(--color5);
  transition: background ease 250ms;
}
._view_flat__8CgHn[class*="dense"]._view_checked__1URSY {
  background: unset;
  transition: background ease 250ms;
}
._view_flat__8CgHn._view_checked__1URSY input:checked ~ span[class*="indicator"]::before {
  background-image: radial-gradient(
    var(--color70),
    var(--color70) 28%,
    transparent 32%
  );
  background-color: var(--color70);
  transition: transform ease 250ms, background-color ease 250ms;
}
._view_flat__8CgHn:hover input:checked ~ span[class*="indicator"]::before {
  background-image: radial-gradient(
    var(--color70),
    var(--color70) 28%,
    transparent 32%
  );
}
._view_flat__8CgHn input:not(:checked) ~ span[class*="indicator"]::before {
  background-image: radial-gradient(
    var(--color70),
    var(--color70) 28%,
    transparent 32%
  );
  background-color: var(--color70);
  transform: scale(0);
  transition: transform ease 150ms;
}
/*Disabled*/
._view_flat__8CgHn._view_disabled__3tlIw > label[class*="control"] > div {
  color: var(--color40);
}
._view_flat__8CgHn._view_checked__1URSY._view_disabled__3tlIw > label[class*="control"] > span[class*="indicator"] {
  background-color: var(--color5);
}
._view_flat__8CgHn._view_disabled__3tlIw:hover > label[class*="control"] > span[class*="indicator"] {
  background-color: transparent;
  background-color: initial;
  box-shadow: none;
}
._view_flat__8CgHn[class*="dense"]._view_checked__1URSY._view_disabled__3tlIw
  > label[class*="control"]
  > span[class*="indicator"] {
  background-color: var(--color5);
  box-shadow: 0 0 0 0.375rem var(--color5);
}
/*helper*/
._view_flat__8CgHn ._view_helperText__1JeZU,
._view_flat__8CgHn._view_disabled__3tlIw ._view_helperText__1JeZU {
  color: var(--color60);
}
/*-----------*/

/*SMOOTH*/
._view_smooth__99keY {
  color: var(--color100);
  background: var(--color0);
}
._view_smooth__99keY:hover {
  background: var(--color5);
  transition: background ease 250ms;
}
._view_smooth__99keY._view_checked__1URSY {
  background: var(--color10);
  transition: background ease 250ms;
}
._view_smooth__99keY > label[class*="control"] span[class*="indicator"] {
  background-color: transparent;
  background-color: initial;
  background-image: none;
  box-shadow: none;
  border: 2px solid var(--color70);
}
._view_smooth__99keY:hover:not(._view_disabled__3tlIw)
  > label[class*="control"]
  > span[class*="indicator"] {
  background-color: var(--color5);
  box-shadow: none;
  transition: all ease 150ms;
}
._view_smooth__99keY._view_checked__1URSY > label[class*="control"] > span[class*="indicator"],
._view_smooth__99keY._view_checked__1URSY:hover > label[class*="control"] > span[class*="indicator"] {
  background-color: var(--color10);
  transition: all ease 150ms;
}

._view_smooth__99keY._view_checked__1URSY input:checked ~ span[class*="indicator"]::before {
  background-image: radial-gradient(
    var(--color70),
    var(--color70) 28%,
    transparent 32%
  );
  background-color: var(--color70);
  transition: transform ease 250ms, background-color ease 250ms;
}
._view_smooth__99keY:hover input:checked ~ span[class*="indicator"]::before {
  background-image: radial-gradient(
    var(--color70),
    var(--color70) 28%,
    transparent 32%
  );
}
._view_smooth__99keY input:not(:checked) ~ span[class*="indicator"]::before {
  background-image: radial-gradient(
    var(--color70),
    var(--color70) 28%,
    transparent 32%
  );
  background-color: var(--color70);
  transform: scale(0);
  transition: transform ease 150ms;
}
/*Disabled*/
._view_smooth__99keY._view_disabled__3tlIw > label[class*="control"] > div {
  color: var(--color40);
}
._view_smooth__99keY._view_checked__1URSY._view_disabled__3tlIw,
._view_smooth__99keY._view_disabled__3tlIw:hover,
._view_smooth__99keY._view_checked__1URSY._view_disabled__3tlIw > label[class*="control"] > span[class*="indicator"] {
  background-color: var(--color0);
}
._view_smooth__99keY._view_disabled__3tlIw._view_checked__1URSY > label[class*="control"] > span[class*="indicator"] {
  background-color: transparent;
  background-color: initial;
  box-shadow: none;
}
/*helper*/
._view_smooth__99keY ._view_helperText__1JeZU,
._view_smooth__99keY._view_disabled__3tlIw ._view_helperText__1JeZU {
  color: var(--color60);
}
/*-----------*/

/*RAISED*/
._view_raised__1mShv {
  color: var(--color100);
  background: var(--color0);
  box-shadow: var(--shadow2dp);
}
._view_raised__1mShv:hover {
  background: var(--white);
  box-shadow: var(--shadow4dp);
  transition: background ease 250ms;
}
._view_raised__1mShv._view_checked__1URSY {
  background: var(--color5);
  box-shadow: none;
  transition: background ease 250ms;
}
._view_raised__1mShv > label[class*="control"] span[class*="indicator"] {
  background-color: transparent;
  background-color: initial;
  background-image: none;
  box-shadow: none;
  border: 2px solid var(--color70);
}
._view_raised__1mShv:hover:not(._view_disabled__3tlIw)
  > label[class*="control"]
  > span[class*="indicator"] {
  background-color: var(--white);
  box-shadow: none;
  transition: all ease 250ms;
}
._view_raised__1mShv._view_checked__1URSY > label[class*="control"] > span[class*="indicator"],
._view_raised__1mShv._view_checked__1URSY:hover > label[class*="control"] > span[class*="indicator"] {
  background-color: var(--color5);
  transition: all ease 150ms;
}

._view_raised__1mShv._view_checked__1URSY input:checked ~ span[class*="indicator"]::before {
  background-image: radial-gradient(
    var(--color70),
    var(--color70) 28%,
    transparent 32%
  );
  background-color: var(--color70);
  transition: transform ease 250ms, background-color ease 250ms;
}
._view_raised__1mShv:hover input:checked ~ span[class*="indicator"]::before {
  background-image: radial-gradient(
    var(--color70),
    var(--color70) 28%,
    transparent 32%
  );
}
._view_raised__1mShv input:not(:checked) ~ span[class*="indicator"]::before {
  background-image: radial-gradient(
    var(--color70),
    var(--color70) 28%,
    transparent 32%
  );
  background-color: var(--color70);
  transform: scale(0);
  transition: transform ease 150ms;
}
/*Disabled*/
._view_raised__1mShv._view_disabled__3tlIw > label[class*="control"] > div {
  color: var(--color40);
}
._view_raised__1mShv._view_checked__1URSY._view_disabled__3tlIw,
._view_raised__1mShv._view_disabled__3tlIw:hover,
._view_raised__1mShv._view_checked__1URSY._view_disabled__3tlIw > label[class*="control"] > span[class*="indicator"] {
  background-color: var(--color0);
  box-shadow: var(--shadow2dp);
}
._view_raised__1mShv._view_disabled__3tlIw._view_checked__1URSY > label[class*="control"] > span[class*="indicator"] {
  background-color: transparent;
  background-color: initial;
  box-shadow: none;
}
/*helper*/
._view_raised__1mShv ._view_helperText__1JeZU,
._view_raised__1mShv._view_disabled__3tlIw ._view_helperText__1JeZU {
  color: var(--color60);
}
/*-----------*/

/*OUTLINED*/
._view_outlined__ajeyz {
  color: var(--color100);
  background: var(--white);
  box-shadow: 0 0 0 0.0625rem var(--color20);
}
._view_outlined__ajeyz:hover {
  box-shadow: 0 0 0 0.0625rem var(--color40);
  transition: all ease 250ms;
}
._view_outlined__ajeyz._view_checked__1URSY {
  box-shadow: 0 0 0 0.125rem var(--color40);
  background: var(--white);
  transition: all ease 250ms;
}
._view_outlined__ajeyz > label[class*="control"] span[class*="indicator"] {
  background: var(--white);
  background-image: none;
  box-shadow: none;
  border: 2px solid var(--color70);
}
._view_outlined__ajeyz:hover:not(._view_disabled__3tlIw)
  > label[class*="control"]
  > span[class*="indicator"] {
  background-color: var(--white);
  box-shadow: none;
  transition: all ease 250ms;
}
._view_outlined__ajeyz._view_checked__1URSY > label[class*="control"] > span[class*="indicator"] {
  background-color: var(--white);
  background: var(--white);
  box-shadow: none;
  transition: all ease 250ms;
}

._view_outlined__ajeyz._view_checked__1URSY input:checked ~ span[class*="indicator"]::before {
  background-image: radial-gradient(
    var(--color70),
    var(--color70) 28%,
    transparent 32%
  );
  background-color: var(--color70);
  transition: transform ease 250ms, background-color ease 250ms;
}
._view_outlined__ajeyz:hover input:checked ~ span[class*="indicator"]::before {
  background-image: radial-gradient(
    var(--color70),
    var(--color70) 28%,
    transparent 32%
  );
}
._view_outlined__ajeyz input:not(:checked) ~ span[class*="indicator"]::before {
  background-image: radial-gradient(
    var(--color70),
    var(--color70) 28%,
    transparent 32%
  );
  background-color: var(--color70);
  transform: scale(0);
  transition: transform ease 150ms;
}
/*Disabled*/
._view_outlined__ajeyz._view_disabled__3tlIw > label[class*="control"] > div {
  color: var(--color40);
}
._view_outlined__ajeyz._view_checked__1URSY._view_disabled__3tlIw,
._view_outlined__ajeyz._view_disabled__3tlIw:hover,
._view_outlined__ajeyz._view_checked__1URSY._view_disabled__3tlIw
  > label[class*="control"]
  > span[class*="indicator"] {
  box-shadow: 0 0 0 0.0625rem var(--color10);
}
._view_outlined__ajeyz._view_disabled__3tlIw._view_checked__1URSY
  > label[class*="control"]
  > span[class*="indicator"] {
  background-color: transparent;
  background-color: initial;
  box-shadow: none;
}
/*helper*/
._view_outlined__ajeyz ._view_helperText__1JeZU,
._view_outlined__ajeyz._view_disabled__3tlIw ._view_helperText__1JeZU {
  color: var(--color60);
}
/*-----------*/

/*HELPER TEXT*/
/*.helper div {
  color: var(--grey50);
}
.helper.disabled div {
  color: var(--grey40);
}*/

/* TYPOGRAPHY TYPES*/

._type_roboto__2h_Ka {
  font-family: var(--roboto);
}
._type_inter__29pL2 {
  font-family: var(--inter);
}
._type_lato__2sQv- {
  font-family: var(--lato);
}
._type_plex__2hXrO {
  font-family: var(--plex);
}
._type_manrope__3X1El {
  font-family: var(--manrope);
}

._type_h1__2-qzH {
  font-weight: var(--font_weight_h1);
  font-size: var(--font_size_h1);
  letter-spacing: var(--letter_spacing_h1);
  line-height: 120%;
}

._type_h2__1iufF {
  font-weight: var(--font_weight_h2);
  font-size: var(--font_size_h2);
  letter-spacing: var(--letter_spacing_h2);
  line-height: 120%;
}

._type_h3__1HgZr {
  font-weight: var(--font_weight_h3);
  font-size: var(--font_size_h3);
  letter-spacing: var(--letter_spacing_h3);
  line-height: 124%;
}

._type_h4__1t-gl {
  font-weight: var(--font_weight_h4);
  font-size: var(--font_size_h4);
  letter-spacing: var(--letter_spacing_h4);
  line-height: 128%;
}

._type_h5__3aYKA {
  font-weight: var(--font_weight_h5);
  font-size: var(--font_size_h5);
  letter-spacing: var(--letter_spacing_h5);
  line-height: 150%;
}

._type_h6__ZtjcL {
  font-weight: var(--font_weight_h6);
  font-size: var(--font_size_h6);
  letter-spacing: var(--letter_spacing_h6);
  line-height: 150%;
}

._type_body__iT1WN {
  font-weight: var(--font_weight_body);
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body);
  line-height: 150%;
}
._type_body_bold__2Zmby {
  font-weight: var(--font_weight_body_medium);
  letter-spacing: var(--letter_spacing_body_bold);
}
._type_body__iT1WN._type_large__QjIhj {
  font-size: var(--font_size_body_large);
  letter-spacing: var(--letter_spacing_body_large);
  line-height: 158%;
}
._type_body_bold__2Zmby._type_large__QjIhj {
  font-size: var(--font_size_body_large);
  letter-spacing: var(--letter_spacing_body_large_bold);
  line-height: 158%;
}
._type_body__iT1WN._type_small__2TtSX,
._type_body_bold__2Zmby._type_small__2TtSX {
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  line-height: 143%;
}
._type_button_text__2a6fY {
  font-weight: var(--font_weight_button);
  font-size: var(--font_size_button);
  letter-spacing: var(--letter_spacing_button);
  line-height: 114%;
}

._type_caption__3tliZ {
  font-weight: var(--font_weight_caption);
  font-size: var(--font_size_caption);
  letter-spacing: var(--letter_spacing_caption);
  line-height: 126%;
}
._type_caption__3tliZ._type_small__2TtSX {
  font-size: var(--font_size_caption_small);
  font-weight: var(--font_weight_caption_small);
  letter-spacing: var(--letter_spacing_caption_small);
  line-height: 120%;
}

._type_overline__3xJLc {
  font-weight: var(--font_weight_overline);
  font-size: var(--font_size_overline);
  letter-spacing: var(--letter_spacing_overline);
  text-transform: uppercase;
  line-height: 126%;
}
._type_overline__3xJLc._type_small__2TtSX {
  font-size: var(--font_size_overline_small);
  letter-spacing: var(--letter_spacing_overline_small);
  line-height: 120%;
}

._view_color0__1t8ts {
  color: var(--color0);
}
._view_color5__pR69C {
  color: var(--color5);
}
._view_color10__1xKvr {
  color: var(--color10);
}
._view_color20__7dIG3 {
  color: var(--color20);
}
._view_color30__1l4jw {
  color: var(--color30);
}
._view_color40__L5g1n {
  color: var(--color40);
}
._view_color50__25My7 {
  color: var(--color50);
}
._view_color60__25xDP {
  color: var(--color60);
}
._view_color70__238N2 {
  color: var(--color70);
}
._view_color80__9kPC5 {
  color: var(--color80);
}
._view_color90__22cIr {
  color: var(--color90);
}
._view_color100__18IPv {
  color: var(--color100);
}

/* BUTTONS TYPES*/
/*--DEFAULT--*/
._type_default__ZR_4j {
  font-size: var(--font_size_button);
  font-family: var(--font_family_button);
  font-weight: bold;
  letter-spacing: var(--letter-spacing-button);
  padding: 0 0.75rem 0 0.75rem;
}

._type_right__37jKF {
  border-radius: var(--s8) 0 0 var(--s8);
}
._type_left__nSWIF {
  border-radius: 0 var(--s8) var(--s8) 0;
}
._type_top__3dBOk {
  border-radius: 0 0 var(--s8) var(--s8);
}
._type_bottom__38DL8 {
  border-radius: var(--s8) var(--s8) 0 0;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

/*SMOOTH STYLE*/
._view_smooth__1oO59 {
  background: var(--white);
  box-shadow: var(--greyShadow8dp);
}

._view_smooth__1oO59 span[class*="icon"] {
  color: var(--color70);
}
/* END STYLE */

/* TABS SEGMENTED TYPES*/
._type_def__x7eUP div[class*="tab-list"] {
  height: 2.5rem;
  align-items: center;
}
._type_dense__1jKfK div[class*="tab-list"] {
  height: 1.75rem;
  align-items: center;
}

._type_def__x7eUP div[class*="tab"],
._type_dense__1jKfK div[class*="tab"] {
  margin-right: 0;
}

._type_def__x7eUP div[class*="tab"][id*="tab-title"] {
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body);
}
._type_dense__1jKfK div[class*="tab"][id*="tab-title"] {
  font-size: var(--font_size_caption);
  letter-spacing: var(--letter_spacing_caption);
}

._type_def__x7eUP div[class*="tab"][aria-selected="true"],
._type_dense__1jKfK div[class*="tab"][aria-selected="true"] {
  font-weight: var(--font_weight_body_medium);
  letter-spacing: 0.0152em;
}

/*---*/

._type_def__x7eUP div[class*="tab"][id*="tab-title"] {
  line-height: 2.5rem;
}
._type_dense__1jKfK div[class*="tab"][id*="tab-title"] {
  line-height: 1.75rem;
}

/*---*/

._type_def__x7eUP div[class*="tab"][id*="tab-title"] {
  padding: 0 1rem;
  text-align: center;
}
._type_dense__1jKfK div[class*="tab"][id*="tab-title"] {
  padding: 0 0.75rem;
  text-align: center;
}
._type_def__x7eUP div[class*="tab"][id*="tab-title"] > span[class*="icon"] > svg {
  width: 1.25rem;
  height: 1.25rem;
}
/**Right position*/
._type_def__x7eUP div[role="tablist"][class*="tab-list"],
._type_dense__1jKfK div[role="tablist"][class*="tab-list"] {
  margin-left: 0;
}
._type_right__2d2mx div[role="tablist"][class*="tab-list"] {
  margin-left: auto;
  margin-right: 0 !important;
}

/*TABS SEGMENTED*/
/*FLAT*/
._view_flat__1T3nP div[class*="tab-indicator"] {
  height: 0;
}
._view_flat__1T3nP div[class*="tab-list"] {
  background: var(--white);
  border-radius: 4px;
}

._view_flat__1T3nP div[class*="tab"][aria-selected="true"] {
  color: var(--color90);
  font-weight: 500;
}
._view_flat__1T3nP div[class*="tab"][aria-selected="false"] {
  color: var(--color50);
  border-bottom: none;
  transition: box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_flat__1T3nP div[class*="tab"][aria-selected="false"]:hover,
._view_flat__1T3nP div[class*="tab"][aria-selected="false"]:active {
  border-bottom: none;
  color: var(--color90);
  background-color: var(--color0) !important;
  border-radius: 0.25rem;
  box-shadow: none !important;
  transition: all 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}

._view_flat__1T3nP div[class*="tab"][aria-selected="false"] > span > svg {
  fill: var(--color40);
}
._view_flat__1T3nP div[class*="tab"][aria-selected="false"]:hover > span > svg {
  fill: var(--color90);
}
._view_flat__1T3nP div[class*="tab"][aria-selected="true"] > span > svg {
  fill: var(--color100);
}

._view_flat__1T3nP div[class*="tab-indicator-wrapper"] {
  border-radius: 4px;
  background-color: var(--color5);
}
/*END-----*/

/*FILLED*/
._view_filled__1nn4C div[class*="tab-indicator"] {
  height: 0;
}
._view_filled__1nn4C div[class*="tab-list"] {
  background: var(--color10);
  border-radius: 4px;
}

._view_filled__1nn4C div[class*="tab"][aria-selected="true"] {
  color: var(--white);
  font-weight: 500;
}
._view_filled__1nn4C div[class*="tab"][aria-selected="false"] {
  color: var(--color70);
  border-bottom: none;
  transition: box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_filled__1nn4C div[class*="tab"][aria-selected="false"]:hover,
._view_filled__1nn4C div[class*="tab"][aria-selected="false"]:active {
  border-bottom: none;
  color: var(--color100);
  background-color: var(--color20) !important;
  border-radius: 0.25rem;
  box-shadow: none !important;
  transition: all 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}

._view_filled__1nn4C div[class*="tab"][aria-selected="false"] > span > svg {
  fill: var(--color50);
}
._view_filled__1nn4C div[class*="tab"][aria-selected="false"]:hover > span > svg {
  fill: var(--color100);
}
._view_filled__1nn4C div[class*="tab"][aria-selected="true"] > span > svg {
  fill: var(--white);
}

._view_filled__1nn4C div[class*="tab-indicator-wrapper"] {
  border-radius: 4px;
  background-color: var(--color50);
}
/*END-----*/

/*SMOOTH*/
._view_smooth__11rYF div[class*="tab-indicator"] {
  height: 0;
}
._view_smooth__11rYF div[class*="tab-list"] {
  background: var(--color5);
  border-radius: 4px;
}

._view_smooth__11rYF div[class*="tab"][aria-selected="true"] {
  color: var(--color100);
  font-weight: 500;
}
._view_smooth__11rYF div[class*="tab"][aria-selected="false"] {
  color: var(--color50);
  border-bottom: none;
  transition: box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_smooth__11rYF div[class*="tab"][aria-selected="false"]:hover,
._view_smooth__11rYF div[class*="tab"][aria-selected="false"]:active {
  border-bottom: none;
  color: var(--color100);
  background-color: var(--color10) !important;
  border-radius: 0.25rem;
  box-shadow: none !important;
  transition: all 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}

._view_smooth__11rYF div[class*="tab"][aria-selected="false"] > span > svg {
  fill: var(--color40);
}
._view_smooth__11rYF div[class*="tab"][aria-selected="false"]:hover > span > svg {
  fill: var(--color90);
}
._view_smooth__11rYF div[class*="tab"][aria-selected="true"] > span > svg {
  fill: var(--color100);
}

._view_smooth__11rYF div[class*="tab-indicator-wrapper"] {
  border-radius: 4px;
  background-color: var(--color20);
}
/*END-----*/

/*OUTLINED*/
._view_outlined__Rcrsy div[class*="tab-indicator"] {
  height: 0;
}
._view_outlined__Rcrsy div[class*="tab-list"] {
  background: var(--white);
  box-shadow: 0 0 0 1px var(--color20);
  border-radius: 4px;
}

._view_outlined__Rcrsy div[class*="tab"][aria-selected="true"] {
  color: var(--color90);
  font-weight: 500;
  z-index: 1;
}
._view_outlined__Rcrsy div[class*="tab"][aria-selected="false"] {
  color: var(--color50);
  border-bottom: none;
  transition: box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_outlined__Rcrsy div[class*="tab"][aria-selected="false"]:hover,
._view_outlined__Rcrsy div[class*="tab"][aria-selected="false"]:active {
  border-bottom: none;
  color: var(--color90);
  background-color: var(--color0) !important;
  border-radius: 0.25rem;
  box-shadow: none !important;
  transition: all 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}

._view_outlined__Rcrsy div[class*="tab"][aria-selected="false"] > span > svg {
  fill: var(--color40);
}
._view_outlined__Rcrsy div[class*="tab"][aria-selected="false"]:hover > span > svg {
  fill: var(--color90);
}
._view_outlined__Rcrsy div[class*="tab"][aria-selected="true"] > span > svg {
  fill: var(--color90);
}

._view_outlined__Rcrsy div[class*="tab-indicator-wrapper"] {
  border-radius: 0.25rem;
  box-shadow: 0 0 0 2px var(--color30);
  background-color: var(--white);
  z-index: 1;
}
/*END-----*/

/*RAISED*/
._view_raised__38Zkd div[class*="tab-indicator"] {
  height: 0;
}
._view_raised__38Zkd div[class*="tab-list"] {
  background: var(--color0);
  border-radius: 4px;
}

._view_raised__38Zkd div[class*="tab"][aria-selected="true"] {
  color: var(--color90);
  font-weight: 500;
}
._view_raised__38Zkd div[class*="tab"][aria-selected="false"] {
  color: var(--color50);
  border-bottom: none;
  transition: box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_raised__38Zkd div[class*="tab"][aria-selected="false"]:hover,
._view_raised__38Zkd div[class*="tab"][aria-selected="false"]:active {
  border-bottom: none;
  color: var(--color90);
  background-color: var(--color5) !important;
  border-radius: 0.25rem;
  box-shadow: none !important;
  transition: all 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}

._view_raised__38Zkd div[class*="tab"][aria-selected="false"] > span > svg {
  fill: var(--color40);
}
._view_raised__38Zkd div[class*="tab"][aria-selected="false"]:hover > span > svg {
  fill: var(--color90);
}
._view_raised__38Zkd div[class*="tab"][aria-selected="true"] > span > svg {
  fill: var(--color90);
}

._view_raised__38Zkd div[class*="tab-indicator-wrapper"] {
  border-radius: 4px;
  background-color: var(--white);
  box-shadow: var(--shadow2dp);
}
/*END-----*/

._view_filled__1nn4C div[class*="tab-indicator"],
._view_outlined__Rcrsy div[class*="tab-indicator"],
._view_smooth__11rYF div[class*="tab-indicator"],
._view_segmented__10sKz._view_raised__38Zkd div[class*="tab-indicator"],
._view_segmented__10sKz._view_smooth__11rYF div[class*="tab-indicator"] {
  height: 0;
}
/*.flat div[class*="tab"][id*="tab-title"],
.filled div[class*="tab"][id*="tab-title"],
.smooth div[class*="tab"][id*="tab-title"],
.raised div[class*="tab"][id*="tab-title"],
.outlined div[class*="tab"][id*="tab-title"] {
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
}*/

@keyframes _view_tab-flip__2AoQ9 {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

/**TOAST*/
._type_def__3dRHy > div {
  height: auto;
  border-radius: 0.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  z-index: 999;
}
._type_def__3dRHy span[class*="toast-message"] {
  padding: 0;
  margin: auto;
  margin-left: 1rem;
  font-size: var(--font_size_h6);
  font-family: var(--font_secondary);
  letter-spacing: var(--letter_spacing_h6);
  font-weight: bold;
}
._type_def__3dRHy div[class*="button-group"] {
  padding: 0;
  margin: auto;
  margin-right: 1rem;
}
._type_def__3dRHy div[class*="button-group"] svg {
  width: 1.5rem;
  height: 1.5rem;
}
._type_def__3dRHy div[class*="button-group"] a {
  margin-left: 0.5rem;
  font-family: var(--font_primary);
  text-transform: uppercase;
  font-size: var(--font_size_button);
  letter-spacing: var(--letter_spacing_button);
  font-weight: var(--font_weight_button);
}

._type_def__3dRHy span[class*="icon"]:first-child {
  float: left;
  margin: auto;
  border-radius: 99px;
  margin-left: 1rem;
  width: 2.5rem;
  height: 2.5rem;
}
._type_def__3dRHy span[class*="icon"]:first-child > svg {
  margin: auto;
  margin-top: 0.5rem;
}

._type_def__3dRHy._type_no-close__2hkBU div[class*="button-group"] button[class*="button"] {
  display: none;
}

/*TOAST*/

/*flat*/

/*filled*/

._view_filled__2sWrO > div {
  background-color: var(--color70);
  color: var(--color10);
  box-shadow: none;
}
._view_filled__2sWrO div[class*="button-group"] svg {
  color: var(--color40);
  transition: all 180ms ease-in;
}
._view_filled__2sWrO div[class*="button-group"] > button:hover svg,
._view_filled__2sWrO div[class*="button-group"] > button[class*="button"]:hover,
._view_filled__2sWrO div[class*="button-group"] a[class*="button"]:hover {
  color: var(--color10);
  background: none;
  transition: all 180ms ease-in;
}
._view_filled__2sWrO div[class*="button-group"] a {
  color: var(--color30);
  transition: all 180ms ease-in;
}

._view_filled__2sWrO svg,
._view_filled__2sWrO span[class*="icon"]:first-child {
  color: var(--color10);
}

._view_filled__2sWrO div > span[class*="icon"]:first-child {
  background: var(--color80);
}

/*smooth*/

._view_smooth__QFpvi > div {
  background-color: var(--color5);
  color: var(--color80);
  box-shadow: none;
}
._view_smooth__QFpvi div[class*="button-group"] svg {
  color: var(--color40);
  transition: all 180ms ease-in;
}
._view_smooth__QFpvi div[class*="button-group"] > button:hover svg,
._view_smooth__QFpvi div[class*="button-group"] > button[class*="button"]:hover {
  color: var(--color80);
  background: none;
  transition: all 180ms ease-in;
}
._view_smooth__QFpvi div[class*="button-group"] a[class*="button"] {
  color: var(--color70);
  background: var(--white);
  padding: 0.75rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  margin-left: 0.5rem;
  border-radius: 4px;
  box-shadow: var(--shadow4dp);
  transition: all 180ms ease-in;
}
._view_smooth__QFpvi div[class*="button-group"] a[class*="button"]:hover {
  color: var(--color70);
  background: var(--white);
  box-shadow: var(--shadow8dp);
}
._view_smooth__QFpvi div[class*="button-group"] a[class*="button"]:active {
  box-shadow: var(--shadow2dp);
}

._view_smooth__QFpvi svg,
._view_smooth__QFpvi span[class*="icon"]:first-child {
  color: var(--color80);
}

._view_smooth__QFpvi div > span[class*="icon"]:first-child {
  background: var(--color20);
}

/*OUTLINED*/

._view_outlined__1-QOj > div {
  background-color: var(--white);
  color: var(--color80);
  border: 1px solid var(--color30);
  box-shadow: none;
}
._view_outlined__1-QOj div[class*="button-group"] svg {
  color: var(--color40);
  transition: all 180ms ease-in;
}
._view_outlined__1-QOj div[class*="button-group"] > button:hover svg,
._view_outlined__1-QOj div[class*="button-group"] > button[class*="button"]:hover {
  color: var(--color80);
  background: none;
  transition: all 180ms ease-in;
}
/*close button*/
._view_outlined__1-QOj div[class*="button-group"] a[class*="button"] {
  color: var(--color70);
  background: var(--color5);
  padding: 0.75rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  margin-left: 0.5rem;
  border-radius: 4px;
  /*box-shadow: var(--shadow4dp);*/
  transition: all 180ms ease-in;
}
._view_outlined__1-QOj div[class*="button-group"] a[class*="button"]:hover {
  color: var(--color70);
  background: var(--color5);
  box-shadow: var(--shadow4dp);
}
._view_outlined__1-QOj div[class*="button-group"] a[class*="button"]:active {
  box-shadow: none;
  background: var(--color10);
}
/**/

/*icon*/
._view_outlined__1-QOj svg,
._view_outlined__1-QOj span[class*="icon"]:first-child {
  color: var(--color80);
}

._view_outlined__1-QOj div > span[class*="icon"]:first-child {
  background: var(--color10);
}

/*RAISED*/

._view_raised__Zj_y6 > div {
  background-color: var(--white);
  color: var(--color80);
  box-shadow: var(--shadow4dp);
}
._view_raised__Zj_y6 div[class*="button-group"] svg {
  color: var(--grey50);
  transition: all 180ms ease-in;
}
._view_raised__Zj_y6 div[class*="button-group"] > button:hover svg,
._view_raised__Zj_y6 div[class*="button-group"] > button[class*="button"]:hover {
  color: var(--grey90);
  background: none;
  transition: all 180ms ease-in;
}
/*close button*/
._view_raised__Zj_y6 div[class*="button-group"] a[class*="button"] {
  color: var(--color70);
  background: var(--color5);
  padding: 0.75rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  margin-left: 0.5rem;
  border-radius: 4px;
  /*box-shadow: var(--shadow4dp);*/
  transition: all 180ms ease-in;
}
._view_raised__Zj_y6 div[class*="button-group"] a[class*="button"]:hover {
  color: var(--color70);
  background: var(--color5);
  box-shadow: var(--shadow4dp);
}
._view_raised__Zj_y6 div[class*="button-group"] a[class*="button"]:active {
  box-shadow: none;
  background: var(--color10);
}
/*icon*/
._view_raised__Zj_y6 svg,
._view_raised__Zj_y6 span[class*="icon"]:first-child {
  color: var(--color80);
}
._view_raised__Zj_y6 div > span[class*="icon"]:first-child {
  background: var(--color10);
}

/*SWITCH CSS MODULE*/

/*CONTAINER*/
._type_container__3u4dS {
  align-items: center;
  box-shadow: none;
  border-radius: 99px;
}
._type_container__3u4dS > label > div {
  width: -webkit-fill-available;
}

/*---------------------------------------*/
._type_def__wKtnL,
._type_dense__hggTg {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0;
  display: flex;
}
._type_inline__20VHJ._type_def__wKtnL,
._type_inline__20VHJ._type_dense__hggTg {
  display: inline-flex;
}
._type_fill__3VI03,
._type_fill__3VI03 > label[class*="control"] {
  width: 100%;
}
._type_def__wKtnL {
  height: 2.5rem;
  line-height: 2.5rem;
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body);
}
._type_def__wKtnL[class*="container"] > label[class*="control"]:not(._type_bp3-align-right__cv3nv),
._type_dense__hggTg[class*="container"] > label[class*="control"]:not(._type_bp3-align-right__cv3nv) {
  padding: 0;
  margin: 0;
  display: flex;
  margin-right: 1rem;
}
._type_def__wKtnL[class*="container"]
  > label[class*="control"]:not(._type_bp3-align-right__cv3nv)
  > span[class*="indicator"] {
  margin: auto 1.125rem auto 0.625rem;
  height: 0.875rem;
  min-width: 2.25rem;
}

._type_def__wKtnL input:checked ~ span[class*="indicator"]::before,
._type_def__wKtnL input:not(:checked) ~ span[class*="indicator"]::before {
  height: 1.25rem;
  width: 1.25rem;
  margin: auto;
  margin-top: -0.1875rem;
  border-radius: 99px;
}
/*iOS like style*/
._type_def__wKtnL._type_i-style__3DmBI[class*="container"]
  > label[class*="control"]:not(._type_bp3-align-right__cv3nv)
  > span[class*="indicator"] {
  height: 1.5rem;
  min-width: 2.5rem;
}
._type_def__wKtnL._type_i-style__3DmBI input:checked ~ span[class*="indicator"]::before,
._type_def__wKtnL._type_i-style__3DmBI input:not(:checked) ~ span[class*="indicator"]::before {
  height: 1.5rem;
  width: 1.5rem;
  margin: auto;
}
._type_def__wKtnL._type_i-style__3DmBI input:checked ~ span[class*="indicator"]::before {
  left: calc(100% - 1.5rem);
}
/**/
._type_def__wKtnL input:checked ~ div {
  letter-spacing: var(--letter_spacing_body_bold);
  font-weight: var(--font_weight_body_medium);
}
/*Dense*/
._type_dense__hggTg {
  height: 1.75rem;
  line-height: 1.75rem;
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
}
._type_dense__hggTg[class*="container"]
  > label[class*="control"]:not(._type_bp3-align-right__cv3nv)
  > span[class*="indicator"] {
  margin: auto 1.125rem auto 0.625rem;
  height: 0.75rem;
}
._type_dense__hggTg input:checked ~ span[class*="indicator"]::before,
._type_dense__hggTg input:not(:checked) ~ span[class*="indicator"]::before {
  height: 1rem;
  width: 1rem;
  margin: auto;
  margin-top: -0.125rem;
  border-radius: 99px;
}
/*iOS like style*/
._type_dense__hggTg._type_i-style__3DmBI[class*="container"]
  > label[class*="control"]:not(._type_bp3-align-right__cv3nv)
  > span[class*="indicator"] {
  height: 1.125rem;
  min-width: 2.125rem;
}
._type_dense__hggTg._type_i-style__3DmBI input:checked ~ span[class*="indicator"]::before,
._type_dense__hggTg._type_i-style__3DmBI input:not(:checked) ~ span[class*="indicator"]::before {
  height: 1.125rem;
  width: 1.125rem;
  margin: auto;
}
._type_dense__hggTg._type_i-style__3DmBI input:checked ~ span[class*="indicator"]::before {
  left: calc(100% - 1.125rem);
}
/**/
._type_dense__hggTg input:checked ~ div {
  letter-spacing: var(--letter_spacing_body_small);
  font-weight: var(--font_weight_body_medium);
}

/*-----------------------------------------*/
._type_def__wKtnL._type_with_helper__3qqww {
  height: 2.75rem;
  line-height: 2.75rem;
}

._type_dense__hggTg._type_with_helper__3qqww {
  height: 2.5rem;
  line-height: 2.5rem;
}

/*HELPER TEXT*/
._type_helperBox__1aOJc {
  line-height: normal;
  line-height: initial;
  margin-top: auto;
  margin-bottom: auto;
}
._type_helperBox__1aOJc > ._type_helperText__3x15M {
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  font-weight: normal;
}

._type_helperBox__1aOJc > ._type_helperText--dense__1gg7P {
  font-size: var(--font_size_caption);
  letter-spacing: var(--letter_spacing_caption);
  font-weight: normal;
}

/*--------------------------------------------*/

/*LEFT*/
._type_leftPosition__3D9Pa[class*="container"]
  > label[class*="control"]:not(._type_bp3-align-right__cv3nv) {
  flex-direction: row-reverse;
  margin-left: 1rem;
  margin-right: 0;
}
._type_def__wKtnL._type_leftPosition__3D9Pa[class*="container"]
  > label[class*="control"]
  > span[class*="indicator"] {
  margin: auto 0.625rem auto 1.125rem;
}
._type_dense__hggTg._type_leftPosition__3D9Pa[class*="container"]
  > label[class*="control"]
  > span[class*="indicator"] {
  margin: auto 0.375rem auto 0.75rem;
}

/*------------------------*/

/*RTL*/
._type_rtl__3WFqV > div {
  text-align: right;
}

/*----------------------------------*/

/*SWITCH*/
/*FLAT*/
._view_flat__2EUWe > label {
  color: var(--color100);
}
._view_flat__2EUWe._view_checked__24N1H > label {
  color: var(--color100);
}
._view_flat__2EUWe > label[class*="control"] > span[class*="indicator"],
._view_flat__2EUWe
  > label[class*="control"]
  > input:not(:disabled):active
  ~ span[class*="indicator"],
._view_flat__2EUWe > label[class*="control"]:hover > span[class*="indicator"] {
  background: var(--color10);
  transition: all ease 250ms;
}
._view_flat__2EUWe._view_checked__24N1H {
  background: var(--color5);
  transition: all ease 250ms;
}
._view_flat__2EUWe._view_checked__24N1H > label[class*="control"] > span[class*="indicator"],
._view_flat__2EUWe._view_checked__24N1H > label[class*="control"]:hover > span[class*="indicator"] {
  background: var(--color30);
  transition: all ease 250ms;
}
._view_flat__2EUWe._view_checked__24N1H
  > label[class*="control"]
  > input:checked:not(:disabled):active
  ~ span[class*="indicator"],
._view_flat__2EUWe._view_checked__24N1H
  > label[class*="control"]
  > input:checked:not(:disabled)
  ~ span[class*="indicator"] {
  background: var(--color30);
  transition: all ease 250ms;
}
._view_flat__2EUWe > label[class*="control"] > input:not(:checked) ~ span::before {
  background: var(--color30);
  box-shadow: none;
  transition: all ease 250ms;
}
._view_flat__2EUWe > label[class*="control"]:hover > input:not(:checked) ~ span::before {
  background: var(--color50);
  box-shadow: none;
  transition: all ease 250ms;
}
._view_flat__2EUWe._view_checked__24N1H > label[class*="control"] > input:checked ~ span::before {
  background: var(--color70);
  box-shadow: none;
  transition: all ease 250ms;
}
._view_flat__2EUWe._view_checked__24N1H > label[class*="control"]:hover > input:checked ~ span::before {
  background: var(--color80);
}
._view_flat__2EUWe._view_disabled__1C8og {
  background: none;
}
/*END FLAT*/

/*SMOOTH*/
._view_smooth__2my6V {
  background: var(--color0);
}
._view_smooth__2my6V:hover {
  background: var(--color5);
  transition: all ease 250ms;
}
._view_smooth__2my6V > label {
  color: var(--color100);
}
._view_smooth__2my6V._view_checked__24N1H > label {
  color: var(--color100);
}
._view_smooth__2my6V > label[class*="control"] > span[class*="indicator"],
._view_smooth__2my6V
  > label[class*="control"]
  > input:not(:disabled):active
  ~ span[class*="indicator"],
._view_smooth__2my6V > label[class*="control"]:hover > span[class*="indicator"] {
  background: var(--color10);
  transition: all ease 250ms;
}
._view_smooth__2my6V._view_checked__24N1H {
  background: var(--color10);
  transition: all ease 250ms;
}
._view_smooth__2my6V._view_checked__24N1H > label[class*="control"] > span[class*="indicator"],
._view_smooth__2my6V._view_checked__24N1H > label[class*="control"]:hover > span[class*="indicator"] {
  background: var(--color30);
  transition: all ease 250ms;
}
._view_smooth__2my6V._view_checked__24N1H
  > label[class*="control"]
  > input:checked:not(:disabled):active
  ~ span[class*="indicator"],
._view_smooth__2my6V._view_checked__24N1H
  > label[class*="control"]
  > input:checked:not(:disabled)
  ~ span[class*="indicator"] {
  background: var(--color30);
  transition: all ease 250ms;
}
._view_smooth__2my6V > label[class*="control"] > input:not(:checked) ~ span::before {
  background: var(--color30);
  box-shadow: none;
  transition: all ease 250ms;
}
._view_smooth__2my6V > label[class*="control"]:hover > input:not(:checked) ~ span::before {
  background: var(--color50);
  box-shadow: none;
  transition: all ease 250ms;
}
._view_smooth__2my6V._view_checked__24N1H > label[class*="control"] > input:checked ~ span::before {
  background: var(--color70);
  box-shadow: none;
  transition: all ease 250ms;
}
._view_smooth__2my6V._view_checked__24N1H > label[class*="control"]:hover > input:checked ~ span::before {
  background: var(--color80);
}
._view_smooth__2my6V._view_disabled__1C8og {
  background: var(--color0);
}
/*END SMOOTH*/

/*OUTLINED*/
._view_outlined__TnBKL {
  box-shadow: 0 0 0 1px var(--color20);
}
._view_outlined__TnBKL:hover {
  box-shadow: 0 0 0 1px var(--color40);
  transition: all ease 250ms;
}
._view_outlined__TnBKL > label {
  color: var(--color100);
}
._view_outlined__TnBKL._view_checked__24N1H > label {
  color: var(--color100);
}
._view_outlined__TnBKL > label[class*="control"] > span[class*="indicator"],
._view_outlined__TnBKL
  > label[class*="control"]
  > input:not(:disabled):active
  ~ span[class*="indicator"],
._view_outlined__TnBKL > label[class*="control"]:hover > span[class*="indicator"] {
  background: var(--color10);
  transition: all ease 250ms;
}
._view_outlined__TnBKL._view_checked__24N1H {
  box-shadow: 0 0 0 2px var(--color40);
  transition: all ease 250ms;
}
._view_outlined__TnBKL._view_checked__24N1H > label[class*="control"] > span[class*="indicator"],
._view_outlined__TnBKL._view_checked__24N1H > label[class*="control"]:hover > span[class*="indicator"] {
  background: var(--color30);
  transition: all ease 250ms;
}
._view_outlined__TnBKL._view_checked__24N1H
  > label[class*="control"]
  > input:checked:not(:disabled):active
  ~ span[class*="indicator"],
._view_outlined__TnBKL._view_checked__24N1H
  > label[class*="control"]
  > input:checked:not(:disabled)
  ~ span[class*="indicator"] {
  background: var(--color30);
  transition: all ease 250ms;
}
._view_outlined__TnBKL > label[class*="control"] > input:not(:checked) ~ span::before {
  background: var(--color0);
  box-shadow: none;
  border: 0.125rem solid var(--color30);
  transition: all ease 250ms;
}
._view_outlined__TnBKL > label[class*="control"]:hover > input:not(:checked) ~ span::before {
  background: var(--color0);
  box-shadow: none;
  border: 0.125rem solid var(--color50);
  transition: all ease 250ms;
}
._view_outlined__TnBKL._view_checked__24N1H > label[class*="control"] > input:checked ~ span::before {
  background: var(--color0);
  box-shadow: none;
  border: 0.25rem solid var(--color70);
  transition: all ease 250ms;
}
._view_outlined__TnBKL._view_checked__24N1H
  > label[class*="control"]:hover
  > input:checked
  ~ span::before {
  border: 0.25rem solid var(--color80);
}
._view_outlined__TnBKL._view_disabled__1C8og {
  box-shadow: 0 0 0 1px var(--color10);
}
/*END OUTLINED*/

/*DISABLED*/
div[class*="container"] > label[class*="disabled"] {
  color: var(--color40);
}
._view_disabled__1C8og
  > label[class*="disabled"]
  > input:not(:checked):disabled
  ~ span[class*="ontrol-indicator"],
._view_disabled__1C8og
  > label[class*="disabled"]
  > input:checked:disabled
  ~ span[class*="ontrol-indicator"] {
  background: var(--color5);
}
._view_disabled__1C8og
  > label[class*="disabled"]
  > input:not(:checked):disabled
  ~ span[class*="control-indicator"]::before,
._view_disabled__1C8og
  > label[class*="disabled"]
  input:checked:disabled
  ~ span[class*="control-indicator"]::before {
  background: var(--color10);
}

/* CHIPS STYLES*/

._type_def__19Nrv[class*="tag"] {
  min-width: 0px;
  height: 2rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5rem;
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body);
  text-transform: none;
  padding: 0.25rem 0.625rem;
  justify-content: left;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
._type_def__19Nrv[class*="tag"]:focus,
._type_dense__P-uld[class*="tag"]:focus {
  outline: none !important;
}

._type_def__19Nrv[class*="tag"] span[icon]:first-of-type {
  margin-left: 0;
  margin-right: 0.375rem;
}
._type_def__19Nrv[class*="tag"] span[icon]:last-of-type {
  margin-right: 0;
  margin-left: 0.375rem;
}
._type_def__19Nrv[class*="tag"] span[icon]:first-of-type > svg {
  height: 1.25rem;
  width: 1.25rem;
}
._type_def__19Nrv[class*="tag"] span[class*="icon-tick"]:first-of-type {
  width: 0;
  height: 0;
  margin-right: 0;
  transform: scale(0);
  transition: all 200ms ease-in;
}
._type_def__19Nrv._type_active__1IN_j[class*="tag"] span[class*="icon-tick"]:first-of-type {
  height: 1.25rem;
  width: 1.25rem;
  margin-right: 0.375rem;
  transform: scaleX(1);
  transition: all 200ms ease-in;
}
._type_def__19Nrv[class*="tag"] button[class*="tag-remove"] > span[class*="small-cross"] {
  margin-right: 0;
  margin-left: 0.375rem;
}
._type_def__19Nrv[class*="tag"] button[class*="tag-remove"] {
  padding: 0;
  padding-right: 0;
  margin-right: 0 !important;
}
._type_def__19Nrv[class*="tag"] span[class*="text"] {
  margin-right: 0;
}
/*dense*/
._type_dense__P-uld[class*="tag"] {
  min-width: 0px;
  height: 1.25rem;
  min-height: 1.25rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1rem;
  font-size: var(--font_size_caption);
  letter-spacing: var(--letter_spacing_caption);
  text-transform: none;
  padding: 0.125rem 0.375rem;
  justify-content: left;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
._type_dense__P-uld[class*="tag"] span[icon]:first-of-type {
  margin-left: 0;
  margin-right: 0.25rem;
}
._type_dense__P-uld[class*="tag"] span[icon]:last-of-type {
  margin-right: 0;
  margin-left: 0.25rem;
}
._type_dense__P-uld[class*="tag"] span[icon]:first-of-type > svg {
  height: 1rem;
  width: 1rem;
}
._type_dense__P-uld[class*="tag"] span[class*="icon-tick"]:first-of-type {
  width: 0;
  height: 0;
  margin-right: 0;
  transform: scale(0);
  transition: all 200ms ease-in;
}
._type_dense__P-uld._type_active__1IN_j[class*="tag"] span[class*="icon-tick"]:first-of-type {
  height: 1rem;
  width: 1rem;
  margin-right: 0.25rem;
  transform: scaleX(1);
  transition: all 200ms ease-in;
}
._type_dense__P-uld[class*="tag"] button[class*="tag-remove"] > span[class*="small-cross"] {
  margin-right: 0;
  margin-left: 0.125rem;
}
._type_dense__P-uld[class*="tag"] button[class*="tag-remove"] {
  padding: 0;
  padding-right: 0;
  margin-right: 0 !important;
}
._type_dense__P-uld[class*="tag"] span[class*="text"] {
  margin-right: 0;
}
/*END dense*/
/*ROUNDED*/
._type_round__3VOgZ[class*="tag"] {
  border-radius: 99px;
}
/*-----------------------*/

/*WITH NUMBER */
._type_def__19Nrv[class*="tag"]._type_number__9AOrj,
._type_dense__P-uld[class*="tag"]._type_number__9AOrj {
  line-height: 1.25rem;
}
._type_def__19Nrv[class*="tag"]._type_number__9AOrj span > div {
  min-width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  border-radius: 0.25rem;
  text-align: center;
  margin-right: 0.375rem;
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  font-weight: var(--font_weight_body_medium);
  padding: 0 0.4375rem;
}
._type_def__19Nrv[class*="tag"]._type_number__9AOrj._type_round__3VOgZ span > div {
  border-radius: 99px;
}

._type_dense__P-uld[class*="tag"]._type_number__9AOrj span > div {
  min-width: 0;
  height: 1.25rem;
  display: inline-block;
  text-align: center;
  margin-right: 0.25rem;
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  font-weight: var(--font_weight_body_medium);
  padding: 0 0;
  line-height: 1.25rem;
}

/*-----------*/

/*WITH AVATAR*/
._type_def__19Nrv[class*="tag"]._type_avatar__pToR2,
._type_dense__P-uld[class*="tag"]._type_avatar__pToR2 {
  border-radius: 99px;
}
._type_dense__P-uld[class*="tag"]._type_avatar__pToR2 {
  padding-left: 0;
}
._type_def__19Nrv[class*="tag"]._type_avatar__pToR2 span[class*="text"],
._type_dense__P-uld[class*="tag"]._type_avatar__pToR2 span[class*="text"] {
  line-height: 0;
}
._type_def__19Nrv[class*="tag"]._type_avatar__pToR2 span > div:first-of-type,
._type_dense__P-uld[class*="tag"]._type_avatar__pToR2 span > div:first-of-type {
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  text-align: center;
  margin-right: 0.375rem;
  vertical-align: middle;
}
._type_dense__P-uld[class*="tag"]._type_avatar__pToR2 span > div:first-of-type {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.25rem;
}
._type_def__19Nrv[class*="tag"]._type_avatar__pToR2 span > div:first-of-type > img {
  width: 1.5rem;
  height: 1.5rem;
}
._type_dense__P-uld[class*="tag"]._type_avatar__pToR2 span > div:first-of-type > img {
  width: 1.25rem;
  height: 1.25rem;
}
._type_dense__P-uld[class*="tag"]._type_avatar__pToR2._type_outlined__20rT7 span > div:first-of-type > img {
  width: 1.125rem;
  height: 1.125rem;
  margin-top: 0.0625rem;
}
._type_def__19Nrv[class*="tag"]._type_avatar__pToR2 span > div:last-of-type,
._type_dense__P-uld[class*="tag"]._type_avatar__pToR2 span > div:last-of-type {
  display: inline-block;
  vertical-align: middle;
}
/**END AVATAR*/

/* CHIPS VIEW STYLES*/

/*SMOOTH STYLE*/
._view_smooth__yl3BB[class*="tag"],
._view_smooth__yl3BB[class*="tag"]:visited {
  color: var(--color70);
  background-color: var(--color5);
  transition: all 180ms ease-in;
}
._view_smooth__yl3BB[class*="tag"]:hover {
  box-shadow: var(--shadow2dp);
  background-color: var(--color5);
  transition: all 200ms ease-in;
}
._view_smooth__yl3BB[class*="tag"] svg {
  color: var(--color40);
}
._view_smooth__yl3BB[class*="tag"] button[class*="tag-remove"] {
  opacity: 1;
}
._view_smooth__yl3BB[class*="tag"]
  button[class*="tag-remove"]
  > span[class*="small-cross"]
  > svg {
  color: var(--color50);
}
._view_smooth__yl3BB[class*="tag"]
  button[class*="tag-remove"]:hover
  > span[class*="small-cross"]
  > svg {
  color: var(--color70);
  opacity: 1;
  transition: all 180ms ease-in;
}
._view_smooth__yl3BB[class*="tag"]._view_active__6wNEj {
  color: var(--color0);
  background-color: var(--color40);
  transition: all 180ms ease-in;
}
._view_smooth__yl3BB[class*="tag"]._view_active__6wNEj svg,
._view_smooth__yl3BB[class*="tag"]._view_active__6wNEj svg:hover {
  background-color: transparent;
  color: var(--color0);
}

._view_smooth__yl3BB[class*="tag"]._view_disabled__2TDMx,
._view_smooth__yl3BB[class*="tag"]._view_disabled__2TDMx svg,
._view_smooth__yl3BB[class*="tag"]._view_disabled__2TDMx._view_active__6wNEj,
._view_smooth__yl3BB[class*="tag"]._view_disabled__2TDMx._view_active__6wNEj svg,
._view_smooth__yl3BB[class*="tag"]._view_disabled__2TDMx:hover,
._view_smooth__yl3BB[class*="tag"]._view_disabled__2TDMx
  button[class*="tag-remove"]:hover
  > span[class*="small-cross"]
  > svg,
._view_smooth__yl3BB[class*="tag"]._view_disabled__2TDMx
  button[class*="tag-remove"]
  > span[class*="small-cross"]
  > svg,
._view_smooth__yl3BB[class*="tag"]._view_disabled__2TDMx
  button[class*="tag-remove"]:hover
  > span[class*="small-cross"]
  > svg,
._view_smooth__yl3BB[class*="tag"]._view_disabled__2TDMx button[class*="tag-remove"],
._view_smooth__yl3BB[class*="tag"]._view_disabled__2TDMx button[class*="tag-remove"]:hover {
  background-color: var(--color5);
  color: var(--color30);
  box-shadow: none;
  cursor: no-drop;
}

/* END STYLE */

/*OUTLINED STYLE*/
._view_outlined__1ztD2[class*="tag"],
._view_outlined__1ztD2[class*="tag"]:visited {
  color: var(--color70);
  background-color: var(--white);
  border: 0.0625rem solid var(--color30);
  transition: all 100ms ease-in;
}
._view_outlined__1ztD2[class*="tag"]:hover {
  box-shadow: 0 0 0 1px var(--color30);
  background-color: var(--white);
  border: 0.0625rem solid var(--color30);
  transition: all 200ms ease-in;
}
._view_outlined__1ztD2[class*="tag"]._view_active__6wNEj {
  background-color: var(--color5);
  border: 0.0625rem solid var(--color50);
  box-shadow: 0 0 0 1px var(--color50);
  color: var(--color70);
  transition: all 150ms ease-in;
}
._view_outlined__1ztD2[class*="tag"]._view_active__6wNEj svg,
._view_outlined__1ztD2[class*="tag"]._view_active__6wNEj svg:hover {
  color: var(--color70);
  transition: all 150ms ease-in;
}

._view_outlined__1ztD2[class*="tag"] button[class*="tag-remove"] {
  background: transparent;
  opacity: 1;
  transition: all 150ms ease-in;
}
._view_outlined__1ztD2[class*="tag"] svg {
  color: var(--color30);
  transition: all 150ms ease-in;
}
._view_outlined__1ztD2[class*="tag"] button[class*="tag-remove"] > span > svg {
  color: var(--color50);
}

._view_outlined__1ztD2[class*="tag"] button[class*="tag-remove"]:hover > span > svg {
  color: var(--color70);
  transition: all 180ms ease-in;
}

._view_outlined__1ztD2[class*="tag"]._view_disabled__2TDMx,
._view_outlined__1ztD2[class*="tag"]._view_disabled__2TDMx svg,
._view_outlined__1ztD2[class*="tag"]._view_disabled__2TDMx._view_active__6wNEj,
._view_outlined__1ztD2[class*="tag"]._view_disabled__2TDMx._view_active__6wNEj svg,
._view_outlined__1ztD2[class*="tag"]._view_disabled__2TDMx:hover,
._view_outlined__1ztD2[class*="tag"]._view_disabled__2TDMx svg:hover,
._view_outlined__1ztD2[class*="tag"]._view_disabled__2TDMx button[class*="tag-remove"] > span > svg,
._view_outlined__1ztD2[class*="tag"]._view_disabled__2TDMx
  button[class*="tag-remove"]:hover
  > span
  > svg {
  background-color: transparent;
  color: var(--color30);
  border: 0.0625rem solid var(--color10);
  box-shadow: none;
  cursor: no-drop;
  opacity: 1;
}
._view_outlined__1ztD2[class*="tag"]._view_disabled__2TDMx button[class*="tag-remove"] > span > svg,
._view_outlined__1ztD2[class*="tag"]._view_disabled__2TDMx button[class*="tag-remove"]:hover > span > svg,
._view_outlined__1ztD2[class*="tag"]._view_disabled__2TDMx svg:hover,
._view_outlined__1ztD2[class*="tag"]._view_disabled__2TDMx svg,
._view_outlined__1ztD2[class*="tag"]._view_disabled__2TDMx._view_active__6wNEj svg {
  border: none;
}
/* END STYLE */
/*WITH NUMBER*/
._view_smooth__yl3BB[class*="tag"]._view_number__36AB- span > div,
._view_outlined__1ztD2[class*="tag"]._view_number__36AB- span > div {
  background: var(--color70);
  color: var(--color0);
}
._view_smooth__yl3BB[class*="tag"]._view_number__36AB-._view_dense__29IWO span > div,
._view_outlined__1ztD2[class*="tag"]._view_number__36AB-._view_dense__29IWO span > div {
  background: none;
  color: var(--color100);
}
._view_smooth__yl3BB[class*="tag"]._view_number__36AB-._view_disabled__2TDMx span > div,
._view_outlined__1ztD2[class*="tag"]._view_number__36AB-._view_disabled__2TDMx span > div {
  background: var(--color50);
}
._view_smooth__yl3BB[class*="tag"]._view_number__36AB-._view_disabled__2TDMx._view_dense__29IWO span > div,
._view_outlined__1ztD2[class*="tag"]._view_number__36AB-._view_disabled__2TDMx._view_dense__29IWO span > div {
  color: var(--color70);
  background: none;
}

/* TOOLTIP*/
._type_def__eHwsr > div[class*="popover-arrow"] {
  width: 21px;
  height: 21px;
}
._type_def__eHwsr > div[class*="popover-arrow"]::before {
  margin: 3px;
  border-radius: 0;
}

/*TOOLTIP*/

/*FILLED*/
._view_filled__1Yk_2 div[class*="popover-content"] {
  background: var(--color70);
  color: var(--white);
}
._view_filled__1Yk_2 div[class*="popover-arrow"] > svg > path:last-of-type {
  fill: var(--color70);
}
._view_filled__1Yk_2 div[class*="popover-arrow"] > svg > path:first-of-type {
  fill-opacity: 0;
  fill: none;
}
._view_filled__1Yk_2 > div[class*="popover-arrow"]::before {
  box-shadow: none;
}
._view_filled__1Yk_2 {
  box-shadow: var(--shadow8dp);
}

/*SMOOTH*/
._view_smooth__3xiK4 div[class*="popover-content"] {
  background: var(--color10);
  color: var(--color100);
}
._view_smooth__3xiK4 div[class*="popover-arrow"] > svg > path:last-of-type {
  fill: var(--color10);
}
._view_smooth__3xiK4 div[class*="popover-arrow"] > svg > path:first-of-type {
  fill-opacity: 0;
  fill: none;
}
._view_smooth__3xiK4 > div[class*="popover-arrow"]::before {
  box-shadow: none;
}
._view_smooth__3xiK4 {
  box-shadow: none;
}
/*RAISED*/
._view_raised__1Ssn8 div[class*="popover-content"] {
  background: var(--color0);
  color: var(--color100);
}
._view_raised__1Ssn8 div[class*="popover-arrow"] > svg > path:last-of-type {
  fill: var(--color0);
}
._view_raised__1Ssn8 div[class*="popover-arrow"] > svg > path:first-of-type {
  fill-opacity: 0;
  fill: none;
}
._view_raised__1Ssn8 > div[class*="popover-arrow"]::before,
._view_raised__1Ssn8 {
  box-shadow: var(--shadow4dp);
}

/**CALLOUT*/

._type_def__2ZQjL[class*="callout"] {
  padding: 0.75rem 1rem 0.75rem 3.5rem;
  border-radius: 0.5rem;
  font-size: var(--font_size_body);
}
._type_dense__3BY3z[class*="callout"] {
  padding: 0.5rem 0.75rem 0.5rem 3rem;
  border-radius: 0.25rem;
  font-size: var(--font_size_body_small);
}
._type_def__2ZQjL[class*="callout"] span[class*="icon"]:first-child {
  float: left;
  position: absolute;
  top: 1.125rem;
  left: 1.375rem;
}
._type_dense__3BY3z[class*="callout"] span[class*="icon"]:first-child {
  float: left;
  position: absolute;
  top: 0.875rem;
  left: 1.125rem;
}
._type_def__2ZQjL[class*="callout"] h4[class*="heading"] {
  font-size: var(--font_size_h5);
  font-family: var(--font_primary);
  font-weight: var(--font_weight_h5);
  letter-spacing: var(--letter_spacing_h5);
  line-height: 2.25rem;
  margin-bottom: 0.5rem;
}
._type_dense__3BY3z[class*="callout"] h4[class*="heading"] {
  font-size: var(--font_size_h6);
  font-family: var(--font_primary);
  font-weight: var(--font_weight_h6);
  letter-spacing: var(--letter_spacing_h6);
  line-height: 1.875rem;
  margin-bottom: 0.25rem;
}

/*CALLOUT*/

/*filled*/
._view_filled__3Giao[class*="callout"],
._view_filled__3Giao[class*="callout"] h4[class*="heading"] {
  background-color: var(--color70);
  color: var(--color0);
}
._view_filled__3Giao[class*="callout"] span[class*="icon"]:first-child > svg,
._view_filled__3Giao[class*="callout"] span[class*="icon"]:first-child {
  color: var(--color20);
}

/*smooth*/
._view_smooth__z1ath[class*="callout"],
._view_smooth__z1ath[class*="callout"] h4[class*="heading"] {
  background-color: var(--color10);
  color: var(--color100);
}
._view_smooth__z1ath[class*="callout"] span[class*="icon"]:first-child > svg,
._view_smooth__z1ath[class*="callout"] span[class*="icon"]:first-child {
  color: var(--color70);
}

/*outlined*/
._view_outlined__jPUWT[class*="callout"] {
  background-color: var(--white);
  color: var(--color100);
  border: 2px solid var(--color30);
}
._view_outlined__jPUWT[class*="callout"] h4[class*="heading"] {
  color: var(--color100);
}
._view_outlined__jPUWT[class*="callout"] span[class*="icon"]:first-child > svg,
._view_outlined__jPUWT[class*="callout"] span[class*="icon"]:first-child {
  color: var(--color70);
}

/*raised*/
._view_raised__26XNm[class*="callout"] {
  background-color: var(--color0);
  color: var(--color100);
  box-shadow: var(--shadow2dp);
}
._view_raised__26XNm[class*="callout"] h4[class*="heading"] {
  color: var(--color100);
}
._view_raised__26XNm[class*="callout"] span[class*="icon"]:first-child > svg,
._view_raised__26XNm[class*="callout"] span[class*="icon"]:first-child {
  color: var(--color70);
}

/*MENU SHAPE CSS MODULE*/
/*DEFAULT*/
._type_def__swHtP,
._type_def__swHtP div[class*="popover-content"] > ul[class*="menu"] {
  border-radius: 0.5rem;
  margin: 0;
  padding: 0;
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body);
}
._type_def__swHtP div[class*="popover-content"] {
  border-radius: 0.5rem;
}
._type_def__swHtP > li:not([class*="menu-divider"]),
._type_def__swHtP
  div[class*="popover-content"]
  > ul[class*="menu"]
  > li:not([class*="menu-divider"]) {
  height: 2.5rem;
  margin: 0;
}
._type_def__swHtP > li:first-of-type,
._type_def__swHtP div[class*="popover-content"] > ul[class*="menu"] > li:first-of-type {
  padding-top: 0.5rem;
  line-height: 3rem;
  height: 3rem;
}
._type_def__swHtP > li:last-of-type,
._type_def__swHtP div[class*="popover-content"] > ul[class*="menu"] > li:last-of-type {
  padding-bottom: 0.5rem;
  height: 3rem;
}
._type_def__swHtP div[class*="popover"][class*="submenu"],
._type_dense__3Sxx- div[class*="popover"][class*="submenu"] {
  padding: 0;
  margin-left: -0.0625rem;
}
._type_def__swHtP div[class*="popover"][class*="submenu"],
._type_dense__3Sxx- div[class*="popover"][class*="submenu"] {
  animation: _type_slide-fwd-right__2Na3H 0.28s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/*Heading divider*/
._type_def__swHtP > li[class*="menu-header"]:not([class*="menu-divider"]),
._type_dense__3Sxx- > li[class*="menu-header"]:not([class*="menu-divider"]) {
  margin-top: 0.5rem;
}
/*----*/

/*DENSE*/
._type_dense__3Sxx-,
._type_dense__3Sxx- div[class*="popover-content"] > ul[class*="menu"] {
  border-radius: 0.375rem;
  margin: 0;
  padding: 0;
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
}

._type_dense__3Sxx- div[class*="popover-content"] {
  border-radius: 0.375rem;
}
._type_dense__3Sxx- > li:not([class*="menu-divider"]),
._type_dense__3Sxx-
  div[class*="popover-content"]
  > ul[class*="menu"]
  > li:not([class*="menu-divider"]) {
  height: 1.75rem;
  margin: 0;
}
._type_dense__3Sxx- > li:first-of-type,
._type_dense__3Sxx- div[class*="popover-content"] > ul[class*="menu"] > li:first-of-type {
  padding-top: 0.5rem;
  line-height: 2.25rem;
  height: 2.25rem;
}
._type_dense__3Sxx- > li:last-of-type,
._type_dense__3Sxx- div[class*="popover-content"] > ul[class*="menu"] > li:last-of-type {
  padding-bottom: 0.5rem;
  height: 2.25rem;
}

/*-------------------*/

@keyframes _type_slide-fwd-right__2Na3H {
  0% {
    transform: scale(0.9) translateX(-20%);
  }
  100% {
    transform: scale(1) translateX(0);
  }
}

/*MENU VIEW*/
/*SMOOTH*/
._view_smooth__1PE6P,
._view_smooth__1PE6P div[class*="popover-content"] > ul[class*="menu"] {
  box-shadow: var(--shadow2dp);
  color: var(--color100);
  background: var(--color0);
}
._view_smooth__1PE6P div[class*="popover"] > div[class*="popover-content"] {
  box-shadow: none;
}
._view_smooth__1PE6P svg {
  color: var(--color70);
}
._view_smooth__1PE6P > li:not([class*="menu-divider"]) a {
  background-color: unset;
}
._view_smooth__1PE6P > li:not([class*="menu-divider"]) a:hover:not([class*="disabled"]) {
  background-color: var(--white);
}
._view_smooth__1PE6P a[class*="disabled"] > div,
._view_smooth__1PE6P a > span[class*="label"],
._view_smooth__1PE6P a[class*="disabled"] > span[class*="item-label"],
._view_smooth__1PE6P a[class*="disabled"] svg,
._view_smooth__1PE6P a > span[class*="label"] > span > svg {
  color: var(--color40) !important;
}
._view_smooth__1PE6P
  > li[class*="submenu"]
  span[class*="popover-wrapper"]
  > span[class*="popover-open"]
  > a:not(:hover) {
  background-color: var(--color5);
}

/*OUTLINED*/
._view_outlined__2-QRJ,
._view_outlined__2-QRJ div[class*="popover-content"] > ul[class*="menu"] {
  box-shadow: none;
  color: var(--color100);
  background: var(--white);
  border: 0.125rem solid var(--color30);
}
._view_outlined__2-QRJ div[class*="popover"] > div[class*="popover-content"] {
  box-shadow: none;
}
._view_outlined__2-QRJ svg {
  color: var(--color70);
}
._view_outlined__2-QRJ > li:not([class*="menu-divider"]) a {
  background-color: unset;
}
._view_outlined__2-QRJ > li:not([class*="menu-divider"]) a:hover:not([class*="disabled"]) {
  background-color: var(--color5);
}
._view_outlined__2-QRJ a[class*="disabled"] > div,
._view_outlined__2-QRJ a > span[class*="label"],
._view_outlined__2-QRJ a[class*="disabled"] > span[class*="item-label"],
._view_outlined__2-QRJ a[class*="disabled"] svg,
._view_outlined__2-QRJ a > span[class*="label"] > span > svg {
  color: var(--color40) !important;
}
._view_outlined__2-QRJ
  > li[class*="submenu"]
  span[class*="popover-wrapper"]
  > span[class*="popover-open"]
  > a:not(:hover) {
  background-color: var(--color5);
}
/*-----*/
/*RAISED*/
._view_raised__1MaEi,
._view_raised__1MaEi div[class*="popover-content"] > ul[class*="menu"] {
  box-shadow: var(--shadow8dp);
  color: var(--color100);
  background: var(--white);
}
._view_raised__1MaEi div[class*="popover"] > div[class*="popover-content"] {
  box-shadow: none;
}
._view_raised__1MaEi svg {
  color: var(--color70);
}
._view_raised__1MaEi > li:not([class*="menu-divider"]) a {
  background-color: unset;
}
._view_raised__1MaEi > li[class*="menu-divider"] {
  border-top-color: var(--color10);
}
._view_raised__1MaEi > li:not([class*="menu-divider"]) a:hover:not([class*="disabled"]) {
  background-color: var(--color5);
}
._view_raised__1MaEi a[class*="disabled"] > div,
._view_raised__1MaEi a > span[class*="label"],
._view_raised__1MaEi a[class*="disabled"] > span[class*="item-label"],
._view_raised__1MaEi a[class*="disabled"] svg,
._view_raised__1MaEi a > span[class*="label"] > span > svg {
  color: var(--color40) !important;
}
._view_raised__1MaEi
  > li[class*="submenu"]
  span[class*="popover-wrapper"]
  > span[class*="popover-open"]
  > a:not(:hover) {
  background-color: var(--color5);
}
/*-----*/
/*FILLED*/
._view_filled__2LgWc,
._view_filled__2LgWc div[class*="popover-content"] > ul[class*="menu"] {
  box-shadow: var(--shadow8dp);
  color: var(--color0);
  background: var(--color70);
}
._view_filled__2LgWc div[class*="popover"] > div[class*="popover-content"] {
  box-shadow: none;
}
._view_filled__2LgWc svg {
  color: var(--color40);
}
._view_filled__2LgWc > li:not([class*="menu-divider"]) a {
  background-color: transparent;
}
._view_filled__2LgWc > li[class*="menu-divider"] {
  border-top-color: var(--color80);
}
._view_filled__2LgWc > li:not([class*="menu-divider"]) a:hover:not([class*="disabled"]) {
  background-color: var(--color90);
}
._view_filled__2LgWc a[class*="disabled"] > div,
._view_filled__2LgWc a > span[class*="label"],
._view_filled__2LgWc a[class*="disabled"] > span[class*="item-label"],
._view_filled__2LgWc a[class*="disabled"] svg,
._view_filled__2LgWc a > span[class*="label"] > span > svg {
  color: var(--color40) !important;
}
._view_filled__2LgWc
  > li[class*="submenu"]
  span[class*="popover-wrapper"]
  > span[class*="popover-open"]
  > a:not(:hover) {
  background-color: var(--color90);
}

/*MENU ITEM CSS MODULE*/
/*DEFAULT*/
._type_def__2wXx8,
._type_dense__1UApX {
  line-height: 2.5rem;
  padding: 0;
  margin: 0 0.5rem;
  border-radius: 0.25rem;
}
._type_def__2wXx8 > div[class*="fill"] {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}
._type_def__2wXx8 > span[class*="icon"],
._type_dense__1UApX > span[class*="icon"] {
  margin-bottom: auto;
  margin-top: auto;
  margin-left: 0.75rem;
  margin-right: -0.25rem;
}

._type_def__2wXx8 > span[class*="menu-item-label"],
._type_dense__1UApX > span[class*="menu-item-label"] {
  margin-bottom: auto;
  margin-top: auto;
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}
._type_def__2wXx8 > span[class*="icon"] > svg {
  width: 1.25rem;
  height: 1.25rem;
}
._type_def__2wXx8 > span[class*="menu-item-label"] > span[class*="icon"],
._type_dense__1UApX > span[class*="menu-item-label"] > span[class*="icon"] {
  display: block;
}

/*DENSE*/
._type_dense__1UApX {
  line-height: 1.75rem;
}
._type_dense__1UApX > div[class*="fill"] {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
._type_dense__1UApX > span[class*="icon"] {
  margin-left: 0.5rem;
  margin-right: -0.125rem;
}
._type_dense__1UApX > span[class*="menu-item-label"] {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
/*--------------*/
._type_def__2wXx8 > span[class*="icon-caret-right"],
._type_dense__1UApX > span[class*="icon-caret-right"] {
  margin-right: 0.5rem;
}

/*MENU ITEM*/
._view_smooth__19_cH._view_note__7j4aw,
._view_outlined__jlFJZ._view_note__7j4aw,
._view_raised__2cAMw._view_note__7j4aw {
  color: var(--color80);
}
._view_filled__hg57u._view_note__7j4aw {
  color: var(--color10);
}

/*MENU DIVIDER SHAPE CSS MODULE*/

._type_def__2vKAM,
._type_dense__1XgIq {
  margin: 0.5rem;
  padding: 0;
}
._type_def__2vKAM > h6 {
  line-height: 2.5rem;
  padding: 0;
  margin-left: 0.5rem;
}

._type_dense__1XgIq > h6 {
  line-height: 1.75rem;
  padding: 0;
  margin-left: 0.5rem;
}

/*MENU DIVIDER VIEW*/

._view_smooth__1kQxi > h6,
._view_outlined__3AShT > h6 {
  color: var(--color80);
}
._view_filled__2twZv > h6 {
  color: var(--color30);
}

._view_smooth__1kQxi,
._view_outlined__3AShT {
  border-top: 0.0625rem solid var(--color10);
}

/* SLIDER TYPES*/
._type_def__37FQj /*default*/
	 {
  min-width: 0;
}
._type_def__37FQj div[class*="slider-track"] {
  height: 0.25rem;
  top: 0.625rem;
  border-radius: 99px;
}

._type_def__37FQj span[class*="slider-handle"] {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 99px;
}

._type_def__37FQj span[class*="slider-handle"] > span[class*="slider-label"] {
  margin-left: 0.75rem;
  border-radius: 0.25rem;
  top: 0.5rem;
  font-size: var(--font_size_body_small);
  font-weight: var(--font_weight_body_medium);
  letter-spacing: var(--letter_spacing_body_small);
  min-width: 40px;
  height: 20px;
  padding: 4px 6px;
  text-align: center;
  line-height: 100%;
}
._type_def__37FQj div[class*="slider-axis"] > div[class*="slider-label"] {
  border-radius: 0.25rem;
  top: 0.5rem;
  font-size: var(--font_size_body_small);
  font-weight: var(--font_weight_body);
  letter-spacing: var(--letter_spacing_body_small);
  min-width: 40px;
  height: 20px;
  padding: 4px 6px;
  text-align: center;
  line-height: 100%;
}
._type_def__37FQj[class*="vertical"] div[class*="slider-axis"] > div[class*="slider-label"] {
  top: auto;
  top: initial;
  left: 0.25rem;
}
._type_def__37FQj[class*="vertical"]
  span[class*="slider-handle"]
  > span[class*="slider-label"] {
  top: auto;
  top: initial;
  left: 0.5rem;
}
._type_def__37FQj[class*="vertical"] span[class*="slider-handle"] {
  left: -0.25rem;
}

/*PROGRESS BAR*/

._view_filled__3g1Gk div[class*="slider-progress"]:first-of-type,
._view_filled__3g1Gk div[class*="slider-progress"]:last-of-type {
  background: var(--color20);
}
._view_filled__3g1Gk div[class*="slider-progress"]:nth-of-type(2) {
  background-color: var(--color70);
}

._view_filled__3g1Gk div[class*="slider-axis"] > div[class*="slider-label"] {
  color: var(--color40);
}

._view_filled__3g1Gk span[class*="slider-handle"] {
  background: var(--color0);
  box-shadow: var(--shadow2dp);
  transition: box-shadow 200ms ease-in-out;
}
._view_filled__3g1Gk:hover span[class*="slider-handle"] {
  background: var(--white);
  background-color: var(--white);
  box-shadow: var(--shadow4dp);
  transition: box-shadow 200ms ease-in-out;
}
._view_filled__3g1Gk span[class*="slider-handle"][class*="active"] {
  background: var(--color50);
  background-color: var(--color50);
  box-shadow: none;
  transition: box-shadow 200ms ease-in-out;
}

._view_filled__3g1Gk span[class*="slider-handle"] > span[class*="slider-label"] {
  background: var(--color5);
  color: var(--color100);
  box-shadow: none;
}
/*.filled span[class*="slider-handle"] > span[class*="slider-handle"]:active {
  background: var(--grey10);
}*/

/* PROGRESS BAR TYPES*/
._type_def__dwNo9 /*default*/
	 {
}

/*PROGRESS BAR*/

._view_filled__2FaNW {
  background: var(--color5);
}

._view_filled__2FaNW > div[class*="progress-meter"] {
  background-color: var(--color60);
}

/*SMOOTH STYLE*/
._view_smooth__33mG2 svg > path[class*="spinner-track"] {
  stroke: var(--color0);
}
._view_smooth__33mG2 svg > path[class*="spinner-head"] {
  stroke: var(--color20);
}
/*END STYLE*/

/*FILLED STYLE*/
._view_filled__f0--E svg > path[class*="spinner-track"] {
  stroke: var(--color20);
}
._view_filled__f0--E svg > path[class*="spinner-head"] {
  stroke: var(--color80);
}
/*END STYLE*/

/* TABS TYPES*/
._type_def__1ToRQ div[class*="tab-list"] {
  height: 2.5rem;
  align-items: center;
}
._type_dense__36Sjo div[class*="tab-list"] {
  height: 1.75rem;
  align-items: center;
}

._type_def__1ToRQ div[class*="tab"],
._type_dense__36Sjo div[class*="tab"] {
  margin-right: 0;
}

._type_def__1ToRQ div[class*="tab"][id*="tab-title"] {
  /*text-transform: uppercase;*/
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body);
}
._type_dense__36Sjo div[class*="tab"][id*="tab-title"] {
  /* text-transform: initial;*/
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
}

/*
.def div[class*="tab"][aria-selected="true"],
.dense div[class*="tab"][aria-selected="true"] {
  font-weight: var(--font_weight_body_medium);
  letter-spacing: 0.0152em;
}*/

/*---*/
._type_def__1ToRQ[class*="outlined"] div[class*="tab"][id*="tab-title"] {
  line-height: calc(2.5rem);
}
._type_dense__36Sjo[class*="outlined"] div[class*="tab"][id*="tab-title"] {
  line-height: calc(1.75rem + 0.0625rem);
}

._type_def__1ToRQ[class*="flat"] div[class*="tab"][id*="tab-title"],
._type_def__1ToRQ[class*="filled"] div[class*="tab"][id*="tab-title"],
._type_def__1ToRQ[class*="smooth"] div[class*="tab"][id*="tab-title"],
._type_def__1ToRQ[class*="raised"] div[class*="tab"][id*="tab-title"] {
  line-height: 2.5rem;
}
._type_dense__36Sjo[class*="flat"] div[class*="tab"][id*="tab-title"],
._type_dense__36Sjo[class*="filled"] div[class*="tab"][id*="tab-title"],
._type_dense__36Sjo[class*="smooth"] div[class*="tab"][id*="tab-title"],
._type_dense__36Sjo[class*="raised"] div[class*="tab"][id*="tab-title"] {
  line-height: 1.75rem;
}

/*---*/

._type_def__1ToRQ div[class*="tab"][id*="tab-title"] > span[class*="icon"],
._type_dense__36Sjo div[class*="tab"][id*="tab-title"] > span[class*="icon"] {
  margin-right: 0.5rem;
}
._type_def__1ToRQ div[class*="tab"][id*="tab-title"] > span[class*="icon"] > svg {
  width: 1.25rem;
  height: 1.25rem;
}
/*SEGMENTED*/
._type_segmented__1TOv3 div[class*="tab-list"] {
  height: 1.75rem;
  align-items: center;
}
._type_segmented__1TOv3 div[class*="tab"] {
  margin-right: 0;
}
._type_segmented__1TOv3 div[class*="tab"][id*="tab-title"] > span[class*="icon"] {
  margin-right: 0.5rem;
}
._type_segmented__1TOv3 div[class*="tab"][id*="tab-title"] {
  text-transform: none;
  text-transform: initial;
  font-size: var(--font_size_caption);
  letter-spacing: var(--letter_spacing_caption);
}
._type_segmented__1TOv3 div[class*="tab"][id*="tab-title"] {
  line-height: 1.75rem;
}
/**Fill container*/
._type_def__1ToRQ,
._type_dense__36Sjo,
._type_segmented__1TOv3 {
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
}
._type_fill__1EQP5 {
  width: unset;
}

/*TABS */
/*FLAT*/
._view_flat__3k-oZ div[class*="tab"][aria-selected="true"] {
  color: var(--color100);
}
._view_flat__3k-oZ div[class*="tab"][aria-selected="true"] > span > svg,
._view_flat__3k-oZ div[class*="tab"][aria-selected="false"]:hover > span > svg {
  fill: var(--color70);
}
._view_flat__3k-oZ div[class*="tab"][aria-selected="false"] {
  color: var(--color70);
  transition: box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_flat__3k-oZ div[class*="tab"][aria-selected="false"] > span > svg {
  fill: var(--color30);
}

._view_flat__3k-oZ div[class*="tab"][aria-selected="false"]:hover,
._view_flat__3k-oZ div[class*="tab"][aria-selected="false"]:active {
  color: var(--color100);
  background-color: var(--color0) !important;
  box-shadow: 0px -0.25rem 0px 0px var(--color10) inset !important;
  border-radius: 0.25rem 0.25rem 0 0;
  transition: all 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}

._view_flat__3k-oZ div[class*="tab-indicator-wrapper"] {
  z-index: 1;
}

._view_flat__3k-oZ div[class*="tab-indicator"]:not([class*="tab-indicator-wrapper"]) {
  height: 0.25rem;
  border-radius: 0.25rem;
  background-color: var(--color70);
  /*box-shadow: var(--shadow2dp);*/
}

._view_flat__3k-oZ[class*="dense"] div[class*="tab"][aria-selected="false"]:hover,
._view_flat__3k-oZ[class*="dense"] div[class*="tab"][aria-selected="false"]:active {
  box-shadow: 0px -0.125rem 0px 0px var(--color10) inset !important;
}
._view_flat__3k-oZ[class*="dense"]
  div[class*="tab-indicator"]:not([class*="tab-indicator-wrapper"]) {
  height: 0.125rem;
}
/*-----*/
/*FLAT SEGMENTED*/
._view_flat__3k-oZ[class*="segmented"] div[class*="tab-indicator"] {
  height: 0;
}
._view_flat__3k-oZ[class*="segmented"] div[class*="tab-indicator-wrapper"] {
  z-index: 0;
}

._view_flat__3k-oZ[class*="segmented"] div[class*="tab-list"] {
  background: var(--white);
  border-radius: 4px;
}
._view_flat__3k-oZ[class*="segmented"] div[class*="tab"][aria-selected="false"] {
  color: var(--color50);
  border-bottom: none;
}
._view_flat__3k-oZ[class*="segmented"] div[class*="tab"][aria-selected="false"]:hover,
._view_flat__3k-oZ[class*="segmented"] div[class*="tab"][aria-selected="false"]:active {
  border-bottom: none;
  color: var(--color90);
  background-color: var(--color0);
  border-radius: 0.25rem;
  box-shadow: none !important;
  transition: all 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_flat__3k-oZ[class*="segmented"] div[class*="tab"][aria-selected="true"] {
  color: var(--color90);
  font-weight: 500;
}
._view_flat__3k-oZ[class*="segmented"] div[class*="tab-indicator-wrapper"] {
  border-radius: 4px;
  background-color: var(--color5);
}

._view_flat__3k-oZ[class*="segmented"]
  div[class*="tab"][aria-selected="false"]
  > span
  > svg {
  fill: var(--color40);
}
._view_flat__3k-oZ[class*="segmented"]
  div[class*="tab"][aria-selected="false"]:hover
  > span
  > svg {
  fill: var(--color90);
}
._view_flat__3k-oZ[class*="segmented"] div[class*="tab"][aria-selected="true"] > span > svg {
  fill: var(--color100);
}

/*END-----*/

/*FILLED*/
._view_filled__2ZVpN div[class*="tab-list"] {
  background: var(--color5);
  border-radius: 4px 4px 0 0;
  /*width: fit-content;*/
}

._view_filled__2ZVpN div[class*="tab"][aria-selected="false"] {
  color: var(--color60);
}

._view_filled__2ZVpN div[class*="tab"][aria-selected="false"]:hover,
._view_filled__2ZVpN div[class*="tab"][aria-selected="false"]:active {
  box-shadow: 1px -2px 0px -1px var(--color40) inset !important;
  transition: box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}

._view_filled__2ZVpN div[class*="tab-indicator-wrapper"] {
  border: none;
  border-radius: 4px 4px 0 0;
  background: var(--color60); /*linear-gradient(90deg, var(--gradient));*/
}

._view_filled__2ZVpN div[class*="tab"][aria-selected="true"] {
  color: var(--white);
}
/*--------------*/
/*FILLED SEGMENTED*/
._view_segmented__38knl._view_filled__2ZVpN div[class*="tab-list"] {
  border-radius: 4px;
  border-bottom: none;
}
._view_segmented__38knl._view_filled__2ZVpN div[class*="tab"][aria-selected="false"] {
  color: var(--grey50);
  border-bottom: none;
}
._view_segmented__38knl._view_filled__2ZVpN div[class*="tab"][aria-selected="false"]:hover {
  color: var(--color60);
}
._view_segmented__38knl._view_filled__2ZVpN div[class*="tab"][aria-selected="false"]:hover {
  border-bottom: none;
  box-shadow: none !important;
}
._view_segmented__38knl._view_filled__2ZVpN div[class*="tab"][aria-selected="false"]:active {
  border-bottom: none;
}
._view_segmented__38knl._view_filled__2ZVpN div[class*="tab-indicator-wrapper"] {
  border-radius: 4px;
}
/*END-----*/

/*SMOOTH*/
._view_smooth__3Wd1x div[class*="tab-list"] {
  background: var(--color0); /*test*/
  border-radius: 4px 4px 0 0;
  /* width: fit-content;*/
}

._view_smooth__3Wd1x div[class*="tab"][aria-selected="true"] {
  color: var(--color70);
}
._view_smooth__3Wd1x div[class*="tab"][aria-selected="false"] {
  box-shadow: none !important;
  color: var(--color40);
  transition: box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}

._view_smooth__3Wd1x div[class*="tab"][aria-selected="false"]:hover,
._view_smooth__3Wd1x div[class*="tab"][aria-selected="false"]:active {
  box-shadow: 1px -2px 0px -1px var(--color70) inset !important;
  color: var(--color70);
  transition: box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}

._view_smooth__3Wd1x div[class*="tab-indicator-wrapper"] {
  background-color: var(--color5);
  border-radius: 4px 4px 0 0;
}

._view_smooth__3Wd1x div[class*="tab-indicator"]:not([class*="tab-indicator-wrapper"]) {
  height: 3px;
  border-radius: 4px 4px 0 0;
  background-color: var(--color70);
  box-shadow: var(--shadow2dp);
}
._view_dense__3y8Nz._view_smooth__3Wd1x
  div[class*="tab-indicator"]:not([class*="tab-indicator-wrapper"]) {
  height: 1px;
}
/*-----*/
/*SMOOTH SEGMENTED*/
._view_segmented__38knl._view_smooth__3Wd1x div[class*="tab-list"] {
  background: var(--color0);
  border-radius: 4px;
}
._view_segmented__38knl._view_smooth__3Wd1x div[class*="tab"][aria-selected="false"] {
  color: var(--color50);
  border-bottom: none;
}
._view_segmented__38knl._view_smooth__3Wd1x div[class*="tab"][aria-selected="false"]:hover,
._view_segmented__38knl._view_smooth__3Wd1x div[class*="tab"][aria-selected="false"]:active {
  border-bottom: none;
  color: var(--color70);
  box-shadow: none !important;
  transition: all 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_segmented__38knl._view_smooth__3Wd1x div[class*="tab-indicator-wrapper"] {
  border-radius: 4px;
  background-color: var(--color10);
}
/*END-----*/

/*OUTLINED*/
._view_outlined__2NItk div[class*="tab-list"] {
  /*width: fit-content;*/
}
._view_segmented__38knl._view_outlined__2NItk div[class*="tab-list"] {
  border: 1px solid var(--color20);
  border-radius: 4px;
}
._view_outlined__2NItk div[class*="tab"][aria-selected="true"] {
  color: var(--color70);
  box-shadow: 0px 2px 0px -1px var(--color40) inset,
    2px -1px 0px -1px var(--color40) inset,
    -2px -1px 0px -1px var(--color40) inset !important;
  border-radius: 4px 4px 0 0;
  border-bottom: none;
  transform-origin: 0 100%;
  animation: _view_tab-flip__1TIZv 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_segmented__38knl._view_outlined__2NItk div[class*="tab"][aria-selected="true"] {
  border: none;
  box-shadow: none !important;
  animation: none;
}
._view_outlined__2NItk div[class*="tab"][aria-selected="false"] {
  border-radius: 4px 4px 0 0;
  border-bottom: none;
  color: var(--color40);
  box-shadow: 1px -2px 0px -1px var(--color40) inset !important;
  transition: box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_segmented__38knl._view_outlined__2NItk div[class*="tab"][aria-selected="false"] {
  border-bottom: none;
  color: var(--color50);
  box-shadow: none !important;
}
._view_segmented__38knl._view_outlined__2NItk div[class*="tab"][aria-selected="false"] svg {
  color: var(--color30);
}
._view_outlined__2NItk div[class*="tab"][aria-selected="false"]:hover,
._view_segmented__38knl._view_outlined__2NItk div[class*="tab"][aria-selected="false"]:hover svg {
  color: var(--color70);
  transition: all 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_segmented__38knl._view_outlined__2NItk div[class*="tab-indicator-wrapper"] {
  border: 1px solid var(--color40);
  border-radius: 4px;
  margin-top: 0;
  background: var(--color0);
}
/*----*/

/*RAISED*/
._view_raised__3F2y9 div[class*="tab-list"] {
  background: var(--color5);
  border-radius: 4px 4px 0 0;
  /*width: fit-content;*/
}
._view_raised__3F2y9 div[class*="tab"][aria-selected="true"],
._view_raised__3F2y9 div[class*="tab"][aria-selected="false"] {
  color: var(--color70);
}
._view_raised__3F2y9 div[class*="tab"][aria-selected="true"] {
  border-radius: 4px 4px 0 0;
  transition: all 180ms ease-out;
}
._view_raised__3F2y9 div[class*="tab"][aria-selected="false"] {
  box-shadow: 0px -3px 0px -1px var(--color20) inset !important;
  transition: box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_dense__3y8Nz._view_raised__3F2y9 div[class*="tab"][aria-selected="false"] {
  transition: box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_raised__3F2y9 div[class*="tab"][aria-selected="false"]:hover,
._view_raised__3F2y9 div[class*="tab"][aria-selected="false"]:active {
  color: var(--color70);
  box-shadow: 0px -3px 0px -1px var(--color40) inset !important;
  transition: box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_dense__3y8Nz._view_raised__3F2y9 div[class*="tab"][aria-selected="false"]:hover,
._view_dense__3y8Nz._view_raised__3F2y9 div[class*="tab"][aria-selected="false"]:active {
  box-shadow: 0px -2px 0px -1px var(--color40) inset !important;
  transition: box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_raised__3F2y9 div[class*="tab-indicator-wrapper"] {
  box-shadow: var(--shadow2dp);
  background-color: var(--white);
  border-radius: 4px 4px 0 0;
}
._view_raised__3F2y9 div[class*="tab-indicator"]:not([class*="tab-indicator-wrapper"]) {
  height: 2px;
  background-color: var(--color60);
}
._view_dense__3y8Nz._view_raised__3F2y9
  div[class*="tab-indicator"]:not([class*="tab-indicator-wrapper"]) {
  height: 1px;
}
/*---*/

/*Raised Segmented*/
._view_segmented__38knl._view_raised__3F2y9 div[class*="tab-list"] {
  border-radius: 4px;
  border-bottom: none;
}
._view_segmented__38knl._view_raised__3F2y9 div[class*="tab"][aria-selected="false"] {
  color: var(--color50);
  border-bottom: none;
  box-shadow: none !important;
}

._view_segmented__38knl._view_raised__3F2y9 div[class*="tab"][aria-selected="false"]:hover,
._view_segmented__38knl._view_raised__3F2y9 div[class*="tab"][aria-selected="false"]:active {
  border-bottom: none;
  box-shadow: none !important;
  color: var(--color60);
  transition: all 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
}

._view_segmented__38knl._view_raised__3F2y9 div[class*="tab-indicator-wrapper"] {
  border-radius: 4px;
  box-shadow: var(--shadow2dp);
  background-color: var(--white);
}

/*-----*/

._view_filled__2ZVpN div[class*="tab-indicator"],
._view_outlined__2NItk div[class*="tab-indicator"],
._view_smooth__3Wd1x div[class*="tab-indicator"],
._view_segmented__38knl._view_raised__3F2y9 div[class*="tab-indicator"],
._view_segmented__38knl._view_smooth__3Wd1x div[class*="tab-indicator"] {
  height: 0;
}
._view_flat__3k-oZ div[class*="tab"][id*="tab-title"],
._view_filled__2ZVpN div[class*="tab"][id*="tab-title"],
._view_smooth__3Wd1x div[class*="tab"][id*="tab-title"],
._view_raised__3F2y9 div[class*="tab"][id*="tab-title"],
._view_outlined__2NItk div[class*="tab"][id*="tab-title"] {
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
}

/*------DIV BOTTOM LINE
height: 3px;
width: 100%;
background: black;
bottom: 0;
position: absolute;
left: 0;
border-radius: 99px;
transform: scaleX(01);
transition: transform 180ms;
*/

@keyframes _view_tab-flip__1TIZv {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

/**INPUT*/
._type_def__25kjC,
._type_dense__3WgGM/*,
.fixed*/ {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
._type_full__7dzxb {
  width: 100%;
}
._type_def__25kjC[class*="input-group"] input[class*="input"] {
  border-radius: 0.25rem;
  padding-left: 0.75rem;
  padding-right: 0.625rem !important;
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
}
._type_def__25kjC[class*="input-group"] input[class*="input"] {
  height: 2.5rem;
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body);
  font-weight: var(--font_weight_body);
}

._type_def__25kjC label {
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  font-weight: var(--font_weight_body_small);
  transition: all 200ms ease-in-out;
  z-index: 1;
  pointer-events: none;
}
/*Dense*/
._type_dense__3WgGM[class*="input-group"] input[class*="input"] {
  border-radius: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.375rem !important;
  margin-top: 0.0625rem;
  margin-bottom: 0.0625rem;
}
._type_dense__3WgGM[class*="input-group"] input[class*="input"] {
  height: 1.75rem;
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  font-weight: var(--font_weight_body);
}
._type_dense__3WgGM label {
  font-size: var(--font_size_caption);
  letter-spacing: var(--letter_spacing_caption);
  font-weight: var(--font_weight_caption);
  transition: all 200ms ease-in-out;
  z-index: 1;
  pointer-events: none;
}

/*CLEAR BUTTON*/
._type_def__25kjC._type_clearButton__13iUh[class*="input-group"] span[class*="input-action"] > button {
  height: 2.5rem;
  padding: 0;
  margin: 0;
  margin-right: 0.5rem;
  margin-top: 0.125rem;
}
._type_dense__3WgGM._type_clearButton__13iUh[class*="input-group"] span[class*="input-action"] > button {
  height: 1.75rem;
  padding: 0;
  margin: 0;
  margin-right: 0.125rem;
  margin-top: 0.0625rem;
}
._type_dense__3WgGM._type_clearButton__13iUh[class*="input-group"]
  span[class*="input-action"]
  > button
  svg {
  width: 1rem;
  height: 1rem;
}
._type_clearButton__13iUh[class*="input-group"] span[class*="input-action"] {
  top: auto;
  top: initial;
}
/*RIGHT ICON*/
._type_def__25kjC._type_right-icon__hqb3q[class*="input-group"] span[class*="input-action"] {
  padding: 0;
  margin: 0;
  margin-right: 0.625rem;
  margin-top: 0.75rem;
}
._type_dense__3WgGM._type_right-icon__hqb3q[class*="input-group"] span[class*="input-action"] {
  padding: 0;
  margin: 0;
  margin-right: 0.375rem;
  bottom: 0.4375rem;
}
._type_right-icon__hqb3q[class*="input-group"] span[class*="input-action"] {
  top: auto;
  top: initial;
}

/*-----*/
/*HELPER TEXT*/
._type_def__25kjC ._type_error-helper-text__1rcCY {
  position: absolute;
  bottom: -1rem;
  z-index: 1;
  /*padding-left: 0.75rem;*/
  font-size: var(--font_size_caption);
  letter-spacing: var(--letter_spacing_caption);
  font-weight: var(--font_weight_caption);
  animation: _type_shake-horizontal__3xNBl 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}
._type_dense__3WgGM ._type_error-helper-text__1rcCY {
  position: absolute;
  bottom: -0.875rem;
  z-index: 1;
  /*padding-left: 0.75rem;*/
  font-size: var(--font_size_caption_small);
  letter-spacing: var(--letter_spacing_caption_small);
  font-weight: var(--font_weight_caption_small);
  animation: _type_shake-horizontal__3xNBl 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}

/*ANIMATION*/
@keyframes _type_shake-horizontal__3xNBl {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    transform: translateX(10px);
  }
  80% {
    transform: translateX(8px);
  }
  90% {
    transform: translateX(-8px);
  }
}

/*INPUT*/

/*SMOOTH*/
._view_smooth__azf1C[class*="input-group"] input[class*="input"],
._view_smooth__azf1C._view_active__2-hK1._view_isFilled__z2FAt[class*="input-group"] input[class*="input"] {
  box-shadow: none;
  background: var(--color5);
  color: var(--color100);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_smooth__azf1C[class*="input-group"]._view_active__2-hK1 input[class*="input"],
._view_smooth__azf1C[class*="input-group"] input[class*="input"]:hover {
  color: var(--color100);
  background: var(--color10);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_smooth__azf1C[class*="input-group"] input[class*="input"]::-webkit-input-placeholder {
  color: var(--color40);
  opacity: 1;
  visibility: visible;
  display: inline;
  display: initial;
}
._view_smooth__azf1C[class*="input-group"] input[class*="input"]:-ms-input-placeholder {
  color: var(--color40);
  opacity: 1;
  visibility: visible;
  display: inline;
  display: initial;
}
._view_smooth__azf1C[class*="input-group"] input[class*="input"]::placeholder {
  color: var(--color40);
  opacity: 1;
  visibility: visible;
  display: inline;
  display: initial;
}
._view_smooth__azf1C[class*="input-group"] label {
  opacity: 0;
  color: var(--color50);
}
._view_smooth__azf1C[class*="input-group"]._view_active__2-hK1._view_isFilled__z2FAt label,
._view_smooth__azf1C[class*="input-group"]._view_active__2-hK1 label {
  color: var(--color50);
  opacity: 1;
}
._view_smooth__azf1C[class*="input-group"] input[class*="input"]:focus {
  box-shadow: none;
}
/**END SMOOTH*/

/*OUTLINED*/
._view_outlined__kWWPR[class*="input-group"] input[class*="input"],
._view_outlined__kWWPR._view_active__2-hK1[class*="input-group"] input[class*="input"],
._view_outlined__kWWPR._view_active__2-hK1._view_isFilled__z2FAt[class*="input-group"] input[class*="input"] {
  box-shadow: 0 0 0 1px var(--color30);
  background: var(--white);
  color: var(--color100);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_outlined__kWWPR[class*="input-group"] input[class*="input"]:hover,
._view_outlined__kWWPR[class*="input-group"]._view_isFilled__z2FAt input[class*="input"]:hover {
  color: var(--color100);
  box-shadow: 0 0 0 1px var(--color80);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_outlined__kWWPR[class*="input-group"]._view_active__2-hK1 input[class*="input"] {
  box-shadow: 0 0 0 2px var(--color80);
}
._view_outlined__kWWPR[class*="input-group"] input[class*="input"]::-webkit-input-placeholder {
  color: var(--color40);
  opacity: 1;
  visibility: visible;
  display: inline;
  display: initial;
}
._view_outlined__kWWPR[class*="input-group"] input[class*="input"]:-ms-input-placeholder {
  color: var(--color40);
  opacity: 1;
  visibility: visible;
  display: inline;
  display: initial;
}
._view_outlined__kWWPR[class*="input-group"] input[class*="input"]::placeholder {
  color: var(--color40);
  opacity: 1;
  visibility: visible;
  display: inline;
  display: initial;
}
._view_outlined__kWWPR[class*="input-group"] label {
  opacity: 0;
  color: var(--color50);
}
._view_outlined__kWWPR[class*="input-group"]._view_active__2-hK1._view_isFilled__z2FAt label,
._view_outlined__kWWPR[class*="input-group"]._view_active__2-hK1 label {
  color: var(--color50);
  opacity: 1;
}
/**END OUTLINED*/

/*RAISED*/
._view_raised__2HUx1[class*="input-group"] input[class*="input"],
._view_raised__2HUx1._view_active__2-hK1[class*="input-group"] input[class*="input"],
._view_raised__2HUx1._view_active__2-hK1._view_success__1mXvl[class*="input-group"] input[class*="input"],
._view_raised__2HUx1._view_active__2-hK1._view_error-helper-text__2FjG-[class*="input-group"] input[class*="input"],
._view_raised__2HUx1._view_active__2-hK1._view_isFilled__z2FAt[class*="input-group"] input[class*="input"] {
  box-shadow: var(--shadow2dp);
  background: var(--white);
  color: var(--color100);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_raised__2HUx1[class*="input-group"] input[class*="input"]:hover,

._view_raised__2HUx1[class*="input-group"]._view_isFilled__z2FAt input[class*="input"]:hover {
  color: var(--color100);
  box-shadow: var(--shadow4dp);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_raised__2HUx1[class*="input-group"]._view_active__2-hK1 input[class*="input"] {
  box-shadow: var(--shadow8dp);
}
._view_raised__2HUx1[class*="input-group"] input[class*="input"]::-webkit-input-placeholder {
  color: var(--color40);
  opacity: 1;
  visibility: visible;
  display: inline;
  display: initial;
}
._view_raised__2HUx1[class*="input-group"] input[class*="input"]:-ms-input-placeholder {
  color: var(--color40);
  opacity: 1;
  visibility: visible;
  display: inline;
  display: initial;
}
._view_raised__2HUx1[class*="input-group"] input[class*="input"]::placeholder {
  color: var(--color40);
  opacity: 1;
  visibility: visible;
  display: inline;
  display: initial;
}
._view_raised__2HUx1[class*="input-group"] input[class*="input"]:hover::-webkit-input-placeholder {
  color: var(--color60);
}
._view_raised__2HUx1[class*="input-group"] input[class*="input"]:hover:-ms-input-placeholder {
  color: var(--color60);
}
._view_raised__2HUx1[class*="input-group"] input[class*="input"]:hover::placeholder {
  color: var(--color60);
}
._view_raised__2HUx1[class*="input-group"] label {
  color: var(--color50);
  opacity: 0;
}
._view_raised__2HUx1[class*="input-group"]._view_active__2-hK1._view_isFilled__z2FAt label,
._view_raised__2HUx1[class*="input-group"]._view_active__2-hK1 label {
  color: var(--color50);
  opacity: 1;
}
/**END RAISED*/

/*FILLED*/
._view_filled__3tsUx[class*="input-group"] input[class*="input"],
._view_filled__3tsUx._view_active__2-hK1[class*="input-group"] input[class*="input"],
._view_filled__3tsUx._view_active__2-hK1._view_isFilled__z2FAt[class*="input-group"] input[class*="input"] {
  box-shadow: none;
  background: var(--color30);
  color: var(--color0);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_filled__3tsUx[class*="input-group"] input[class*="input"]:hover,
._view_filled__3tsUx[class*="input-group"]._view_isFilled__z2FAt input[class*="input"]:hover {
  color: var(--color0);
  box-shadow: none;
  background: var(--color40);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_filled__3tsUx[class*="input-group"]._view_active__2-hK1 input[class*="input"] {
  box-shadow: none;
  background: var(--color70);
}
._view_filled__3tsUx[class*="input-group"] input[class*="input"]::-webkit-input-placeholder {
  color: var(--color0);
  opacity: 1;
  visibility: visible;
  display: inline;
  display: initial;
}
._view_filled__3tsUx[class*="input-group"] input[class*="input"]:-ms-input-placeholder {
  color: var(--color0);
  opacity: 1;
  visibility: visible;
  display: inline;
  display: initial;
}
._view_filled__3tsUx[class*="input-group"] input[class*="input"]::placeholder {
  color: var(--color0);
  opacity: 1;
  visibility: visible;
  display: inline;
  display: initial;
}
._view_filled__3tsUx[class*="input-group"] label {
  opacity: 0;
}
._view_filled__3tsUx[class*="input-group"]._view_active__2-hK1._view_isFilled__z2FAt label,
._view_filled__3tsUx[class*="input-group"]._view_active__2-hK1 label {
  color: var(--color50);
  opacity: 1;
}
/**END FILLED*/

/*HELPER TEXT*/
._view_error-helper-text__2FjG- {
  color: var(--red60);
}
/*end*/

/*RIGHT ICON*/
._view_error-helper-text__2FjG- span[class*="input-action"] {
  color: var(--color60);
}
._view_filled__3tsUx._view_error-helper-text__2FjG- span[class*="input-action"] {
  color: var(--color0);
}
._view_success__1mXvl span[class*="input-action"] {
  color: var(--color60);
}
._view_filled__3tsUx._view_success__1mXvl span[class*="input-action"] {
  color: var(--color0);
}
/*-----*/
/*CLEAR BUTTON*/
._view_clearButton__3Tsb4[class*="input-group"]
  > input
  + span[class*="input-action"]
  > button {
  visibility: hidden;
  opacity: 0;
  cursor: pointer;
}
._view_clearButton__3Tsb4[class*="input-group"]._view_isFilled__z2FAt
  span[class*="input-action"]
  > button,
._view_clearButton__3Tsb4[class*="input-group"]._view_active__2-hK1 span[class*="input-action"] > button {
  visibility: hidden;
  opacity: 0;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_clearButton__3Tsb4[class*="input-group"]._view_active__2-hK1
  span[class*="input-action"]
  > button[class*="button"],
._view_clearButton__3Tsb4[class*="input-group"]._view_active__2-hK1
  span[class*="input-action"]
  > button[class*="button"]
  > span[class*="icon"] {
  color: var(--color40) !important;
  opacity: 1;
  visibility: visible;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_filled__3tsUx._view_clearButton__3Tsb4[class*="input-group"]:hover
  span[class*="input-action"]
  > button[class*="button"]
  > span[class*="icon"] {
  color: var(--color5) !important;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_clearButton__3Tsb4[class*="input-group"]._view_active__2-hK1._view_isFilled__z2FAt
  span[class*="input-action"]
  > button {
  visibility: hidden;
  opacity: 0;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_clearButton__3Tsb4[class*="input-group"]._view_active__2-hK1._view_isFilled__z2FAt:hover
  span[class*="input-action"]
  > button {
  visibility: visible;
  opacity: 1;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
}

/*END CLEAR BUTTON*/

/**INPUT*/
._type_def__295yN._type_fill__s3Ar9,
._type_dense__3hqbk._type_fill__s3Ar9 {
  width: 100%;
}
._type_def__295yN,
._type_dense__3hqbk {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
._type_def__295yN[class*="input-group"] > input[class*="input"] {
  height: 2.5rem;
  border-radius: 0.25rem;
  padding: 0 2.5rem !important;
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body);
  font-weight: var(--font_weight_body);
}
._type_dense__3hqbk[class*="input-group"] > input[class*="input"] {
  height: 1.75rem;
  border-radius: 0.25rem;
  padding: 0 1.75rem !important;
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  font-weight: var(--font_weight_body_small);
}

._type_def__295yN[class*="input-group"] > button[class*="button"],
._type_dense__3hqbk[class*="input-group"] > button[class*="button"],
._type_def__295yN[class*="input-group"]
  span[class*="input-action"]
  > button[class*="button"],
._type_dense__3hqbk[class*="input-group"]
  span[class*="input-action"]
  > button[class*="button"] {
  z-index: 1;
  margin: 0;
  padding: 0;
  width: 1.25rem;
  height: 100%;
  min-width: 1.25rem;
  min-height: 0;
  margin-left: 0.75rem;
}

._type_def__295yN[class*="input-group"] span[class*="input-action"],
._type_dense__3hqbk[class*="input-group"] span[class*="input-action"] {
  height: 100%;
}
._type_def__295yN[class*="input-group"]
  span[class*="input-action"]
  > button[class*="button"] {
  margin-right: 0.625rem;
}
._type_dense__3hqbk[class*="input-group"] > button[class*="button"] {
  width: 1rem;
  min-width: 1rem;
  margin-left: 0.5rem;
}
._type_dense__3hqbk[class*="input-group"]
  span[class*="input-action"]
  > button[class*="button"] {
  margin-right: 0.375rem;
}

._type_def__295yN[class*="active"] > input[class*="input"],
._type_dense__3hqbk[class*="active"] > input[class*="input"] {
  font-weight: var(--font_weight_body_medium);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

/*INPUT*/
/*SMOOTH*/
._view_smooth__aYfxu[class*="input-group"] input[class*="input"] {
  box-shadow: none;
  background: var(--color5);
  color: var(--color100);
  border: none;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_smooth__aYfxu[class*="input-group"] input[class*="input"]:hover {
  box-shadow: none;
  background: var(--color10);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_smooth__aYfxu[class*="input-group"]._view_active__3fZAJ input[class*="input"] {
  box-shadow: none;
  background: var(--color10);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_smooth__aYfxu[class*="input-group"] input[class*="input"]::-webkit-input-placeholder {
  color: var(--color40);
}
._view_smooth__aYfxu[class*="input-group"] input[class*="input"]:-ms-input-placeholder {
  color: var(--color40);
}
._view_smooth__aYfxu[class*="input-group"] input[class*="input"]::placeholder {
  color: var(--color40);
}
._view_smooth__aYfxu[class*="input-group"]:hover input[class*="input"]::-webkit-input-placeholder {
  color: var(--color60);
}
._view_smooth__aYfxu[class*="input-group"]:hover input[class*="input"]:-ms-input-placeholder {
  color: var(--color60);
}
._view_smooth__aYfxu[class*="input-group"]:hover input[class*="input"]::placeholder {
  color: var(--color60);
}
/**END SMOOTH*/

/*FILLED*/
._view_filled__vK-5x[class*="input-group"] input[class*="input"] {
  box-shadow: none;
  background: var(--color30);
  color: var(--color0);
  border: none;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_filled__vK-5x[class*="input-group"] input[class*="input"]:hover {
  box-shadow: none;
  background: var(--color40);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_filled__vK-5x[class*="input-group"]._view_active__3fZAJ input[class*="input"] {
  box-shadow: none;
  background: var(--color70);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_filled__vK-5x[class*="input-group"] input[class*="input"]::-webkit-input-placeholder {
  color: var(--color0);
}
._view_filled__vK-5x[class*="input-group"] input[class*="input"]:-ms-input-placeholder {
  color: var(--color0);
}
._view_filled__vK-5x[class*="input-group"] input[class*="input"]::placeholder {
  color: var(--color0);
}

/**END FILLED*/

/*OUTLINED*/
._view_outlined__25BEg[class*="input-group"] input[class*="input"] {
  box-shadow: 0 0 0 0.0625rem var(--color30);
  color: var(--color100);
  border: none;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_outlined__25BEg[class*="input-group"] input[class*="input"]:hover {
  box-shadow: 0 0 0 0.0625rem var(--color80);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_outlined__25BEg[class*="input-group"]._view_active__3fZAJ input[class*="input"] {
  box-shadow: 0 0 0 0.125rem var(--color80);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_outlined__25BEg[class*="input-group"] input[class*="input"]::-webkit-input-placeholder {
  color: var(--color40);
}
._view_outlined__25BEg[class*="input-group"] input[class*="input"]:-ms-input-placeholder {
  color: var(--color40);
}
._view_outlined__25BEg[class*="input-group"] input[class*="input"]::placeholder {
  color: var(--color40);
}
/**END OUTLINED*/

/*RAISED*/
._view_raised__2Cg2u[class*="input-group"] input[class*="input"] {
  box-shadow: var(--shadow2dp);
  color: var(--color100);
  border: none;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_raised__2Cg2u[class*="input-group"] input[class*="input"]:hover {
  box-shadow: var(--shadow4dp);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_raised__2Cg2u[class*="input-group"]._view_active__3fZAJ input[class*="input"] {
  box-shadow: var(--shadow8dp);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_raised__2Cg2u[class*="input-group"] input[class*="input"]::-webkit-input-placeholder {
  color: var(--color40);
}
._view_raised__2Cg2u[class*="input-group"] input[class*="input"]:-ms-input-placeholder {
  color: var(--color40);
}
._view_raised__2Cg2u[class*="input-group"] input[class*="input"]::placeholder {
  color: var(--color40);
}
._view_raised__2Cg2u[class*="input-group"]:hover input[class*="input"]::-webkit-input-placeholder {
  color: var(--color60);
}
._view_raised__2Cg2u[class*="input-group"]:hover input[class*="input"]:-ms-input-placeholder {
  color: var(--color60);
}
._view_raised__2Cg2u[class*="input-group"]:hover input[class*="input"]::placeholder {
  color: var(--color60);
}
/**END RAISED*/

/*SEARCH BUTTON*/
div[class*="input-group"] > ._view_searchButton__1D5dW {
  color: var(--color30);
}
div[class*="input-group"] > ._view_searchButton__1D5dW:hover {
  color: var(--color50);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_smooth__aYfxu[class*="input-group"]._view_active__3fZAJ > ._view_searchButton__1D5dW {
  color: var(--color60);
}
._view_filled__vK-5x[class*="input-group"] > ._view_searchButton__1D5dW,
._view_filled__vK-5x[class*="input-group"]._view_active__3fZAJ > ._view_searchButton__1D5dW:hover {
  color: var(--color20);
}
._view_filled__vK-5x[class*="input-group"]._view_active__3fZAJ > ._view_searchButton__1D5dW,
._view_filled__vK-5x[class*="input-group"] > ._view_searchButton__1D5dW:hover {
  color: var(--color0);
}

/*-----*/
/*CLEAR BUTTON*/

div[class*="input-group"]
  > input[class*="input"]
  + span[class*="input-action"]
  > button[class*="minimal"]._view_clearButton__37ZAh:not(:hover):not(:focus)
  > span[class*="icon"]
  > svg {
  fill: var(--color30) !important;
}
div[class*="input-group"] span[class*="input-action"] > button {
  color: var(--color30) !important;
}
._view_smooth__aYfxu[class*="input-group"]
  > input[class*="input"]
  + span[class*="input-action"]
  > button[class*="minimal"]._view_clearButton__37ZAh:not(:hover):not(:focus)
  > span[class*="icon"]
  > svg,
._view_raised__2Cg2u[class*="input-group"]
  > input[class*="input"]
  + span[class*="input-action"]
  > button[class*="minimal"]._view_clearButton__37ZAh:not(:hover):not(:focus)
  > span[class*="icon"]
  > svg {
  fill: var(--color40) !important;
}
._view_smooth__aYfxu[class*="input-group"] span[class*="input-action"] > button,
._view_raised__2Cg2u[class*="input-group"] span[class*="input-action"] > button {
  color: var(--color40) !important;
}
._view_filled__vK-5x[class*="input-group"] span[class*="input-action"] > button {
  color: var(--color50) !important;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_filled__vK-5x[class*="input-group"]._view_active__3fZAJ span[class*="input-action"] > button {
  color: var(--color40) !important;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_filled__vK-5x[class*="input-group"]
  > input[class*="input"]
  + span[class*="input-action"]
  > button[class*="minimal"]._view_clearButton__37ZAh:not(:hover):not(:focus)
  > span[class*="icon"]
  > svg {
  fill: var(--color40) !important;
}

div[class*="input-group"]._view_isFilled__2Ehsg
  span[class*="input-action"]
  > button._view_clearButton__37ZAh {
  visibility: hidden;
  opacity: 0;
}
._view_div__iPCEM[class*="input-group"]._view_isFilled__2Ehsg span[class*="input-action"] > button {
  visibility: hidden;
  opacity: 0;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_div__iPCEM[class*="input-group"]
  > input[class*="input"]
  + span[class*="input-action"]
  > button[class*="minimal"]._view_clearButton__37ZAh:not(:hover):not(:focus)
  > span[class*="icon"] {
  opacity: 1;
  visibility: visible;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

div[class*="input-group"] span[class*="input-action"] > button {
  visibility: hidden;
}
div[class*="input-group"] span[class*="input-action"] > ._view_clearButton__37ZAh {
  visibility: visible;
}
div[class*="input-group"]._view_isFilled__2Ehsg span[class*="input-action"] > ._view_clearButton__37ZAh {
  visibility: hidden;
  opacity: 0;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
div[class*="input-group"]._view_isFilled__2Ehsg:hover
  span[class*="input-action"]
  > ._view_clearButton__37ZAh {
  visibility: visible;
  opacity: 1;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
/*-----*/

/*.active,
.active svg {
  color: var(--base-color, var(--prim-color)) !important;
}*/
._view_default__151_T._view_checked__2DKib svg {
  color: var(--prim-color) !important;
  color: var(--base-color, var(--prim-color)) !important;
}
._view_default__151_T._view_checked__2DKib svg {
  color: var(--prim-color) !important;
  color: var(--base-color, var(--prim-color)) !important;
}

._view_default__151_T span,
._view_default__151_T svg {
  color: var(--grey40) !important;
}
._view_default__151_T span:hover svg {
  color: var(--prim-color) !important;
  color: var(--base-color, var(--prim-color)) !important;
}

.paper_def__3xupZ {
  border-radius: 4px !important;
  /* height: 100% !important;*/
  display: inline-block !important;
}

._view_flat__nP_Pu {
  box-shadow: none !important;
  background: var(--white) !important;
}

._view_raised__1DnyJ {
  box-shadow: var(--raised-prim-shadow-active)
   !important;
  box-shadow: var(
    --raised-shadow-active,
    var(--raised-prim-shadow-active)
  ) !important;
  background: var(--white) !important;
}

._view_smooth__1rSo8 {
  /* background: var(--smooth-bg-active, var(--smooth-prim-bg-active)) !important;
  opacity: 0.48 !important;*/
  box-shadow: none !important;
  background-color: var(--white) !important;
}

._view_filled__1snF5 {
  /* background: var(--filled-bg-active, var(--filled-prim-bg-active)) !important;*/
  /* opacity: 0.72 !important;*/
  box-shadow: none !important;
  background-color: var(--white) !important;
  /*border-radius: 4px !important;*/
}

._view_ghost__ziJ0h {
  box-shadow: none !important;
  background: var(--white) !important;
  border: 1px solid var(--ghost-prim-border) !important;
  border: 1px solid var(--ghost-border, var(--ghost-prim-border)) !important;
}

.table_def__2UV56 tr {
  height: 40px !important;
}
.table_def__2UV56 th,
.table_dense__2Nv2U th {
  padding: 4px 8px 4px 8px !important;
  /*padding-top: 9px !important;*/
  font-family: var(--font-primary) !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  letter-spacing: 0.8px !important;
  /*opacity: 0.64 !important;*/
  border-bottom: 2px solid !important;
}
.table_def__2UV56 td,
.table_dense__2Nv2U td {
  padding: 4px 8px 4px 8px !important;
  font-family: var(--font-primary) !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: 0.44px !important;
}

.table_dense__2Nv2U tr {
  height: 24px !important;
}
.table_dense__2Nv2U th {
  text-transform: uppercase !important;
  font-size: 10px !important;
  line-height: 16px !important;
  letter-spacing: 1.5px !important;
  border-bottom: 1px solid !important;
}
.table_dense__2Nv2U td {
  height: 20px;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: 0.4px !important;
  padding: 2px 8px 2px 8px !important;
}
.table_dense__2Nv2U svg {
  height: 20px;
  width: 20px;
}

/*PAGINATION*/
.table_def-pag__HsTZ_ div,
.table_def-pag__HsTZ_,
.table_def-pag__HsTZ_ span {
  height: 40px !important;
  min-height: 0px !important;
  font-family: var(--font-primary) !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 40px !important;
  letter-spacing: 0.25px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  /*top: 9px !important;*/
  overflow: visible !important;
  overflow: initial !important;
}

.table_def-pag__HsTZ_ button,
.table_def-pag__HsTZ_ button span {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  width: 28px !important;
  height: 28px !important;
}
.table_def-pag__HsTZ_ button {
  margin-right: 8px !important;
}
.table_def-pag__HsTZ_ svg {
  top: 7px !important;
}
.table_def-pag__HsTZ_ div > div > div {
  padding-right: 24px !important;
  min-width: 0px !important;
}
.table_def-pag__HsTZ_ div div:nth-of-type(3) {
  margin-left: 16px !important;
}
.table_def-pag__HsTZ_ div > div {
  margin-right: 0px !important;
  margin-left: 0px !important;
}
.table_def-pag__HsTZ_ div:nth-child(1) {
  float: right;
}

/*DENSE PAGINATION*/
.table_dense-pag__157pJ div,
.table_dense-pag__157pJ,
.table_dense-pag__157pJ span {
  height: 24px !important;
  min-height: 0px !important;
  font-family: var(--font-primary) !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  letter-spacing: 0.4px !important;
  overflow: visible !important;
  overflow: initial !important;
}
.table_dense-pag__157pJ p {
  font-size: 12px !important;
}
.table_dense-pag__157pJ div > span {
  vertical-align: middle;
  display: inline-block;
  line-height: 24px;
}
.table_dense-pag__157pJ button,
.table_dense-pag__157pJ button span {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  width: 24px !important;
  height: 24px !important;
}
.table_dense-pag__157pJ button {
  margin-right: 8px !important;
}
.table_dense-pag__157pJ svg {
  top: 0px !important;
}
.table_dense-pag__157pJ div > div > div {
  padding-right: 24px !important;
  padding-left: 8px !important;
  min-width: 0px !important;
}
.table_dense-pag__157pJ div div:nth-of-type(3) {
  margin-left: 16px !important;
}
.table_dense-pag__157pJ div > div {
  margin-right: 0px !important;
  margin-left: 0px !important;
  padding: 0 0 0 0 !important;
  vertical-align: middle;
  display: inline-block;
  line-height: 24px;
}
.table_dense-pag__157pJ div:nth-child(1) {
  float: right;
  width: auto;
}

/*TRASh BUTTON*/
.table_def-trash__2qqpW {
  font-family: var(--font-primary) !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  /*line-height: 24px !important;*/
  letter-spacing: 0.44px !important;
  display: flex;
}
.table_def-trash__2qqpW button + span {
  padding-left: 8px;
}
.table_def-trash-btn__1ejUv {
  padding: 8px !important;
  width: 40px !important;
  height: 40px !important;
}

.table_dense-trash__3mr90 {
  font-family: var(--font-primary) !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  /*line-height: 24px !important;*/
  letter-spacing: 0.44px !important;
  display: flex;
}
.table_dense-trash__3mr90 button + span {
  padding-left: 8px;
}
.table_dense-trash-btn__1btmV {
  vertical-align: baseline;
  vertical-align: initial;
  line-height: 22px;
  margin-left: 2px;
  min-height: 0;
  padding: 0;
}

div[role="document"] {
  margin-top: 0 !important;
}
ul[role="listbox"] {
  padding: 0;
}

.table_info-text__30s6j {
  margin-bottom: auto;
  margin-top: auto;
}

._view_checkbox__2BKlK {
  padding: 0 !important;
  background-color: transparent !important;
  color: unset !important;
}

li[role="option"] {
  background: transparent !important;
}
li[role="option"]:hover {
  background: linear-gradient(
    90deg,
    var(--gradient-prim-reverse)) !important;
  background: linear-gradient(
    90deg,
    var(--gradGreyRev, var(--gradient-prim-reverse))
  ) !important;
  color: var(--white) !important;
}
/*checkbox*/
._view_flat__2mJdA tr[aria-checked="false"]:hover svg,
._view_flat-head-unchecked__3RsNk,
._view_raised__24wXy tr[aria-checked="false"]:hover svg,
._view_raised-head-unchecked__DACa5,
._view_smooth__c2vnD tr[aria-checked="false"]:hover svg,
._view_smooth-head-unchecked__LzcWJ {
  color: var(--flat-def-color-table) !important;
  color: var(--flat-color-table, var(--flat-def-color-table)) !important;
}
._view_flat-head-checked__1YEgm svg,
._view_smooth-head-checked__1krFE svg,
._view_raised-head-checked__FwA4S svg {
  color: var(--color100) !important;
}
._view_flat-body-checked__1iw21 svg,
._view_smooth-body-checked__27SB5 svg,
._view_raised-body-checked__2k-SC svg {
  color: var(--flat-def-color-checkbox) !important;
  color: var(--flat-color-checkbox, var(--flat-def-color-checkbox)) !important;
}
._view_flat-body-unchecked__172WF svg,
._view_flat-body-unchecked__172WF span,
._view_flat-body-checked__1iw21 span,
._view_smooth-body-unchecked__rfBrN svg,
._view_smooth-body-unchecked__rfBrN span,
._view_smooth-body-checked__27SB5 span,
._view_raised-body-unchecked__cT2Df svg,
._view_raised-body-unchecked__cT2Df span,
._view_raised-body-checked__2k-SC span {
  color: var(--grey30) !important;
}
._view_filled__3jo5F tr[aria-checked="false"]:hover svg,
._view_filled-head-unchecked__2uLb5,
._view_filled-head-checked__144xN svg,
._view_filled-body-checked__13Bh6 svg,
._view_filled-body-checked__13Bh6 span {
  color: var(--white) !important;
}
._view_filled-body-unchecked__2vEl_ svg,
._view_filled-body-unchecked__2vEl_ span {
  color: var(--filled-def-color-checkbox)
   !important;
  color: var(
    --filled-color-checkbox,
    var(--filled-def-color-checkbox)
  ) !important;
}

/*FLAT*/
._view_flat__2mJdA th {
  color: var(--flat-def-color-table) !important;
  color: var(--flat-color-table, var(--flat-def-color-table)) !important;
}
._view_flat__2mJdA thead th {
  border-bottom-color: var(--flat-def-border2-table)
   !important;
  border-bottom-color: var(
    --flat-border2-table,
    var(--flat-def-border2-table)
  ) !important;
}
._view_flat__2mJdA td {
  border-bottom: 1px solid
    var(--flat-def-border-table) !important;
  border-bottom: 1px solid
    var(--flat-border-table, var(--flat-def-border-table)) !important;
  color: var(--grey100) !important;
}
._view_flat__2mJdA tbody > tr:hover {
  background-color: var(--grey0) !important;
}
._view_flat__2mJdA tbody > tr:last-of-type > td {
  border-bottom: 1px solid
    var(--flat-def-borderlast-table) !important;
  border-bottom: 1px solid
    var(--flat-borderlast-table, var(--flat-def-borderlast-table)) !important;
}
._view_dense__3VgIH._view_flat__2mJdA td {
  border-bottom: 1px solid
    var(--flat-def-bg-table-hover);
  border-bottom: 1px solid
    var(--flat-bg-table-hover, var(--flat-def-bg-table-hover));
  color: var(--grey100) !important;
}

._view_flat__2mJdA tr[aria-checked="true"] td,
._view_flat__2mJdA tr[aria-checked="true"]:hover td {
  background: var(--flat-def-bg-table-hover)
   !important;
  background: var(
    --flat-bg-table-hover,
    var(--flat-def-bg-table-hover)
  ) !important;
  border-bottom: 1px solid
    var(--flat-def-borderlast-table) !important;
  border-bottom: 1px solid
    var(--flat-borderlast-table, var(--flat-def-borderlast-table)) !important;
}

/*FILLED*/
._view_filled__3jo5F thead {
  background-color: var(--filled-def-bg-head-table)
   !important;
  background-color: var(
    --filled-bg-head-table,
    var(--filled-def-bg-head-table)
  ) !important;
  border-radius: 4px !important;
}
._view_filled__3jo5F thead th:last-of-type {
  border-radius: 0px 4px 0px 0px !important;
}
._view_filled__3jo5F thead th:first-of-type {
  border-radius: 4px 0px 0px 0px !important;
}
._view_filled__3jo5F th {
  color: var(--white) !important;
}
._view_filled__3jo5F thead span:hover {
  color: var(--white) !important;
}
._view_filled__3jo5F td {
  color: var(--white) !important;
}
._view_filled__3jo5F tr > td {
  border-bottom: 1px solid
    var(--filled-def-border-table) !important;
  border-bottom: 1px solid
    var(--filled-border-table, var(--filled-def-border-table)) !important;
  /*color: var(--white) !important;*/
}
._view_filled__3jo5F tr:last-of-type > td {
  border-bottom: 1px solid
    var(--filled-def-border-table) !important;
  border-bottom: 1px solid
    var(--filled-border-table, var(--filled-def-border-table)) !important;
  /*color: var(--white) !important;*/
}
._view_filled__3jo5F tbody > tr:hover td {
  border-bottom: 1px solid
    var(--filled-def-border-table) !important;
  border-bottom: 1px solid
    var(--filled-border-table, var(--filled-def-border-table)) !important;
  /*border-top: 1px solid
    var(--filled-border-table, var(--filled-def-border-table)) !important;*/
}
._view_filled__3jo5F thead th {
  border-bottom-color: var(--filled-def-border2-table)
   !important;
  border-bottom-color: var(
    --filled-border2-table,
    var(--filled-def-border2-table)
  ) !important;
}
._view_filled__3jo5F tbody > tr:hover {
  background-color: var(--filled-def-bg-table-hover)
   !important;
  background-color: var(
    --filled-bg-table-hover,
    var(--filled-def-bg-table-hover)
  ) !important;
}
._view_filled__3jo5F tbody > tr {
  background-color: var(--filled-def-bg-table)
   !important;
  background-color: var(
    --filled-bg-table,
    var(--filled-def-bg-table)
  ) !important;
}
._view_filled__3jo5F tr[aria-checked="true"] td,
._view_filled__3jo5F tr[aria-checked="true"]:hover td {
  background-color: var(--filled-def-bg-table-hover)
   !important;
  background-color: var(
    --filled-bg-table-hover,
    var(--filled-def-bg-table-hover)
  ) !important;
  border-bottom: 1px solid
    var(--filled-def-border-table) !important;
  border-bottom: 1px solid
    var(--filled-border-table, var(--filled-def-border-table)) !important;
}

._view_filled__3jo5F tbody > tr:last-of-type > td,
._view_smooth__c2vnD tbody > tr:last-of-type > td,
._view_raised__24wXy tbody > tr:last-of-type > td {
  /* border: none !important;*/
}
._view_filled__3jo5F tbody > tr:last-of-type > td:last-of-type,
._view_smooth__c2vnD tbody > tr:last-of-type > td:last-of-type,
._view_raised__24wXy tbody > tr:last-of-type > td:last-of-type {
  border-radius: 0 0 4px 0 !important;
}
._view_filled__3jo5F tbody > tr:last-of-type > td:first-of-type,
._view_smooth__c2vnD tbody > tr:last-of-type > td:first-of-type,
._view_raised__24wXy tbody > tr:last-of-type > td:first-of-type {
  border-radius: 0 0 0 4px !important;
}

._view_pagination__2N7Bg tbody > tr:last-of-type > td:last-of-type {
  border-radius: 0 !important;
}
._view_pagination__2N7Bg tbody > tr:last-of-type > td:first-of-type {
  border-radius: 0 !important;
}
/*SMOOTH*/
._view_smooth__c2vnD thead {
  background-color: var(--smooth-def-bg-head-table)
   !important;
  background-color: var(
    --smooth-bg-head-table,
    var(--smooth-def-bg-head-table)
  ) !important;
  border-radius: 4px !important;
}
._view_smooth__c2vnD thead th:last-of-type {
  border-radius: 0px 4px 0px 0px !important;
}
._view_smooth__c2vnD thead th:first-of-type {
  border-radius: 4px 0px 0px 0px !important;
}
._view_smooth__c2vnD thead th {
  border-bottom-color: var(--smooth-def-border2-table)
   !important;
  border-bottom-color: var(
    --smooth-border2-table,
    var(--smooth-def-border2-table)
  ) !important;
  color: var(--smooth-def-color-table) !important;
  color: var(--smooth-color-table, var(--smooth-def-color-table)) !important;
}
._view_smooth__c2vnD td {
  border-bottom: 1px solid
    var(--smooth-def-border-table) !important;
  border-bottom: 1px solid
    var(--smooth-border-table, var(--smooth-def-border-table)) !important;
  color: var(--grey100) !important;
}
._view_smooth__c2vnD tbody > tr {
  background-color: var(--smooth-def-bg-table)
   !important;
  background-color: var(
    --smooth-bg-table,
    var(--smooth-def-bg-table)
  ) !important;
}
._view_smooth__c2vnD tbody > tr:hover td {
  background-color: var(--white) !important;
}
._view_smooth__c2vnD tr:last-of-type td {
  border-bottom: 1px solid
    var(--smooth-def-border-table) !important;
  border-bottom: 1px solid
    var(--smooth-border-table, var(--smooth-def-border-table)) !important;
}
._view_smooth__c2vnD tr[aria-checked="true"] td,
._view_smooth__c2vnD tr[aria-checked="true"]:hover td {
  background: var(--smooth-def-bg-active) !important;
  background: var(--smooth-bg-active, var(--smooth-def-bg-active)) !important;
  border-bottom: 1px solid
    var(--smooth-def-border2-table) !important;
  border-bottom: 1px solid
    var(--smooth-border2-table, var(--smooth-def-border2-table)) !important;
}

/*RAISED*/
._view_raised__24wXy thead {
  background-color: var(--white) !important;
  border-radius: 4px !important;
}
._view_raised__24wXy thead th:last-of-type {
  border-radius: 0px 4px 0px 0px !important;
}
._view_raised__24wXy thead th:first-of-type {
  border-radius: 4px 0px 0px 0px !important;
}
._view_raised__24wXy thead th {
  border-bottom-color: var(--raised-def-border2-table)
   !important;
  border-bottom-color: var(
    --raised-border2-table,
    var(--raised-def-border2-table)
  ) !important;
  color: var(--raised-def-color-table) !important;
  color: var(--raised-color-table, var(--raised-def-color-table)) !important;
}
._view_raised__24wXy td {
  border-bottom: 1px solid
    var(--raised-def-border-table) !important;
  border-bottom: 1px solid
    var(--raised-border-table, var(--raised-def-border-table)) !important;
  color: var(--grey100) !important;
}
._view_dense__3VgIH._view_raised__24wXy td {
  border-bottom: 1px solid
    var(--flat-def-bg-table-hover);
  border-bottom: 1px solid
    var(--flat-bg-table-hover, var(--flat-def-bg-table-hover));
  color: var(--grey100) !important;
}
._view_raised__24wXy tbody > tr {
  background-color: var(--white) !important;
}
._view_raised__24wXy tbody > tr:hover {
  background-color: var(--grey0) !important;
}
._view_raised__24wXy tr:last-of-type td {
  border-bottom: 1px solid
    var(--raised-def-border-table) !important;
  border-bottom: 1px solid
    var(--raised-border-table, var(--raised-def-border-table)) !important;
}
._view_dense__3VgIH._view_raised__24wXy tr:last-of-type td {
  border-bottom: 1px solid
    var(--flat-def-bg-table-hover);
  border-bottom: 1px solid
    var(--flat-bg-table-hover, var(--flat-def-bg-table-hover));
}
._view_raised__24wXy tr[aria-checked="true"] td,
._view_raised__24wXy tr[aria-checked="true"]:hover td {
  background: var(--smooth-def-bg-table) !important;
  background: var(--smooth-bg-table, var(--smooth-def-bg-table)) !important;
  border-bottom: 1px solid
    var(--raised-def-border2-table) !important;
  border-bottom: 1px solid
    var(--raised-border2-table, var(--raised-def-border2-table)) !important;
}

._view_flat__2mJdA thead span:hover,
._view_smooth__c2vnD thead span:hover,
._view_raised__24wXy thead span:hover {
  color: var(--color100) !important;
}

._view_flat__2mJdA tr:hover td,
._view_smooth__c2vnD tr:hover td,
._view_raised__24wXy tr:hover td {
  border-bottom: 1px solid
    var(--raised-def-border-table) !important;
  border-bottom: 1px solid
    var(--raised-border-table, var(--raised-def-border-table)) !important;
}

/*SORTING STYLE*/
/*Smooth, raised, flat*/
._view_smooth__c2vnD._view_sorted_head__12dEn,
._view_flat__2mJdA._view_sorted_head__12dEn,
._view_raised__24wXy._view_sorted_head__12dEn {
  background: var(--grey0) !important;
  border-bottom: 2px solid var(--smooth-def-bg-active) !important;
  border-bottom: 2px solid var(--smooth-bg-active, var(--smooth-def-bg-active)) !important;
}
._view_dense_smooth__WB0iF._view_sorted_head__12dEn,
._view_dense_flat__TgNcu._view_sorted_head__12dEn,
._view_dense_raised__1uVFa._view_sorted_head__12dEn {
  background: var(--grey0) !important;
  border-bottom: 1px solid var(--smooth-def-bg-active) !important;
  border-bottom: 1px solid var(--smooth-bg-active, var(--smooth-def-bg-active)) !important;
}
._view_smooth__c2vnD._view_sorted_body__3l52y,
._view_flat__2mJdA._view_sorted_body__3l52y,
._view_raised__24wXy._view_sorted_body__3l52y {
  background: var(--grey0) !important;
  border-color: var(--grey0) !important;
  border-radius: 0px !important;
}
._view_dense_smooth__WB0iF._view_sorted_body__3l52y,
._view_dense_flat__TgNcu._view_sorted_body__3l52y,
._view_dense_raised__1uVFa._view_sorted_body__3l52y {
  background: var(--grey0) !important;
  border-bottom: 1px solid var(--grey0) !important;
  border-radius: 0px !important;
}

._view_smooth__c2vnD._view_sorted_head__12dEn span,
._view_flat__2mJdA._view_sorted_head__12dEn span,
._view_raised__24wXy._view_sorted_head__12dEn span,
._view_dense_smooth__WB0iF._view_sorted_head__12dEn span,
._view_dense_flat__TgNcu._view_sorted_head__12dEn span,
._view_dense_raised__1uVFa._view_sorted_head__12dEn span {
  color: var(--color100) !important;
}
._view_smooth__c2vnD._view_sorted_head__12dEn svg,
._view_flat__2mJdA._view_sorted_head__12dEn svg,
._view_raised__24wXy._view_sorted_head__12dEn svg,
._view_dense_smooth__WB0iF._view_sorted_head__12dEn svg,
._view_dense_flat__TgNcu._view_sorted_head__12dEn svg,
._view_dense_raised__1uVFa._view_sorted_head__12dEn svg {
  color: var(--color100) !important;
}
/*Filled*/
._view_filled__3jo5F._view_sorted_head__12dEn {
  background: var(--filled-def-head-table-sort)
   !important;
  background: var(
    --filled-head-table-sort,
    var(--filled-def-head-table-sort)
  ) !important;
  border-bottom: 2px solid var(--filled-def-bg-table) !important;
  border-bottom: 2px solid var(--filled-bg-table, var(--filled-def-bg-table)) !important;
}
._view_dense_filled__1QdEe._view_sorted_head__12dEn {
  background: var(--filled-def-head-table-sort)
   !important;
  background: var(
    --filled-head-table-sort,
    var(--filled-def-head-table-sort)
  ) !important;
  border-bottom: 1px solid var(--filled-def-bg-table) !important;
  border-bottom: 1px solid var(--filled-bg-table, var(--filled-def-bg-table)) !important;
}
._view_filled__3jo5F._view_sorted_head__12dEn span,
._view_dense_filled__1QdEe._view_sorted_head__12dEn span {
  color: var(--white) !important;
}
._view_filled__3jo5F._view_sorted_head__12dEn svg,
._view_dense_filled__1QdEe._view_sorted_head__12dEn svg {
  color: var(--white) !important;
}
._view_filled__3jo5F._view_sorted_body__3l52y,
._view_dense_filled__1QdEe._view_sorted_body__3l52y {
  border-color: var(--filled-def-bg-table-hover)
   !important;
  border-color: var(
    --filled-bg-table-hover,
    var(--filled-def-bg-table-hover)
  ) !important;
  background: var(--filled-def-bg-table-hover)
   !important;
  background: var(
    --filled-bg-table-hover,
    var(--filled-def-bg-table-hover)
  ) !important;
  border-radius: 0px !important;
}

/*PAGINATION*/
/*Flat*/
._view_flat-pag__7R-jx span {
  color: var(--grey60) !important;
}
._view_flat-pag__7R-jx svg,
._view_flat-pag__7R-jx button span {
  color: var(--flat-def-color-table-pag)
   !important;
  color: var(
    --flat-color-table-pag,
    var(--flat-def-color-table-pag)
  ) !important;
}
._view_flat-pag__7R-jx div > div > div {
  color: var(--flat-def-color-table-pag)
   !important;
  color: var(
    --flat-color-table-pag,
    var(--flat-def-color-table-pag)
  ) !important;
}
._view_flat-pag__7R-jx div > div > div:focus,
._view_filled-pag__DNmPj div > div > div:focus,
._view_smooth-pag__1kLI0 div > div > div:focus,
._view_raised-pag__3RtLv div > div > div:focus {
  background-color: transparent !important;
}
._view_flat-pag__7R-jx button:hover {
  background-color: var(--white) !important;
  box-shadow: var(--raised-def-shadow) !important;
  box-shadow: var(--raised-shadow, var(--raised-def-shadow)) !important;
}
/*Filled*/
._view_filled-pag__DNmPj {
  background-color: var(--filled-def-bg-table)
   !important;
  background-color: var(
    --filled-bg-table,
    var(--filled-def-bg-table)
  ) !important;
  /* border-top: 1px solid
    var(--filled-border-table, var(--filled-def-border-table)) !important;*/
  border-radius: 0 0 4px 4px !important;
  color: var(--white) !important;
}
._view_filled-pag__DNmPj svg {
  color: var(--white) !important;
}
/*Smooth*/
._view_smooth-pag__1kLI0 {
  background-color: var(--smooth-def-bg-table)
   !important;
  background-color: var(
    --smooth-bg-table,
    var(--smooth-def-bg-table)
  ) !important;
  /* border-top: 1px solid
    var(--smooth-border-table, var(--smooth-def-border-table)) !important;*/
  border-radius: 0 0 4px 4px !important;
  color: var(--smooth-def-color-table-pag)
   !important;
  color: var(
    --smooth-color-table-pag,
    var(--smooth-def-color-table-pag)
  ) !important;
}
._view_smooth-pag__1kLI0 svg {
  color: var(--smooth-def-color-table-pag)
   !important;
  color: var(
    --smooth-color-table-pag,
    var(--smooth-def-color-table-pag)
  ) !important;
}
._view_def__2jgYb._view_smooth-pag__1kLI0 button {
  background-color: var(--white) !important;
  border: 1px solid var(--color30) !important;
}
/*.dense.smooth-pag button {
  border: none !important;
}*/
._view_smooth-pag__1kLI0 button:hover {
  border-color: var(--smooth-def-color-table-pag)
   !important;
  border-color: var(
    --smooth-color-table-pag,
    var(--smooth-def-color-table-pag)
  ) !important;
}
/*Raised*/
._view_raised-pag__3RtLv {
  /* border-top: 1px solid
    var(--raised-border-table, var(--raised-def-border-table)) !important;*/
  color: var(--smooth-def-color-table-pag)
   !important;
  color: var(
    --smooth-color-table-pag,
    var(--smooth-def-color-table-pag)
  ) !important;
}
._view_raised-pag__3RtLv svg {
  color: var(--smooth-def-color-table-pag)
   !important;
  color: var(
    --smooth-color-table-pag,
    var(--smooth-def-color-table-pag)
  ) !important;
}
._view_raised-pag__3RtLv button:hover {
  background-color: var(--color5) !important;
}

/*TRASH BUTTON*/
._view_flat-trash__1wrnL,
._view_smooth-trash__1sJoP,
._view_raised-trash__M7a0Y {
  color: var(--filled-def-bg-head-table)
   !important;
  color: var(
    --filled-bg-head-table,
    var(--filled-def-bg-head-table)
  ) !important;
}
._view_flat-trash-btn__2iUn5,
._view_smooth-trash-btn__RWVoZ,
._view_raised-trash-btn__3s_il {
  color: var(--red50) !important;
}
._view_flat-trash-btn__2iUn5:hover,
._view_smooth-trash-btn__RWVoZ:hover,
._view_raised-trash-btn__3s_il:hover {
  background-color: transparent !important;
  color: var(--red60) !important;
}

._view_filled-trash__18LIi,
._view_filled-trash-btn__3v--t {
  color: var(--white) !important;
}

/**CARD*/

._type_def__PWMhg {
  border-radius: 0.5rem;
  padding: 0;
}

/*CARD*/
/*filled*/
._view_filled__1wuaU {
  background-color: var(--color50);
  border: none;
  box-shadow: var(--shadow2dp);
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_filled__1wuaU[class*="interactive"]:hover {
  box-shadow: var(--shadow8dp);
  background-color: var(--color70);
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_filled__1wuaU[class*="interactive"]:active {
  box-shadow: var(--shadow2dp);
  background-color: var(--color50);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}

/*smooth*/
._view_smooth__2xfRF {
  background-color: var(--color0);
  border: none;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_smooth__2xfRF[class*="interactive"]:hover {
  background-color: var(--color10);
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_smooth__2xfRF[class*="interactive"]:active {
  box-shadow: none;
  background-color: var(--color0);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}

/*outlined*/
._view_outlined__3dvLU {
  background-color: var(--white);
  border: 1px solid var(--color20);
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_outlined__3dvLU[class*="interactive"]:hover {
  border: 1px solid var(--color30);
  box-shadow: 0 0 0 1px var(--color30);
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_outlined__3dvLU[class*="interactive"]:active {
  box-shadow: none;
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
/*raised*/
._view_raised__EReXM {
  background-color: var(--white);
  box-shadow: var(--shadow2dp);
  border: none;
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_raised__EReXM[class*="interactive"]:hover {
  box-shadow: var(--shadow8dp);
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_raised__EReXM[class*="interactive"]:active {
  box-shadow: var(--shadow2dp);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}

/* COUNTER TYPES*/
/*--DEFAULT--*/
._type_default__24DTV {
  width: var(--s28);
  height: var(--s28);
  min-width: unset;
  min-height: unset;
  border-radius: 99%;
  padding: 0;
}

._type_square__8nTOT {
  border-radius: var(--s4);
}

._type_dense__1LXMG {
  min-width: unset;
  min-height: unset;
  width: var(--s20);
  height: var(--s20);
}

._type_horizontal__97L3z {
  display: flex;
}
._type_vertical__3EFYs {
  display: inline-block;
  text-align: center;
}
._type_value__1-06W {
  padding-left: var(--s4);
  padding-right: var(--s4);
  margin-top: auto;
  margin-bottom: auto;
  color: var(--color100);
  font-family: var(--font_family_button);
  font-weight: bold;
  letter-spacing: var(--letter_spacing_button);
  font-size: var(--font_size_button);
  text-align: center;
}
._type_verticalValue__1-z_1 {
  padding-left: 0;
  padding-right: 0;
  padding-top: var(--s4);
  padding-bottom: var(--s4);
}
._type_denseValue__3RcnS {
  font-size: var(--font_size_caption);
  letter-spacing: var(--letter_spacing_caption);
}

/*FLAT STYLE*/
._view_flat__1rDua,
._view_flat__1rDua:not([class*="bp3-intent-"]),
._view_flat__1rDua:visited {
  color: var(--color70);
  background: none;
  box-shadow: none;
  /*transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;*/
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._view_flat__1rDua:hover,
._view_flat__1rDua:not([class*="bp3-intent-"]):hover,
._view_flat__1rDua:hover {
  box-shadow: var(--shadow2dp);
  background: none;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_flat__1rDua:active,
._view_flat__1rDua:not([class*="bp3-intent-"]):active,
._view_flat__1rDua:not([class*="bp3-intent-"])[class*="active"],
._view_flat__1rDua:not([class*="bp3-intent-"])._view_focused__1EtaH {
  background: var(--color5);
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_flat__1rDua:disabled,
._view_flat__1rDua:disabled > span > svg,
._view_flat__1rDua:disabled:hover,
._view_flat__1rDua:disabled:active {
  color: var(--color30) !important;
  opacity: 1 !important;
  box-shadow: none !important;
  background: var(--color0) !important;
}
/*END STYLE*/

/* FILLED STYLE*/
._view_filled__22Acv,
._view_filled__22Acv:visited {
  box-shadow: var(--shadow2dp) !important;
  background: var(--color60) !important;
  color: var(--color0) !important;
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._view_filled__22Acv:hover {
  background: var(--color70) !important;
  box-shadow: var(--shadow8dp) !important;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_filled__22Acv:active,
._view_filled__22Acv._view_focused__1EtaH {
  background: var(--color80) !important;
  box-shadow: none !important;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_filled__22Acv:disabled {
  color: var(--color40) !important;
  background: var(--color5) !important;
  box-shadow: none !important;
  opacity: 1 !important;
}
/* END STYLE */

/*SMOOTH STYLE*/
._view_smooth__18CqF[class*="button"],
._view_smooth__18CqF[class*="button"]:visited {
  color: var(--color70);
  background: var(--color5);
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._view_smooth__18CqF[class*="button"]:hover {
  color: var(--color100);
  background: var(--color10);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_smooth__18CqF[class*="button"]:active,
._view_smooth__18CqF:not([class*="bp3-intent-"]):active,
._view_smooth__18CqF:not([class*="bp3-intent-"])[class*="active"],
._view_smooth__18CqF[class*="button"]._view_focused__1EtaH {
  background: var(--color20);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_smooth__18CqF:disabled,
._view_smooth__18CqF:disabled:hover {
  color: var(--color30) !important;
  background: var(--color0) !important;
  opacity: 1 !important;
}
/* END STYLE */

/* OUTLINED STYLE*/
._view_outlined__3c-ce,
._view_outlined__3c-ce:not([class*="bp3-intent-"]),
._view_outlined__3c-ce:visited {
  background: none;
  box-shadow: 0 0 0 1px var(--color30); /*border: 1px solid var(--color30);*/
  color: var(--color70);
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._view_outlined__3c-ce:not([class*="bp3-intent-"]):hover {
  box-shadow: 0 0 0 2px var(--color30);
  width: calc(- 2px);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_outlined__3c-ce:not([class*="bp3-intent-"]):active,
._view_outlined__3c-ce._view_focused__1EtaH {
  color: var(--color100);
  box-shadow: 0 0 0 2px var(--color50);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_outlined__3c-ce:not([class*="bp3-intent-"]):disabled {
  background: none;
  color: var(--color30);
  box-shadow: 0 0 0 1px var(--color10);
}
/* END STYLE */

/* RAISED STYLE */
._view_raised__3Wuk3,
._view_raised__3Wuk3:not([class*="bp3-intent-"]),
._view_raised__3Wuk3:not([class*="bp3-intent-"]):visited {
  background: var(--color0);
  color: var(--color70);
  box-shadow: var(--shadow4dp);
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._view_raised__3Wuk3:not([class*="bp3-intent-"]):hover {
  background: var(--white);
  box-shadow: var(--shadow8dp);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_raised__3Wuk3:not([class*="bp3-intent-"]):active,
._view_raised__3Wuk3:not([class*="bp3-intent-"])._view_focused__1EtaH {
  box-shadow: var(--shadow2dp);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_raised__3Wuk3:not([class*="bp3-intent-"]):disabled {
  color: var(--color40);
  background: var(--color5);
  box-shadow: none;
}
/* END STYLE */

.section {
  border-bottom: 1px solid var(--grey10);
  margin-bottom: 1.5rem;
}
.margin {
  margin-bottom: 1.5rem;
}
.marginTag {
  margin-bottom: 1rem;
}

@media screen and (min-width: 1024px) {
  .section {
    border-bottom: 1px solid var(--grey10);
    margin-bottom: 2rem;
  }
  .margin {
    margin-bottom: 2rem;
  }
  .marginTag {
    margin-bottom: 1.5rem;
  }
}

@media screen and (min-width: 1280px) {
  .section {
    border-bottom: 1px solid var(--grey10);
    margin-bottom: 3rem;
    display: flex;
  }
  .marginTag {
    margin-bottom: 3rem;
    width: 20%;
  }
  .margin {
    margin-left: 5rem;
    width: 80%;
  }
}

/* BUTTONS TYPES*/

/*--DEFAULT--*/
._type_default__2Vetr {
  text-transform: capitalize;
}

._type_default__2Vetr > li::after {
  margin: 0 0.625rem;
  width: 1.25rem;
  height: 1.25rem;
}

._type_default__2Vetr span[class*="icon"] {
  margin-right: 0.5rem;
}

._type_default__2Vetr span[class*="breadcrumbs-collapsed"]::before {
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  margin-right: auto;
}
._type_default__2Vetr span[class*="breadcrumbs-collapsed"] {
  margin-right: 0;
  width: 2rem;
  height: 1.25rem;
  border-radius: 0.25rem;
  padding: 0;
}
._type_default__2Vetr a[class*="breadcrumb"] {
  padding: 0.125rem 0.5rem;
  border-radius: 0.25rem;
}

/*MORE ICON COLOR*/
._view_primary__1y_pq span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%232979ff'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
._view_success__2S8qc span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%2343a047'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
._view_warning__2eiRo span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%23d6981b'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
._view_danger__3RRKN span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%23F44336'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
._view_default__2P8fR span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%235A5B6A'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
._view_primary_alt__3r__i span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%235c6bc0'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
._view_success_alt__3bP3X span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%23069697'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
._view_warning_alt__3rrmF span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%23fb8200'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
._view_danger_alt__12R43 span[class*="breadcrumbs-collapsed"]::before {
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><g fill='%23ec407a'><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>")
    center no-repeat;
}
/*CHEVRON ICON COLOR*/
._view_primary__1y_pq > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%232979FF'/></svg>");
}
._view_success__2S8qc > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%2343a047'/></svg>");
}
._view_warning__2eiRo > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%23d6981b'/></svg>");
}
._view_danger__3RRKN > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%23F44336'/></svg>");
}
._view_default__2P8fR > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%235a5b6a'/></svg>");
}
._view_primary_alt__3r__i > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%235c6bc0'/></svg>");
}
._view_success_alt__3bP3X > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%23069697'/></svg>");
}
._view_warning_alt__3rrmF > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%23fb8200'/></svg>");
}
._view_danger_alt__12R43 > li::after {
  background: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.71 9.29L7.71 3.29C7.53 3.11 7.28 3 7 3C6.45 3 6 3.45 6 4C6 4.28 6.11 4.53 6.29 4.71L11.59 10L6.3 15.29C6.11 15.47 6 15.72 6 16C6 16.55 6.45 17 7 17C7.28 17 7.53 16.89 7.71 16.71L13.71 10.71C13.89 10.53 14 10.28 14 10C14 9.72 13.89 9.47 13.71 9.29Z' fill='%23ec407a'/></svg>");
}
/*--------*/

/*SMOOTH STYLE*/
._view_smooth__q9UEN {
  color: var(--color100);
}
._view_smooth__q9UEN span[class*="breadcrumbs-collapsed"] {
  background: var(--color30);
  color: var(--color100);
}
._view_smooth__q9UEN a[class*="breadcrumb"] {
  color: var(--color80);
}
._view_smooth__q9UEN a[class*="breadcrumb"] > span[class*="icon"] {
  color: var(--color30);
}
._view_smooth__q9UEN a[class*="breadcrumb"]:hover {
  background: var(--color5);
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_smooth__q9UEN a[class*="breadcrumb"]:hover > span[class*="icon"] {
  color: var(--color50);
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}

/* END STYLE */
._view_popover__39Z7z div[class*="popover-content"] a[class*="menu-item"]:hover {
  background-color: var(--color5);
}
._view_popover__39Z7z div[class*="popover-arrow"] {
  display: none;
}

._type_default__1h5Et {
  width: 100%;
  margin: 0.5rem 0;
  border-radius: 0.25rem;
}

._type_default__1h5Et._type_vertical__6fuRi {
  margin: 0 0.5rem;
}

._view_color0__1BwtE {
  background: var(--color0);
}
._view_color5__3S5Ag {
  background: var(--color5);
}
._view_color10__3QZq_ {
  background: var(--color10);
}
._view_color20__2Bb8l {
  background: var(--color20);
}
._view_color30__1fF26 {
  background: var(--color30);
}
._view_color40__2xqWg {
  background: var(--color40);
}
._view_color50__38wsI {
  background: var(--color50);
}
._view_color60__1H69K {
  background: var(--color60);
}
._view_color70__39E0q {
  background: var(--color70);
}
._view_color80__3dk6J {
  background: var(--color80);
}
._view_color90__1fbXe {
  background: var(--color90);
}
._view_color100__2MtLH {
  background: var(--color100);
}

/* DIALOG TYPES*/
/*--DEFAULT--*/
._type_default__EjeKL {
  width: 98%;
  max-width: 30.5rem;
  padding: 2.5rem;
  border-radius: 1.5rem;
}

._type_header__2G-ha {
  display: flex;
  margin-bottom: 1rem;
}
._type_header__2G-ha > span[class*="icon"] {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 1rem;
}
._type_footer__3vi4C {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
}
._type_left-button__22T6n {
  /* float: left; */
}
._type_right-button__1p_w1 {
  /* float: right; */
}

._type_dense__1m3lf {
  width: 98%;
  max-width: 20.5rem;
  padding: 1rem;
  border-radius: 1rem;
}
._type_dense__1m3lf._type_header__2G-ha {
  margin-bottom: 0.75rem;
}
._type_dense__1m3lf._type_header__2G-ha > span[class*="icon"] {
  margin-right: 0.75rem;
}
._type_dense__1m3lf._type_footer__3vi4C {
  margin-top: 1.75rem;
}

._type_close-button__LazTx {
  margin-right: 0;
  margin-left: auto;
}

/*DIALOG STYLE*/

/*SMOOTH*/
._view_smooth__1n6gH {
  background: var(--color5);
  box-shadow: none;
}
/*RAISED*/
._view_raised__vvobL {
  background: var(--white);
  box-shadow: var(--shadow24dp);
}
/*FILLED*/
._view_filled__3Te-A {
  background: var(--color50);
  box-shadow: none;
}
._view_filled__3Te-A > div[class*="header"] > div,
._view_filled__3Te-A > div[class*="body"] {
  color: var(--color0);
}
/*ICON*/
._view_filled__3Te-A > div[class*="header"] > span[class*="icon"] > svg,
._view_raised__vvobL > div[class*="header"] > span[class*="icon"] > svg,
._view_smooth__1n6gH > div[class*="header"] > span[class*="icon"] > svg {
  fill: var(--color30);
}
/*CLOSE_BUTTON*/
._view_filled__3Te-A > div[class*="header"] div[class*="close-button"] svg,
._view_raised__vvobL > div[class*="header"] div[class*="close-button"] svg,
._view_smooth__1n6gH > div[class*="header"] div[class*="close-button"] svg {
  fill: var(--color30);
  transition: all 150ms ease-in-out;
}

._view_raised__vvobL > div[class*="header"] div[class*="close-button"]:hover svg,
._view_smooth__1n6gH > div[class*="header"] div[class*="close-button"]:hover svg {
  fill: var(--color60);
  transition: all 150ms ease-in-out;
}
._view_filled__3Te-A > div[class*="header"] div[class*="close-button"]:hover svg {
  fill: var(--color5);
  transition: all 150ms ease-in-out;
}
._view_filled__3Te-A > div[class*="header"] div[class*="close-button"]:hover,
._view_raised__vvobL > div[class*="header"] div[class*="close-button"]:hover,
._view_smooth__1n6gH > div[class*="header"] div[class*="close-button"]:hover {
  cursor: pointer;
  transition: all 150ms ease-in-out;
}

/*BACKDROP STYLE*/
._view_backdrop-0__2ExJi {
  background-color: rgba(25, 25, 29, 0);
}
._view_backdrop-10__1i_F5 {
  background-color: rgba(25, 25, 29, 0.1);
}
._view_backdrop-20__1cQeN {
  background-color: rgba(25, 25, 29, 0.2);
}
._view_backdrop-30__1uOJi {
  background-color: rgba(25, 25, 29, 0.3);
}
._view_backdrop-40__2t2nm {
  background-color: rgba(25, 25, 29, 0.4);
}
._view_backdrop-50__2vWUs {
  background-color: rgba(25, 25, 29, 0.5);
}
._view_backdrop-60__w1LM5 {
  background-color: rgba(25, 25, 29, 0.6);
}
._view_backdrop-70__1LPSk {
  background-color: rgba(25, 25, 29, 0.7);
}
._view_backdrop-80__GpKTc {
  background-color: rgba(25, 25, 29, 0.8);
}
._view_backdrop-90__1NP6x {
  background-color: rgba(25, 25, 29, 0.9);
}
._view_backdrop-100__2NE9y {
  background-color: rgba(25, 25, 29, 1);
}
/* END STYLE */

:root {
  --transitionFunc: cubic-bezier(0, 0, 0, 1);
}

._type_wrapper__1I5yA {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  /* background: var(--color10);*/
  padding: 1rem;
  cursor: pointer;
}
/*--card--*/
._type_round__ZCMus {
  border-radius: var(--s8);
}
/*--circle--*/
._type_circle__21b-f {
  border-radius: 50%;
}

._type_hamburger__vUW4a {
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0, 0, 0, 1);
  transition-timing-function: var(--transitionFunc);
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
._type_hamburger__vUW4a._type_is-active__239d_ ._type_hamburger-bar__34Pj8 {
  background-color: currentcolor;
}

._type_hamburger-bar__34Pj8 {
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}
._type_hamburger-bar__34Pj8,
._type_hamburger-bar--top__1peXp,
._type_hamburger-bar--bottom__3E4TR {
  width: 100%;
  height: inherit;
  background-color: currentcolor;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0, 0, 0, 1);
  transition-timing-function: var(--transitionFunc);
}
div [class*="round"] ._type_hamburger-bar__34Pj8,
div [class*="round"] ._type_hamburger-bar--top__1peXp,
div [class*="round"] ._type_hamburger-bar--bottom__3E4TR {
  border-radius: 0.25rem;
}

._type_hamburger-bar--top__1peXp,
._type_hamburger-bar--bottom__3E4TR {
  content: "";
  display: block;
  position: absolute;
}

/** CSS animations from https://jonsuh.com/hamburgers/ */
/** Slider  */
._type_hamburger--slider__wsOkS ._type_hamburger-bar__34Pj8 {
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}
._type_hamburger--slider__wsOkS._type_is-active__239d_ ._type_hamburger-bar__34Pj8 {
  transform: translate(-0.5rem, 0px);
  opacity: 0;
}

/** Squeeze */
._type_hamburger--squeeze__2Wb9D ._type_hamburger-bar__34Pj8 {
  opacity: 1;
  transition: opacity 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
._type_hamburger--squeeze__2Wb9D._type_is-active__239d_ ._type_hamburger-bar__34Pj8 {
  opacity: 0;
  transition: opacity 0.075s 0.12s ease;
}

/** Elastic  */
._type_hamburger--elastic__qdsqN ._type_hamburger-bar__34Pj8 {
  transition: opacity 0.125s 0.275s ease;
}
._type_hamburger--elastic__qdsqN ._type_hamburger-bar--bottom__3E4TR,
._type_hamburger--elastic__qdsqN ._type_hamburger-bar--top__1peXp {
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55),
    top 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55),
    bottom 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
._type_hamburger--elastic__qdsqN._type_is-active__239d_ ._type_hamburger-bar__34Pj8 {
  transition-delay: 0s;
  opacity: 0;
}

/** Emphatic  */
._type_hamburger--emphatic__3t0a4 {
  overflow: hidden;
}
._type_hamburger--emphatic__3t0a4 ._type_hamburger-bar__34Pj8 {
  transition: background-color 0.125s 0.175s ease-in;
}
._type_hamburger--emphatic__3t0a4._type_is-active__239d_ ._type_hamburger-bar__34Pj8 {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important;
}

/** Spin   */
._type_hamburger--spin__1XOrP ._type_hamburger-bar__34Pj8 {
  opacity: 1;
  transition: opacity 0.1s 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
._type_hamburger--spin__1XOrP._type_is-active__239d_ ._type_hamburger-bar__34Pj8 {
  opacity: 0;
  transition: opacity 0.1s 0.12s ease-out;
}
/*END*/

/*FLAT STYLE*/
._view_flat__3cdRp {
  color: var(--color70);
}
/*END STYLE*/

/* FILLED STYLE*/
._view_filled__XsUPB {
  color: var(--color0);
  background: var(--color70);
}
/* END STYLE */

/*SMOOTH STYLE*/
._view_smooth__25eHj {
  color: var(--color70);
  background: var(--color5);
}
/* END STYLE */

/* OUTLINED STYLE*/
._view_outlined__376JE {
  color: var(--color70);
  box-shadow: 0 0 0 1px var(--color30);
}
/* END STYLE */

/* BUTTONS TYPES*/
/*--DEFAULT--*/
._type_default__3LVJL {
  height: 2.5rem;
  align-items: center;
}
._type_dense__1lbYs {
  height: 1.75rem;
  align-items: center;
}
._type_default__3LVJL[class*="button-group"] > button[class*="button"] {
  min-width: 3.25rem;
}
._type_dense__1lbYs[class*="button-group"] > button[class*="button"] {
  min-width: 2.5rem;
}
._type_right__2Z931 {
  float: right;
}

/*FLAT STYLE*/
._view_flat__3kKPb {
  background: none;
  box-shadow: none;
  /*transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);*/
}

._view_flat__3kKPb[class*="button-group"] > button[class*="button"] {
  color: var(--color40);
  background-color: var(--white);
  box-shadow: none;
  margin-right: 0 !important;
  border-radius: 0.25rem !important;
}
._view_flat__3kKPb > button[class*="button"]:hover {
  color: var(--color90);
  background-color: var(--color0);
  box-shadow: none;
  transition: background-color color 0.25s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._view_flat__3kKPb > button[class*="button"][class*="focused"] {
  background-color: var(--color5);
  color: var(--color90);
}

/*END STYLE*/
/*FILLED STYLE*/
._view_filled__gDv6e {
  background: none;
  box-shadow: none;
  background-color: var(--color10);
  border-radius: 0.25rem !important;
}

._view_filled__gDv6e[class*="button-group"] > button[class*="button"] {
  color: var(--color50);
  background-color: inherit;
  box-shadow: none;
  margin-right: 0 !important;
  border-radius: 0.25rem !important;
}
._view_filled__gDv6e > button[class*="button"]:hover {
  color: var(--color100);
  background-color: var(--color20);
  box-shadow: none;
  transition: background-color color 0.25s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._view_filled__gDv6e > button[class*="button"][class*="focused"] {
  background-color: var(--color50);
  color: var(--white);
}
/*END STYLE*/
/*SMOOTH STYLE*/
._view_smooth__mykOx {
  background: none;
  box-shadow: none;
  background-color: var(--color5);
  border-radius: 0.25rem !important;
}
._view_smooth__mykOx[class*="button-group"] > button[class*="button"] {
  color: var(--color40);
  background-color: inherit;
  box-shadow: none;
  margin-right: 0 !important;
  border-radius: 0.25rem !important;
}
._view_smooth__mykOx > button[class*="button"]:hover {
  color: var(--color100);
  background-color: var(--color10);
  box-shadow: none;
  transition: background-color color 0.25s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._view_smooth__mykOx > button[class*="button"][class*="focused"] {
  background-color: var(--color20);
  color: var(--color100);
}
/*END STYLE*/
/*OUTLINED STYLE*/
._view_outlined__2A6yF {
  background: none;
  background-color: var(--white);
  box-shadow: 0 0 0 1px var(--color20);
  border-radius: 0.25rem !important;
}
._view_outlined__2A6yF[class*="button-group"] > button[class*="button"] {
  color: var(--color50);
  background-color: inherit;
  box-shadow: none;
  margin-right: 0 !important;
  border-radius: 0.25rem !important;
  z-index: 1;
}
._view_outlined__2A6yF > button[class*="button"]:hover {
  color: var(--color90);
  background-color: var(--color0);
  box-shadow: none;
  transition: background-color color 0.25s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._view_outlined__2A6yF > button[class*="button"][class*="focused"] {
  background-color: var(--white);
  color: var(--color90);
  box-shadow: 0 0 0 2px var(--color30);
  z-index: 6;
  transition: box-shadow background-color color 0.25s
    cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
/*END STYLE*/

/*RAISED STYLE*/
._view_raised__2_BiA {
  background: none;
  box-shadow: none;
  background-color: var(--color0);
  border-radius: 0.25rem !important;
}
._view_raised__2_BiA[class*="button-group"] > button[class*="button"] {
  color: var(--color40);
  background-color: inherit;
  box-shadow: none;
  margin-right: 0 !important;
  border-radius: 0.25rem !important;
  z-index: 1;
}
._view_raised__2_BiA > button[class*="button"]:hover {
  color: var(--color90);
  background-color: var(--color5);
  box-shadow: none;
  transition: background-color color 0.25s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._view_raised__2_BiA > button[class*="button"][class*="focused"] {
  background-color: var(--white);
  color: var(--color90);
  box-shadow: var(--shadow2dp);
  z-index: 6;
  transition: box-shadow background-color color 0.25s
    cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
/*END STYLE*/

/**TIME PICKER*/
._type_default__3xKSc div[class*="timepicker-input-row"] {
  height: auto;
  border-radius: 0.25rem;
  line-height: unset;
  padding: 0;
}

._type_default__3xKSc input[class*="timepicker-input"] {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.25rem;
  line-height: unset;
  letter-spacing: 0.0275rem;
}

._type_default__3xKSc span[class*="timepicker-divider-text"] {
  width: 0.5rem;
}
/*Arrow Button*/
._type_default__3xKSc span[class*="timepicker-arrow-button"] {
  width: 2.5rem;
}
._type_default__3xKSc span[class*="timepicker-arrow-button"][class*="minute"],
._type_default__3xKSc span[class*="timepicker-arrow-button"][class*="second"],
._type_default__3xKSc span[class*="timepicker-arrow-button"][class*="millisecond"] {
  margin-left: 0.5rem;
}
._type_default__3xKSc div[class*="timepicker-arrow-row"] svg {
  width: 1.25rem;
  height: 1.25rem;
}

/**/

/*AM/PM*/
._type_default__3xKSc div[class*="ampm-select"] {
  margin-left: 0.5rem;
}
._type_default__3xKSc div[class*="ampm-select"] > select {
  height: 2.5rem;
  line-height: unset;
  border-radius: 0.25rem;
  padding: 0 1rem;
  letter-spacing: 0.0275rem;
  font-size: inherit;
  font-family: inherit;
}
._type_default__3xKSc div[class*="ampm-select"] > span[class*="icon"] {
  position: relative;
  top: 0.125rem;
  right: unset;
  left: -36px;
  padding-right: 16px;
}
._type_default__3xKSc div[class*="ampm-select"] > span[class*="icon"] > svg {
  width: 1.25rem;
  height: 1.25rem;
}
/**/

/* OUTLINED STYLE*/
._view_outlined__1SzyQ div[class*="timepicker-input-row"] {
  box-shadow: inset 0 0 0 1px var(--color30);
}
._view_outlined__1SzyQ input[class*="timepicker-input"] {
  color: var(--color100);
}
._view_outlined__1SzyQ span[class*="timepicker-divider-text"] {
  color: var(--color40);
}
._view_outlined__1SzyQ input[class*="timepicker-input"]:focus {
  border: 2px solid var(--color80);
  box-shadow: var(--shadow4dp);
}
/*Arrow Button*/
._view_outlined__1SzyQ div[class*="timepicker-arrow-row"] svg {
  color: var(--color70);
}
._view_outlined__1SzyQ span[class*="timepicker-arrow-button"]:hover svg {
  color: var(--color100);
}

/**/
/*AM/PM*/
._view_outlined__1SzyQ div[class*="ampm-select"] > select {
  background: var(--color5);
  border: none;
  box-shadow: none;
  color: var(--color70);
}
._view_outlined__1SzyQ div[class*="ampm-select"] > span[class*="icon"] {
  color: var(--color70);
}

/*ERROR*/
._view_outlined__1SzyQ input[class*="timepicker-input"][class*="danger"]:focus {
  border: 2px solid var(--red80);
  box-shadow: var(--redShadow4dp);
  /*transition: border 150ms cubic-bezier(0.19, 1, 0.22, 1);*/
}
._view_outlined__1SzyQ input[class*="timepicker-input"][class*="danger"] {
  border: 1px solid var(--red30);
  box-shadow: none;
  /*transition: border 150ms cubic-bezier(0.19, 1, 0.22, 1);*/
}

/* END STYLE */

._type_default__iAvJQ {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 0.5rem;
}
._type_default__iAvJQ div[class*="DayPicker-Day"],
._type_default__iAvJQ div[class*="DayPicker-Day"] > div[class*="day-wrapper"] {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  line-height: 2.5rem;
}

._type_default__iAvJQ div[class*="DayPicker-Weekday"] {
  padding: unset;
  width: 2.5rem;
  height: 1.5rem;
  font-size: var(--font_size_body_small);
  font-weight: var(--font_weight_body_medium);
  letter-spacing: var(--letter_spacing_body_small);
}

/*NavBar*/
._type_default__iAvJQ div[class*="datepicker-caption"] {
  height: 2.5rem;
  margin: 0 2rem;
}
._type_default__iAvJQ div[class*="datepicker-navbar"],
._type_default__iAvJQ div[class*="datepicker-navbar"] > button {
  height: 2.5rem;
}
._type_default__iAvJQ div[class*="datepicker-navbar"] > button svg {
  width: 1.25rem;
  height: 1.25rem;
}
._type_default__iAvJQ div[class*="datepicker-month-select"] > select,
._type_default__iAvJQ div[class*="datepicker-year-select"] > select {
  font-family: inherit;
  font-size: var(--font_size_body_small);
  font-weight: var(--font_weight_body_medium);
  letter-spacing: var(--letter_spacing_body_small);
  height: 2.5rem;
  padding: 0;
  margin: 0;
  padding-left: 0.5rem;
}
._type_default__iAvJQ div[class*="datepicker-year-select"] > select {
  min-width: 68px;
}

._type_default__iAvJQ div[class*="datepicker-month-select"] span > svg,
._type_default__iAvJQ div[class*="datepicker-year-select"] span > svg {
  height: 2.5rem;
  width: 1.25rem;
}
._type_default__iAvJQ div[class*="datepicker-month-select"] span,
._type_default__iAvJQ div[class*="datepicker-year-select"] span {
  top: unset;
}

/*end*/
/*footer*/
._type_default__iAvJQ div[class*="datepicker-footer"] > button {
  line-height: unset;
  height: 2.5rem;
  padding: 0 1rem;
}
/**/

/*shortcuts*/
._type_default__iAvJQ ul[class*="daterangepicker-shortcuts"] {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 3rem;
}
._type_default__iAvJQ ul[class*="daterangepicker-shortcuts"] a[class*="menu-item"] {
  height: 2.5rem;
  padding: 0;
  margin: 0.25rem;
  border-radius: 0.25rem;
}
._type_default__iAvJQ ul[class*="daterangepicker-shortcuts"] a[class*="menu-item"] > div {
  margin: auto 1rem;
}
/**/

/*FLAT STYLE*/
._view_flat__cnRzx {
  color: var(--color100);
}
._view_flat__cnRzx div[class*="DayPicker-Day"] > div[class*="day-wrapper"] {
  border: none !important;
  padding: unset;
}
._view_flat__cnRzx div[class*="DayPicker-Day"][class*="DayPicker-Day--selected"],
._view_flat__cnRzx div[class*="DayPicker-Day"][class*="DayPicker-Day--selected"]:hover {
  background: var(--color5);
  color: var(--color100);
  border-radius: 50%;
}

._view_flat__cnRzx div[class*="divider"] {
  margin: 0.5rem;
  border-right: 1px solid var(--color5);
  border-bottom: 1px solid var(--color5);
}

/*today*/
._view_flat__cnRzx div[class*="DayPicker-Day--isToday"] {
  color: var(--color100);
}
._view_flat__cnRzx div[class*="DayPicker-Day--isToday"] {
  box-shadow: inset 0 0 0 1px var(--color30);
}
._view_flat__cnRzx div[class*="DayPicker-Day--selected"] {
  background-color: var(--color5);
  box-shadow: inset 0 0 0 2px var(--color60);
}
/*end today*/

/*shortcut*/
._view_flat__cnRzx ul[class*="daterangepicker-shortcuts"] a[class*="menu-item"] {
  color: var(--color70);
}
._view_flat__cnRzx
  ul[class*="daterangepicker-shortcuts"]
  a[class*="menu-item"][class*="active"],
._view_flat__cnRzx ul[class*="daterangepicker-shortcuts"] a[class*="menu-item"]:hover {
  background: var(--color5);
}
._view_flat__cnRzx
  ul[class*="daterangepicker-shortcuts"]
  a[class*="menu-item"][class*="active"] {
  font-weight: 500;
}
/*end*/

/*navbar*/
._view_flat__cnRzx div[class*="datepicker-navbar"] > button {
  width: 2.5rem;
  border-radius: 50%;
  color: var(--color70);
}
._view_flat__cnRzx div[class*="datepicker-navbar"] > button:hover {
  color: var(--color100);
  box-shadow: var(--shadow2dp);
}
/*end*/

/*month selector*/
._view_flat__cnRzx div[class*="datepicker-month-select"] > select,
._view_flat__cnRzx div[class*="datepicker-year-select"] > select {
  color: var(--color70);
}
._view_flat__cnRzx div[class*="datepicker-month-select"] > select:hover,
._view_flat__cnRzx div[class*="datepicker-year-select"] > select:hover {
  background: var(--color5);
  color: var(--color100);
}
._view_flat__cnRzx div[class*="datepicker-month-select"] > span[class*="icon"],
._view_flat__cnRzx div[class*="datepicker-year-select"] > span[class*="icon"] {
  color: var(--color70);
}
/*end*/

/*footer*/
._view_flat__cnRzx div[class*="datepicker-footer"] > button:hover {
  background: var(--color5);
}
/*end*/

._view_flat__cnRzx div[class*="DayPicker-Day--outside"] > div[class*="day-wrapper"] {
  color: var(--color40);
}
._view_flat__cnRzx div[class*="DayPicker-Day"]:hover {
  background: var(--color0);
}
/*END STYLE*/

/*SMOOTH STYLE*/
._view_smooth__lz2Ha {
  color: var(--color100);
  background: var(--color0);
}
._view_smooth__lz2Ha div[class*="DayPicker-Day"] > div[class*="day-wrapper"] {
  border: none !important;
  padding: unset;
}
._view_smooth__lz2Ha div[class*="DayPicker-Day"][class*="DayPicker-Day--selected"],
._view_smooth__lz2Ha div[class*="DayPicker-Day"][class*="DayPicker-Day--selected"]:hover {
  background: var(--color5);
  color: var(--color100);
  border-radius: 50%;
}

._view_smooth__lz2Ha div[class*="divider"] {
  margin: 0.5rem;
  border-right: 1px solid var(--color5);
  border-bottom: 1px solid var(--color5);
}

/*today*/
._view_smooth__lz2Ha div[class*="DayPicker-Day--isToday"] {
  color: var(--color100);
}
._view_smooth__lz2Ha div[class*="DayPicker-Day--isToday"] {
  box-shadow: inset 0 0 0 1px var(--color30);
}
._view_smooth__lz2Ha div[class*="DayPicker-Day--selected"] {
  background-color: var(--color5);
  box-shadow: inset 0 0 0 2px var(--color60);
}
/*end today*/

/*shortcut*/
._view_smooth__lz2Ha ul[class*="daterangepicker-shortcuts"] {
  background: var(--color0);
}
._view_smooth__lz2Ha ul[class*="daterangepicker-shortcuts"] a[class*="menu-item"] {
  color: var(--color70);
}
._view_smooth__lz2Ha
  ul[class*="daterangepicker-shortcuts"]
  a[class*="menu-item"][class*="active"],
._view_smooth__lz2Ha ul[class*="daterangepicker-shortcuts"] a[class*="menu-item"]:hover {
  background: var(--color0);
  box-shadow: 0 0 0 1px var(--color30);
}
._view_smooth__lz2Ha
  ul[class*="daterangepicker-shortcuts"]
  a[class*="menu-item"][class*="active"] {
  font-weight: 500;
}
/*end*/

/*navbar*/
._view_smooth__lz2Ha div[class*="datepicker-navbar"] > button {
  width: 2.5rem;
  border-radius: 50%;
  color: var(--color70);
}
._view_smooth__lz2Ha div[class*="datepicker-navbar"] > button:hover {
  color: var(--color100);
  box-shadow: var(--shadow2dp);
}
/*end*/

/*month selector*/
._view_smooth__lz2Ha div[class*="datepicker-month-select"] > select,
._view_smooth__lz2Ha div[class*="datepicker-year-select"] > select {
  color: var(--color70);
}
._view_smooth__lz2Ha div[class*="datepicker-month-select"] > select:hover,
._view_smooth__lz2Ha div[class*="datepicker-year-select"] > select:hover {
  background: var(--color5);
  color: var(--color100);
}
._view_smooth__lz2Ha div[class*="datepicker-month-select"] > span[class*="icon"],
._view_smooth__lz2Ha div[class*="datepicker-year-select"] > span[class*="icon"] {
  color: var(--color70);
}
/*end*/

/*footer*/
._view_smooth__lz2Ha div[class*="datepicker-footer"] > button:hover {
  background: var(--color5);
}
/*end*/

._view_smooth__lz2Ha div[class*="DayPicker-Day--outside"] > div[class*="day-wrapper"] {
  color: var(--color40);
}
._view_smooth__lz2Ha div[class*="DayPicker-Day"]:hover {
  background: var(--color0);
}
/* END STYLE */

/* OUTLINED STYLE*/
._view_outlined__2Mf4R {
}

/* END STYLE */

/* RAISED STYLE */
._view_raised__1nQtN {
  color: var(--color100);
  background: var(--white);
  box-shadow: var(--shadow16dp);
}
._view_raised__1nQtN div[class*="DayPicker-Day"] > div[class*="day-wrapper"] {
  border: none !important;
  padding: unset;
}
._view_raised__1nQtN div[class*="DayPicker-Day"][class*="DayPicker-Day--selected"],
._view_raised__1nQtN div[class*="DayPicker-Day"][class*="DayPicker-Day--selected"]:hover {
  background: var(--color5);
  color: var(--color100);
  border-radius: 50%;
}

._view_raised__1nQtN div[class*="divider"] {
  margin: 0.5rem;
  border-right: 1px solid var(--color5);
  border-bottom: 1px solid var(--color5);
}

/*today*/
._view_raised__1nQtN div[class*="DayPicker-Day--isToday"] {
  color: var(--color100);
}
._view_raised__1nQtN div[class*="DayPicker-Day--isToday"] {
  box-shadow: inset 0 0 0 1px var(--color30);
}
._view_raised__1nQtN div[class*="DayPicker-Day--selected"] {
  background-color: var(--color5);
  box-shadow: inset 0 0 0 2px var(--color60);
}
/*end today*/

/*shortcut*/
._view_raised__1nQtN ul[class*="daterangepicker-shortcuts"] a[class*="menu-item"] {
  color: var(--color70);
}
._view_raised__1nQtN
  ul[class*="daterangepicker-shortcuts"]
  a[class*="menu-item"][class*="active"],
._view_raised__1nQtN ul[class*="daterangepicker-shortcuts"] a[class*="menu-item"]:hover {
  background: var(--color0);
  box-shadow: var(--shadow4dp);
}
._view_raised__1nQtN
  ul[class*="daterangepicker-shortcuts"]
  a[class*="menu-item"][class*="active"] {
  font-weight: 500;
}
/*end*/

/*navbar*/
._view_raised__1nQtN div[class*="datepicker-navbar"] > button {
  width: 2.5rem;
  border-radius: 50%;
  color: var(--color70);
}
._view_raised__1nQtN div[class*="datepicker-navbar"] > button:hover {
  color: var(--color100);
  box-shadow: var(--shadow2dp);
}
/*end*/

/*month selector*/
._view_raised__1nQtN div[class*="datepicker-month-select"] > select,
._view_raised__1nQtN div[class*="datepicker-year-select"] > select {
  color: var(--color70);
}
._view_raised__1nQtN div[class*="datepicker-month-select"] > select:hover,
._view_raised__1nQtN div[class*="datepicker-year-select"] > select:hover {
  background: var(--color5);
  color: var(--color100);
}
._view_raised__1nQtN div[class*="datepicker-month-select"] > span[class*="icon"],
._view_raised__1nQtN div[class*="datepicker-year-select"] > span[class*="icon"] {
  color: var(--color70);
}
/*end*/

/*footer*/
._view_raised__1nQtN div[class*="datepicker-footer"] > button:hover {
  background: var(--color0);
  box-shadow: var(--shadow4dp);
}
/*end*/

._view_raised__1nQtN div[class*="DayPicker-Day--outside"] > div[class*="day-wrapper"] {
  color: var(--color40);
}
._view_raised__1nQtN div[class*="DayPicker-Day"]:hover {
  background: var(--color0);
}
/* END STYLE */

._type_default__1ae_J {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  border-radius: 0.5rem;
}
._type_default__1ae_J div[class*="DayPicker-Day"],
._type_default__1ae_J div[class*="DayPicker-Day"] > div[class*="day-wrapper"] {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
}

._type_default__1ae_J div[class*="DayPicker-Weekday"] {
  padding: unset;
  width: 2.5rem;
  height: 1.5rem;
  font-size: var(--font_size_body_small);
  font-weight: var(--font_weight_body_medium);
  letter-spacing: var(--letter_spacing_body_small);
}

/*NavBar*/
._type_default__1ae_J div[class*="datepicker-caption"] {
  height: 2.5rem;
  margin: 0 2rem;
}
._type_default__1ae_J div[class*="datepicker-navbar"][class*="DayPicker-NavBar"] {
  left: 0 !important;
}
._type_default__1ae_J div[class*="datepicker-navbar"],
._type_default__1ae_J div[class*="datepicker-navbar"] > button {
  height: 2.5rem;
}
._type_default__1ae_J div[class*="datepicker-navbar"] > button svg {
  width: 1.25rem;
  height: 1.25rem;
}
._type_default__1ae_J div[class*="datepicker-month-select"] > select,
._type_default__1ae_J div[class*="datepicker-year-select"] > select {
  font-family: inherit;
  font-size: var(--font_size_body_small);
  font-weight: var(--font_weight_body_medium);
  letter-spacing: var(--letter_spacing_body_small);
  height: 2.5rem;
  padding: 0;
  margin: 0;
  padding-left: 0.5rem;
}
._type_default__1ae_J div[class*="datepicker-year-select"] > select {
  min-width: 68px;
}

._type_default__1ae_J div[class*="datepicker-month-select"] span > svg,
._type_default__1ae_J div[class*="datepicker-year-select"] span > svg {
  height: 2.5rem;
  width: 1.25rem;
}
._type_default__1ae_J div[class*="datepicker-month-select"] span,
._type_default__1ae_J div[class*="datepicker-year-select"] span {
  top: unset;
}

/*end*/
/*footer*/
._type_default__1ae_J div[class*="datepicker-footer"] > button {
  line-height: unset;
  height: 2.5rem;
  padding: 0 1rem;
}
/**/

/*shortcuts*/
._type_default__1ae_J ul[class*="daterangepicker-shortcuts"] {
  padding-left: 1rem;
  padding-right: 1rem;
}
._type_default__1ae_J ul[class*="daterangepicker-shortcuts"] a[class*="menu-item"] {
  height: 2.5rem;
  padding: 0;
  margin: 0.25rem;
  border-radius: 0.25rem;
}
._type_default__1ae_J ul[class*="daterangepicker-shortcuts"] a[class*="menu-item"] > div {
  margin: auto 1rem;
}
/**/

/*FLAT STYLE*/
._view_flat__2i9i0 {
  color: var(--color100);
}
._view_flat__2i9i0 div[class*="DayPicker-Day"] > div[class*="day-wrapper"] {
  border: none !important;
  padding: unset;
}
._view_flat__2i9i0 div[class*="DayPicker-Day"][class*="DayPicker-Day--selected"],
._view_flat__2i9i0 div[class*="DayPicker-Day"][class*="DayPicker-Day--selected"]:hover {
  background: var(--color5);
  color: var(--color100);
  border-radius: 0;
}

/*--DIVIDER*/
._view_flat__2i9i0 div[class*="divider"] {
  border-bottom: 1px solid var(--color5);
  border-right: 1px solid var(--color5);
  margin: 0.5rem;
}
/**/
/*--DAY SELECTED START/END */
._view_flat__2i9i0 div[class*="--selected-range-start"][aria-selected="true"],
._view_flat__2i9i0
  div[class*="--selected-range-end"][class*="--hovered-range-start"][aria-selected="true"] {
  border-radius: 99px 8px 8px 99px !important;
  background-color: var(--color0);
  box-shadow: inset 0 0 0 2px var(--color60);
}
._view_flat__2i9i0 div[class*="--selected-range-end"][aria-selected="true"],
._view_flat__2i9i0
  div[class*="--selected-range-start"][class*="--hovered-range-end"][aria-selected="true"] {
  border-radius: 8px 99px 99px 8px !important;
  background-color: var(--color0);
  box-shadow: inset 0 0 0 2px var(--color60);
}
._view_flat__2i9i0 div[class*="--selected-range-start"][aria-selected="true"]:hover,
._view_flat__2i9i0 div[class*="--selected-range-end"][aria-selected="true"]:hover {
  background-color: var(--color5);
}

._view_flat__2i9i0 div [class*="--selected-range-start"][class*="--selected-range-end"],
._view_flat__2i9i0
  div
  [class*="--selected-range-start"][class*="--selected-range-end"]:hover {
  border-radius: 50% !important;
  background-color: var(--color0);
  color: var(--color100);
}
._view_flat__2i9i0 div[class*="--hovered-range-start"]:not([class*="--selected"]) {
  border-radius: 99px 8px 8px 99px !important;
  box-shadow: inset 0 0 0 1px var(--color30);
  background-color: var(--color5);
}
._view_flat__2i9i0 div[class*="--hovered-range-end"]:not([class*="--selected"]) {
  border-radius: 8px 99px 99px 8px !important;
  box-shadow: inset 0 0 0 1px var(--color30);
  background-color: var(--color5);
}

._view_flat__2i9i0 div[class*="--selected-range"][aria-selected="false"] {
  background-color: var(--color5);
}
._view_flat__2i9i0 div[class*="--selected-range"][aria-selected="false"]:hover {
  background-color: var(--color10);
}
._view_flat__2i9i0
  div[class*="--hovered-range"][aria-selected="false"]:not([class*="range-start"]):not([class*="range-end"]) {
  background-color: var(--color5) !important;
}
._view_flat__2i9i0 div[class*="DayPicker"][aria-selected="false"]:focus {
  background: transparent;
  color: var(--color100);
}
/**/

/*shortcut*/
._view_flat__2i9i0 ul[class*="daterangepicker-shortcuts"] a[class*="menu-item"] {
  color: var(--color70);
}
._view_flat__2i9i0
  ul[class*="daterangepicker-shortcuts"]
  a[class*="menu-item"][class*="active"],
._view_flat__2i9i0 ul[class*="daterangepicker-shortcuts"] a[class*="menu-item"]:hover {
  background: var(--color5);
}
._view_flat__2i9i0
  ul[class*="daterangepicker-shortcuts"]
  a[class*="menu-item"][class*="active"] {
  font-weight: 500;
}
/*end*/

/*navbar*/
._view_flat__2i9i0 div[class*="datepicker-navbar"] > button {
  width: 2.5rem;
  border-radius: 50%;
  color: var(--color70);
}
._view_flat__2i9i0 div[class*="datepicker-navbar"] > button:hover {
  color: var(--color100);
  box-shadow: var(--shadow2dp);
}
/*end*/

/*month selector*/
._view_flat__2i9i0 div[class*="datepicker-month-select"] > select,
._view_flat__2i9i0 div[class*="datepicker-year-select"] > select {
  color: var(--color70);
}
._view_flat__2i9i0 div[class*="datepicker-month-select"] > select:hover,
._view_flat__2i9i0 div[class*="datepicker-year-select"] > select:hover {
  background: var(--color5);
  color: var(--color100);
}
._view_flat__2i9i0 div[class*="datepicker-month-select"] > span[class*="icon"],
._view_flat__2i9i0 div[class*="datepicker-year-select"] > span[class*="icon"] {
  color: var(--color70);
}
/*end*/

/*footer*/
._view_flat__2i9i0 div[class*="datepicker-footer"] > button:hover {
  background: var(--color5);
}
/*end*/

._view_flat__2i9i0 div[class*="DayPicker-Day--outside"] > div[class*="day-wrapper"] {
  color: var(--color40);
}
._view_flat__2i9i0 div[class*="DayPicker-Day"]:hover {
  background: var(--color0);
}
/*END STYLE*/

/*SMOOTH STYLE*/
._view_smooth__Yn4oc {
  color: var(--color100);
  background: var(--color0);
}
._view_smooth__Yn4oc div[class="DayPicker-Day"] > div[class*="day-wrapper"] {
  border: none !important;
  padding: unset;
}
/*--DAY SELECTED START/END */
._view_smooth__Yn4oc div[class*="--selected-range-start"][aria-selected="true"],
._view_smooth__Yn4oc
  div[class*="--selected-range-end"][class*="--hovered-range-start"][aria-selected="true"] {
  border-radius: 99px 8px 8px 99px !important;
  background-color: var(--color60);
}
._view_smooth__Yn4oc div[class*="--selected-range-end"][aria-selected="true"],
._view_smooth__Yn4oc
  div[class*="--selected-range-start"][class*="--hovered-range-end"][aria-selected="true"] {
  border-radius: 8px 99px 99px 8px !important;
  background-color: var(--color60);
}
._view_smooth__Yn4oc div[class*="--selected-range-start"][aria-selected="true"]:hover,
._view_smooth__Yn4oc div[class*="--selected-range-end"][aria-selected="true"]:hover {
  background-color: var(--color70);
}

._view_smooth__Yn4oc div [class*="--selected-range-start"][class*="--selected-range-end"],
._view_smooth__Yn4oc
  div
  [class*="--selected-range-start"][class*="--selected-range-end"]:hover {
  border-radius: 50% !important;
  background-color: var(--color60);
  color: var(--color0);
}
._view_smooth__Yn4oc div[class*="--hovered-range-start"]:not([class*="--selected"]) {
  border-radius: 99px 8px 8px 99px !important;
  box-shadow: inset 0 0 0 1px var(--color30);
  background-color: var(--color5);
}
._view_smooth__Yn4oc div[class*="--hovered-range-end"]:not([class*="--selected"]) {
  border-radius: 8px 99px 99px 8px !important;
  box-shadow: inset 0 0 0 1px var(--color30);
  background-color: var(--color5);
}

._view_smooth__Yn4oc div[class*="--selected-range"][aria-selected="false"] {
  background-color: var(--color10);
}
._view_smooth__Yn4oc div[class*="--selected-range"][aria-selected="false"]:hover {
  background-color: var(--color20);
}
._view_smooth__Yn4oc
  div[class*="--hovered-range"][aria-selected="false"]:not([class*="range-start"]):not([class*="range-end"]) {
  background-color: var(--color10) !important;
}
._view_smooth__Yn4oc div[class*="DayPicker"][aria-selected="false"]:focus {
  background: transparent;
  color: var(--color100);
}
/**/

/*shortcut*/
._view_smooth__Yn4oc ul[class*="daterangepicker-shortcuts"] {
  background: var(--color0);
}
._view_smooth__Yn4oc ul[class*="daterangepicker-shortcuts"] a[class*="menu-item"] {
  color: var(--color70);
}
._view_smooth__Yn4oc
  ul[class*="daterangepicker-shortcuts"]
  a[class*="menu-item"][class*="active"],
._view_smooth__Yn4oc ul[class*="daterangepicker-shortcuts"] a[class*="menu-item"]:hover {
  background: var(--color0);
  box-shadow: 0 0 0 1px var(--color30);
}
._view_smooth__Yn4oc
  ul[class*="daterangepicker-shortcuts"]
  a[class*="menu-item"][class*="active"] {
  font-weight: 500;
}
/*end*/
/*--DIVIDER*/
._view_smooth__Yn4oc div[class*="divider"] {
  border-bottom: 1px solid var(--color10);
  border-right: 1px solid var(--color10);
  margin: 0.5rem;
}
/**/
/*navbar*/
._view_smooth__Yn4oc div[class*="datepicker-navbar"] > button {
  width: 2.5rem;
  border-radius: 50%;
  color: var(--color70);
}
._view_smooth__Yn4oc div[class*="datepicker-navbar"] > button:hover {
  color: var(--color100);
  box-shadow: var(--shadow2dp);
}

/*end*/

/*month selector*/
._view_smooth__Yn4oc div[class*="datepicker-month-select"] > select,
._view_smooth__Yn4oc div[class*="datepicker-year-select"] > select {
  color: var(--color70);
}
._view_smooth__Yn4oc div[class*="datepicker-month-select"] > select:hover,
._view_smooth__Yn4oc div[class*="datepicker-year-select"] > select:hover {
  background: var(--color5);
  color: var(--color100);
}
._view_smooth__Yn4oc div[class*="datepicker-month-select"] > span[class*="icon"],
._view_smooth__Yn4oc div[class*="datepicker-year-select"] > span[class*="icon"] {
  color: var(--color70);
}
/*end*/

/*footer*/
._view_smooth__Yn4oc div[class*="datepicker-footer"] > button:hover {
  background: var(--color5);
}
/*end*/

._view_smooth__Yn4oc div[class*="DayPicker-Day--outside"] > div[class*="day-wrapper"] {
  color: var(--color40);
}
._view_smooth__Yn4oc div[class*="DayPicker-Day"]:hover {
  background: var(--color0);
}
/* END STYLE */

/* RAISED STYLE */
._view_raised__sxTBt {
  color: var(--color100);
  background: var(--white);
  box-shadow: var(--shadow16dp);
}
._view_raised__sxTBt div[class*="DayPicker-Day"] > div[class*="day-wrapper"] {
  border: none !important;
  padding: unset;
}
._view_raised__sxTBt div[class*="DayPicker-Day"][class*="DayPicker-Day--selected"],
._view_raised__sxTBt div[class*="DayPicker-Day"][class*="DayPicker-Day--selected"]:hover {
  background: var(--color5);
  color: var(--color100);
  border-radius: 0;
}

/*--DIVIDER*/
._view_raised__sxTBt div[class*="divider"] {
  border-bottom: 1px solid var(--color5);
  border-right: 1px solid var(--color5);
  margin: 0.5rem;
}
/**/
/*--DAY SELECTED START/END */
._view_raised__sxTBt div[class*="--selected-range-start"][aria-selected="true"],
._view_raised__sxTBt
  div[class*="--selected-range-end"][class*="--hovered-range-start"][aria-selected="true"] {
  border-radius: 99px 8px 8px 99px !important;
  background-color: var(--color0);
  box-shadow: inset 0 0 0 2px var(--color60);
}
._view_raised__sxTBt div[class*="--selected-range-end"][aria-selected="true"],
._view_raised__sxTBt
  div[class*="--selected-range-start"][class*="--hovered-range-end"][aria-selected="true"] {
  border-radius: 8px 99px 99px 8px !important;
  background-color: var(--color0);
  box-shadow: inset 0 0 0 2px var(--color60);
}
._view_raised__sxTBt div[class*="--selected-range-start"][aria-selected="true"]:hover,
._view_raised__sxTBt div[class*="--selected-range-end"][aria-selected="true"]:hover {
  background-color: var(--color5);
}

._view_raised__sxTBt div [class*="--selected-range-start"][class*="--selected-range-end"],
._view_raised__sxTBt
  div
  [class*="--selected-range-start"][class*="--selected-range-end"]:hover {
  border-radius: 50% !important;
  background-color: var(--color0);
  color: var(--color100);
}
._view_raised__sxTBt div[class*="--hovered-range-start"]:not([class*="--selected"]) {
  border-radius: 99px 8px 8px 99px !important;
  box-shadow: inset 0 0 0 1px var(--color30);
  background-color: var(--color0);
}
._view_raised__sxTBt div[class*="--hovered-range-end"]:not([class*="--selected"]) {
  border-radius: 8px 99px 99px 8px !important;
  box-shadow: inset 0 0 0 1px var(--color30);
  background-color: var(--color0);
}

._view_raised__sxTBt div[class*="--selected-range"][aria-selected="false"] {
  background-color: var(--color5);
}
._view_raised__sxTBt div[class*="--selected-range"][aria-selected="false"]:hover {
  background-color: var(--color10);
}
._view_raised__sxTBt
  div[class*="--hovered-range"][aria-selected="false"]:not([class*="range-start"]):not([class*="range-end"]) {
  background-color: var(--color5) !important;
}
._view_raised__sxTBt div[class*="DayPicker"][aria-selected="false"]:focus {
  background: transparent;
  color: var(--color100);
}
/**/

/*shortcut*/
._view_raised__sxTBt ul[class*="daterangepicker-shortcuts"] a[class*="menu-item"] {
  color: var(--color70);
}
._view_raised__sxTBt
  ul[class*="daterangepicker-shortcuts"]
  a[class*="menu-item"][class*="active"],
._view_raised__sxTBt ul[class*="daterangepicker-shortcuts"] a[class*="menu-item"]:hover {
  background: var(--color0);
  box-shadow: var(--shadow4dp);
}
._view_raised__sxTBt
  ul[class*="daterangepicker-shortcuts"]
  a[class*="menu-item"][class*="active"] {
  font-weight: 500;
}
/*end*/

/*navbar*/
._view_raised__sxTBt div[class*="datepicker-navbar"] > button {
  width: 2.5rem;
  border-radius: 50%;
  color: var(--color70);
}
._view_raised__sxTBt div[class*="datepicker-navbar"] > button:hover {
  color: var(--color100);
  box-shadow: var(--shadow2dp);
}
/*end*/

/*month selector*/
._view_raised__sxTBt div[class*="datepicker-month-select"] > select,
._view_raised__sxTBt div[class*="datepicker-year-select"] > select {
  color: var(--color70);
}
._view_raised__sxTBt div[class*="datepicker-month-select"] > select:hover,
._view_raised__sxTBt div[class*="datepicker-year-select"] > select:hover {
  background: var(--color5);
  color: var(--color100);
}
._view_raised__sxTBt div[class*="datepicker-month-select"] > span[class*="icon"],
._view_raised__sxTBt div[class*="datepicker-year-select"] > span[class*="icon"] {
  color: var(--color70);
}
/*end*/

/*footer*/
._view_raised__sxTBt div[class*="datepicker-footer"] > button:hover {
  background: var(--color0);
  box-shadow: var(--shadow4dp);
}
/*end*/

._view_raised__sxTBt div[class*="DayPicker-Day--outside"] > div[class*="day-wrapper"] {
  color: var(--color40);
}
._view_raised__sxTBt div[class*="DayPicker-Day"]:hover {
  background: var(--color0);
}
/* END STYLE */

._type_dropdown__3zVUd,
._type_dropdown--dense__1INBN,
._type_dropdown__3zVUd:after,
._type_dropdown--dense__1INBN::after {
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

._type_dropdown__3zVUd {
  height: 2.5rem;
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
}
._type_dropdown--dense__1INBN {
  height: 1.75rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: var(--font_size_body_small);
}

._type_dropdown--dense__1INBN::after,
._type_dropdown__3zVUd::after {
  display: inline-block;
  margin-left: 1rem;
  vertical-align: 0.1em;
  content: "";
  border-top: 0.4em solid;
  border-right: 0.35em solid transparent;
  border-bottom: 0;
  border-left: 0.35em solid transparent;
  border-radius: 0.125rem;
}

._type_dropdown__3zVUd:hover,
._type_dropdown--dense__1INBN:hover {
  cursor: pointer;
}

._view_popover__36AhB[class*="minimal"] {
  box-shadow: none;
  margin-top: 4px !important;
  background: transparent;
}
._view_popover__36AhB > div[class*="popover-content"] {
  background: transparent;
}
/*CARET-DOWN*/
._view_outlined__1Gy0N::after,
._view_raised__QMtDk::after,
._view_smooth__1gJmN::after {
  color: var(--color40);
}
._view_outlined__1Gy0N:hover::after,
._view_raised__QMtDk:hover::after,
._view_smooth__1gJmN:hover::after {
  color: var(--color60);
}
._view_outlined__1Gy0N:active::after,
._view_outlined__1Gy0N:focus::after,
._view_raised__QMtDk:active::after,
._view_raised__QMtDk:focus::after,
._view_smooth__1gJmN:active::after,
._view_smooth__1gJmN:focus::after {
  color: var(--color90);
}
._view_filled__LQMuv::after {
  color: var(--color10);
}
._view_filled__LQMuv:hover::after {
  color: var(--color5);
}
._view_filled__LQMuv:active::after,
._view_filled__LQMuv:focus::after {
  color: var(--color0);
}
/**/

/*OUTLINED DROPDOWN*/
._view_outlined__1Gy0N {
  margin: 2px;
  border: none;
  color: var(--color100);
  background: var(--white);
  box-shadow: 0 0 0 1px var(--color30);
}
._view_outlined__1Gy0N:hover {
  box-shadow: 0 0 0 1px var(--color80);
}
._view_outlined__1Gy0N:active,
._view_outlined__1Gy0N:focus {
  box-shadow: 0 0 0 2px var(--color80);
}

/* RAISED DROPDOWN*/
._view_raised__QMtDk {
  border: none;
  color: var(--color100);
  background: var(--white);
  box-shadow: var(--shadow2dp);
}
._view_raised__QMtDk:hover {
  box-shadow: var(--shadow4dp);
}
._view_raised__QMtDk:active,
._view_raised__QMtDk:focus {
  box-shadow: var(--shadow8dp);
}

/* SMOOTH DROPDOWN*/
._view_smooth__1gJmN {
  border: none;
  color: var(--color100);
  background: var(--color5);
}
._view_smooth__1gJmN:hover {
  background: var(--color10);
}
._view_smooth__1gJmN:active,
._view_smooth__1gJmN:focus {
  background: var(--color20);
}

/* FILLED DROPDOWN*/
._view_filled__LQMuv {
  border: none;
  color: var(--color0);
  background: var(--color40);
}
._view_filled__LQMuv:hover {
  background: var(--color50);
}
._view_filled__LQMuv:active,
._view_filled__LQMuv:focus {
  background: var(--color70);
  box-shadow: var(--shadow8dp);
}

:root {
  /*VARIABLES*/
  --hide: 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0ms;
  --show: 250ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0ms;
}

.badge_container__3Rf88 {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  flex-shrink: 0;
}
.badge_wrapper__14BmE {
  min-width: var(--min-width);
  height: var(--height);
  box-sizing: border-box;
  padding: 0 0.375rem;
  border-radius: 99px;
  z-index: 1;
  transition: all 250ms ease-in-out, transform var(--animation);
}

/*POSITION*/
.badge_position__Lc57Q {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: absolute;
  justify-content: center;
  align-content: center;
  align-items: center;
  transform-origin: var(--originX) var(--originY);
  transform: scale(var(--scale)) translate(var(--translateX), var(--translateY));
}
.badge_right__1QA6f {
  --translateX: calc(var(--min-width) / 2);
  --originX: 100%;
  right: 0;
}
.badge_top__cCQGC {
  --translateY: -50%;
  --originY: 0%;
  top: 0;
}
.badge_left__1hW3X {
  --translateX: calc(var(--min-width) / -2);
  --originX: 0%;
  left: 0;
}
.badge_bottom__1KSmn {
  --translateY: 50%;
  --originY: 100%;
  bottom: 0;
}
/*position*/

/*VISIBILITY*/
.badge_show__3FD2e {
  --scale: 1;
  --animation: var(--show);
}
.badge_hide__19Dl3 {
  --scale: 0;
  --animation: var(--hide);
}
/*visibility*/

/*TYPES*/
.badge_default__3QXnu {
  --min-width: 1.25rem;
  --height: var(--min-width);
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
}
.badge_dense__1rMcf {
  --min-width: 1rem;
  --height: 0.875rem;
  font-size: var(--font_size_caption_small);
  letter-spacing: var(--letter_spacing_caption_small);
}
.badge_dot__3dPjV {
  --min-width: 0.875rem;
  --height: var(--min-width);
}
/*types*/

.view_filled__2Bp28 {
  background-color: var(--color50);
  box-shadow: var(--shadow8dp);
  color: var(--color0);
}

.view_smooth__208e_ {
  background-color: var(--color20);
  box-shadow: var(--shadow8dp);
  color: var(--color90);
}

.view_outlined__2wxMV {
  background-color: var(--color0);
  box-shadow: var(--shadow8dp);
  color: var(--color70);
  border: 1px solid var(--color40);
}

.style_container__3NNk9 {
  padding-right: 1rem;
  padding-left: 1rem;
}

.style_sub_title__21llz {
  padding-top: 0.5rem;
}
.style_text__3y9CO {
  padding-top: 1rem;
}

.style_main_colors__M_kv1 {
  padding: 2.5rem 0;
  display: flex;
  justify-content: center;
}

.style_action_colors__FHq_h {
  padding: 2.5rem 0;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
}

.style_alt_colors__1nGWZ {
  padding: 2.5rem 0;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
}
.style_space_between__2CsD9 {
  min-width: 0.5rem;
}
.style_alt_colors__1nGWZ > .style_space_between__2CsD9,
.style_action_colors__FHq_h > .style_space_between__2CsD9 {
  display: none;
}

.style_colors_array__1kpz4 {
  width: 100%;
}
.style_colors_array__1kpz4.style_full_space__3oLNR {
  width: calc(200% + 0.5rem);
}
.style_color_item__wIwVf {
  display: flex;
  height: 4rem;
  line-height: 4rem;
  padding-left: 0.75rem;
  padding-right: 1rem;
  transition: all 0.1s cubic-bezier(0.4, 1, 0.75, 0.9);
}

section > div:first-child {
  border-radius: 0.5rem 0.5rem 0 0;
}
section > div:last-child {
  border-radius: 0 0 0.5rem 0.5rem;
}

.style_color_name__2wuZq[class*="body"] {
  text-transform: capitalize;
  line-height: inherit;
}
.style_color_name__2wuZq:after {
  content: attr(data-color);
}
.style_color_hex__3bCf5 {
  display: none;
  line-height: inherit;
}

@media screen and (min-width: 640px) {
  .style_main_colors__M_kv1,
  .style_action_colors__FHq_h,
  .style_alt_colors__1nGWZ {
    padding: 2.5rem 0.5rem;
  }
  .style_space_between__2CsD9 {
    min-width: 1rem;
  }
  .style_color_item__wIwVf:hover {
    border-radius: 0.5rem;
    box-shadow: var(--shadow4dp);
    transform: scale(1.02, 1.02);
    transition: all 0.1s cubic-bezier(0.4, 1, 0.75, 0.9);
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }
  .style_color_item__wIwVf:hover > .style_color_name__2wuZq:after {
    content: "Click to copy hex code";
    text-transform: none;
    transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
  }
  .style_action_colors__FHq_h > .style_space_between__2CsD9 {
    display: inline;
    display: initial;
  }
  .style_action_colors__FHq_h {
    display: flex;
  }
  .style_colors_array__1kpz4.style_full_space__3oLNR {
    width: 100%;
  }
  .style_color_hex__3bCf5 {
    display: inline;
    display: initial;
    float: right;
    text-transform: uppercase;
    margin-left: auto;
  }
  .style_action_colors__FHq_h .style_color_hex__3bCf5,
  .style_alt_colors__1nGWZ .style_color_hex__3bCf5 {
    display: none;
  }
}

@media screen and (min-width: 1025px) {
  .style_space_between__2CsD9 {
    min-width: 2.5rem;
  }
  .style_alt_colors__1nGWZ > .style_space_between__2CsD9,
  .style_action_colors__FHq_h > .style_space_between__2CsD9 {
    display: inline;
    display: initial;
  }
  .style_alt_colors__1nGWZ > .style_space_between__2CsD9 {
    min-width: 1rem;
  }
  .style_action_colors__FHq_h > .style_space_between__2CsD9 {
    min-width: 1.5rem;
  }
  .style_main_colors__M_kv1,
  .style_action_colors__FHq_h,
  .style_alt_colors__1nGWZ {
    padding: 2.5rem 1.5rem;
  }
  .style_color_item__wIwVf:hover {
    transform: scale(1.06, 1.02);
  }

  .style_alt_colors__1nGWZ,
  .style_action_colors__FHq_h {
    display: flex;
  }
  .style_colors_array__1kpz4.style_full_space__3oLNR {
    width: 100%;
  }
}

@media screen and (min-width: 1380px) {
  .style_action_colors__FHq_h .style_color_hex__3bCf5,
  .style_alt_colors__1nGWZ .style_color_hex__3bCf5 {
    display: inline;
    display: initial;
  }
}

.style_container__3JDs4 {
  padding: 0 1rem;
}

.style_section_title__NrMF_ {
  padding-top: var(--s40);
  padding-bottom: 1rem;
  text-transform: capitalize;
}
.style_text__3qvNu {
  padding-top: 1rem;
}

.style_icons_section__1hM9q {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
}
.style_icon_item__2lWAh {
  height: 10rem;
  display: grid;
  color: var(--grey40);
  background: var(--grey0);
  border-radius: 0.5rem;
}
.style_icon_svg__1CaH0 {
  color: var(--color70);
  padding-bottom: 1rem;
  text-align: center;
}
.style_icon_name__1SmrQ {
  text-align: center;
  margin-top: auto;
}
.style_icon_item_container__HcL7U {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: auto;
  margin-bottom: auto;
}
.style_icon_name__1SmrQ:after {
  content: attr(data-name);
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}

@media screen and (min-width: 640px) {
  .style_icons_section__1hM9q {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
  }
  .style_icon_name__1SmrQ {
    margin-bottom: 1rem;
  }
  .style_icon_svg__1CaH0 {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: auto;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .style_icon_item_container__HcL7U {
    height: 100%;
    display: grid;
  }
  .style_icon_item__2lWAh:hover {
    transform: scale(1.06, 1.04);
    box-shadow: var(--greyShadow4dp);
    cursor: pointer;
    transition: all 0.1s cubic-bezier(0.4, 1, 0.75, 0.9);
  }
  .style_icon_item__2lWAh {
    transition: all 0.1s cubic-bezier(0.4, 1, 0.75, 0.9);
  }

  .style_icon_item__2lWAh:hover .style_icon_name__1SmrQ:after {
    content: "Click to copy";
    color: var(--grey60);
  }
}

@media screen and (min-width: 1024px) {
  .style_icons_section__1hM9q {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
  }
}

@media screen and (min-width: 1440px) {
  .style_icons_section__1hM9q {
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
  }
}

.style_container__3Pk3t {
  padding-right: 1rem;
  padding-left: 1rem;
}
.style_sub_title__3M42g {
  padding-top: 1rem;
}
.style_h4_title__RVmZe {
  padding-top: 1rem;
}
.style_h6_title__2yxXy {
  padding-top: 1.5rem;
}
.style_text__13Xdt {
  padding-top: 0.5rem;
}
.style_link__f4yvG,
.style_link__f4yvG:active {
  color: var(--blue60);
  margin: 0 0.25rem;
  text-decoration: underline;
}
.style_link__f4yvG:hover {
  color: var(--blue70);
}
.style_promo_group__3oCpn {
  display: flex;
  flex-direction: column;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.style_promo_item__2mek- {
  width: 100%;
  padding-bottom: 1.5rem;
}
.style_item_img_container__2zfbI {
  border-radius: 0.5rem;
  padding: 0.25rem;
  border: 1px solid;
}
.style_item_img__2zTIB {
  width: 100%;
}

@media screen and (min-width: 640px) {
  .style_promo_group__3oCpn {
    display: flex;
    flex-direction: initial;
    justify-content: space-between;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .style_promo_item__2mek- {
    width: calc(25% - 0.75rem);
    padding-bottom: 1.5rem;
  }
}

.style_container__35C2x {
  padding-right: 1rem;
  padding-left: 1rem;
}
.style_sub_title__1rM33 {
  padding-top: 1rem;
}
.style_h4_title__2hu_e {
  padding-top: 1rem;
}
.style_h6_title__3L8DM {
  padding-top: 1.5rem;
}
.style_text__39NvZ {
  padding-top: 0.5rem;
}
.style_text_warning__1ykow {
  color: var(--yellow80);
  font-weight: var(--font_weight_body_medium);
}
.style_text_command__success__3ehBc {
  color: var(--green100);
  background: var(--green5);
  border-radius: 0.25rem;
  height: 2.5rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 0.75rem;
  line-height: 2.5rem;
  margin-top: 0.5rem;
}
.style_text_command__warning__2gd9S {
  color: var(--yellow100);
  background: var(--yellow5);
  border-radius: 0.25rem;
  height: 2.5rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 0.75rem;
  line-height: 2.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}
.style_link__3VPXU,
.style_link__3VPXU:active {
  color: var(--blue60);
  margin: 0 0.25rem;
  text-decoration: underline;
}
.style_link__3VPXU:hover {
  color: var(--blue70);
}

.style_container__2Cvdi {
  padding-right: 1rem;
  padding-left: 1rem;
}
.style_sub_title__E-TAu {
  padding-top: 1rem;
}
.style_sub_title__item__2izU9 {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}
.style_h4_title__FAkNa {
  padding-top: 1rem;
}
.style_h6_title__3X1qe {
  padding-top: 1rem;
}
.style_text__2-tP3 {
  padding-top: 0.5rem;
}
.style_link__31Cj9,
.style_link__31Cj9:active {
  color: var(--blue60);
  margin: 0 0.25rem;
  text-decoration: underline;
}
.style_link__31Cj9:hover {
  color: var(--blue70);
}
.style_promo_group__1wgFW {
  display: flex;
  flex-direction: column;
}
.style_promo_item__r_q65 {
  width: 100%;
}
.style_item_img__4PCBd {
  width: 100%;
}
.style_item_img_container__3yzPR {
  margin-top: auto;
  margin-bottom: 0;
  border-radius: 0.5rem;
  border: 1px solid;
  padding: 0.25rem;
}
.style_text_warning__dwn-b {
  color: var(--yellow80);
  font-weight: var(--font_weight_body_medium);
}

@media screen and (min-width: 640px) {
  .style_promo_group__1wgFW {
    display: flex;
    flex-direction: initial;
    justify-content: space-between;
  }
  .style_promo_item__r_q65 {
    width: calc(50% - 0.75rem);
    display: flex;
    flex-direction: column;
  }
  .style_h6_title__3X1qe {
    padding-top: 1.5rem;
  }
}

.style_container__2mkwM {
  padding-right: 1rem;
  padding-left: 1rem;
}
.style_sub_title__L_H0I {
  padding-top: 1rem;
}
.style_h4_title__2NiXm {
  padding-top: 1rem;
}
.style_h6_title__1dNto {
  padding-top: 1.5rem;
}
.style_text__3wxAi {
  padding-top: 0.5rem;
}
.style_text_warning__sA_vo {
  color: var(--yellow80);
  font-weight: var(--font_weight_body_medium);
}
.style_text_primary__2G-se {
  color: var(--blue80);
  font-weight: var(--font_weight_body_medium);
}
.style_text_command__code__otYF1 {
  color: var(--blue100);
  background: var(--blue5);
  border-radius: 0.25rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 0.75rem;
  line-height: 2.5rem;
  margin-top: 0.5rem;
}
.style_text_command__success__xEkSw {
  color: var(--green100);
  background: var(--green5);
  border-radius: 0.25rem;
  /*height: 2.5rem;*/
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 0.75rem;
  line-height: 2.5rem;
  margin-top: 0.5rem;
}
.style_text_command__warning__1zQ1R {
  color: var(--yellow100);
  background: var(--yellow5);
  border-radius: 0.25rem;
  /* height: 2.5rem;*/
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 0.75rem;
  line-height: 2.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}
.style_link__12uCF,
.style_link__12uCF:active {
  color: var(--blue60);
  margin: 0 0.25rem;
  text-decoration: underline;
}
.style_link__12uCF:hover {
  color: var(--blue70);
}

.style_container__3fK_n {
  padding-right: 1rem;
  padding-left: 1rem;
}
.style_sub_title__1u6U7 {
  padding-top: 1rem;
}
.style_h4_title__BgLs6 {
  padding-top: 1rem;
}
.style_h6_title__3EQfj {
  padding-top: 1.5rem;
}
.style_text__6Pkh- {
  padding-top: 0.5rem;
}
.style_link__3c8QE,
.style_link__3c8QE:active {
  color: var(--blue60);
  margin: 0 0.25rem;
  text-decoration: underline;
}
.style_link__3c8QE:hover {
  color: var(--blue70);
}
.style_promo_group__1rK6f {
  display: flex;
  flex-direction: column;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.style_promo_item__3KXc4 {
  width: 100%;
  padding-bottom: 1.5rem;
}
.style_item_img_container__3fhEf {
  border-radius: 0.5rem;
  padding: 0.25rem;
  /* border: 1px solid; */
}
.style_item_img__2QzoK {
  width: 100%;
}

@media screen and (min-width: 640px) {
  .style_promo_group__1rK6f {
    display: flex;
    flex-direction: initial;
    justify-content: space-between;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .style_promo_item__3KXc4 {
    width: calc(25% - 0.75rem);
    padding-bottom: 1.5rem;
  }
}

.style_layout__2NPik {
  display: flex;
  background: transparent;
  border: 1px solid var(--grey20);
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 1rem;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.style_grid_item__czLl0 {
  display: flex;
  flex-direction: column;
  /* padding: 40px 0; */
  align-items: center;
  border-radius: 1rem;
  background: transparent;
}
.style_grid_item__czLl0:hover {
  cursor: pointer;
  background: var(--color10);
  transition: all 0.25s ease-in-out;
}

.style_item__icon__2bPAH {
  width: 6.5em;
  height: 6.5em;
  background: var(--color50);
  border-radius: 1rem;
  color: white;
  justify-content: center;
  display: flex;
  align-items: center;
}
.style_item__title__3rsT7 {
  text-align: center;
  margin-top: 1.5rem;
  white-space: pre-wrap;
}

@media (min-width: 540px) {
  .style_layout__2NPik {
    justify-content: center;
  }
}

.style_container__1YsvF {
  margin: 2.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.style_cards_row__2mJ24,
.style_cards_row_last__2Vnd4 {
  display: flex;
  flex: 1 1 auto;
  margin: 1rem;
}
.style_cards_row_last__2Vnd4 {
  justify-content: space-between;
}
.style_cards_row_last__2Vnd4 > div:not(:last-child) {
  margin-right: 1rem;
}

.style_item__icon__1Bd5B {
  color: var(--color40);
  justify-content: flex-start;
  display: flex;
  margin-bottom: 1rem;
}
.style_card_row_add__JVjks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* max-width: 290px; */
  margin: 1rem 3rem;
}
.style_card_row_add__JVjks > div {
  margin-bottom: 0.5rem;
}
.style_dialog__5cSTo div[class*="footer"] {
  flex-direction: column;
}
.style_dialog__5cSTo div[class*="footer"] button {
  width: 100%;
}
.style_dialog__5cSTo div[class*="right-button"] {
  margin-top: 1.5rem;
}

@media (min-width: 640px) {
  .style_cards_row__2mJ24 > div:not(:last-child) {
    margin-right: 1rem;
  }
}

.style_container__1ihlp {
  margin: 2.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.style_up__v8Iuv {
  color: var(--green70);
}
.style_down__3B0K9 {
  color: var(--red70);
}
.style_cards_row__16ijf {
  display: flex;
  margin: 1rem;
}
.style_cards_row__16ijf > div {
  flex: 1 1 auto;
}
.style_item__icon__2pjLH {
  color: var(--color40);
  justify-content: flex-start;
  display: flex;
}
.style_card_row_add__2pvwT {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* max-width: 290px; */
  margin: 1rem 3rem;
}
.style_card_row_add__2pvwT > div {
  margin-bottom: 0.5rem;
}

.style_container__23254 {
  display: flex;
  width: 100%;
}

.style_wrapper__3xOJt {
  height: 400px;
  margin-bottom: 2rem; /*mobile*/
}
.style_wrapper__3xOJt svg > g:hover {
  cursor: pointer;
}
.style_table_wrapper__2XzHy {
  width: calc(100% - 1rem);
  margin: auto;
  padding: 0 0.5rem;
}
.style_table_wrapper__2XzHy > div {
  width: calc(100%);
  overflow-y: hidden;
}
.style_table_wrapper__2XzHy > div > table {
  overflow-x: scroll;
}

.style_legend__2KZ_J {
  justify-content: space-around;
  flex-direction: column;
}
.style_legend__2KZ_J,
.style_legend__2KZ_J > div {
  display: flex;
  align-items: center;
}
.style_legend__2KZ_J > div {
  padding: 0.75rem;
}
.style_legend__2KZ_J > div > div {
  margin-right: 20px;
}
.style_legend__2KZ_J .style_dot__3vxZd {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 0.5rem;
}

div[class*="google-visualization-tooltip"] {
  border: none;
  box-shadow: none;
  background: transparent;
  animation: style_fade-in-bck__2sH6T 0.25s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  height: 1px;
  width: 1px;
  /* position: relative; */
}
.style_tooltip__3WPxI {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: var(--shadow);
  border-radius: 1rem;
  padding: 0.75rem 1rem;
  margin: -1rem;
  background: var(--bg);
  color: var(--color);
  overflow: hidden;
  position: relative;
  /* top: 36px; */
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.style_tooltip__3WPxI > .style_label__Zo-mI {
  font-weight: normal;
  font-size: 20px;
  color: var(--grey100);
  letter-spacing: 0.0075em;
}
.style_tooltip__3WPxI > .style_value__3UZ1c {
  font-size: 34px;
  font-family: Inter;
  font-weight: bold;
  line-height: 128%;
}

@keyframes style_fade-in-bck__2sH6T {
  0% {
    transform: translateZ(80px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0);
    opacity: 1;
  }
}

@media (min-width: 640px) {
  .style_table_wrapper__2XzHy {
    padding: 0 1rem;
  }
}

.style_container__2xPfo {
  padding: 1rem;
  margin: 1rem;
  border-radius: 0.5rem;
  border: 1px solid var(--grey20);
}

.style_caption_wrapper__3dXnR {
  display: flex;
  justify-content: space-between;
}

.style_caption_dropdown__2baEX {
  display: none;
}

div[class*="google-visualization-tooltip"] {
  border: none;
  box-shadow: none;
  background: transparent;
  animation: style_fade-in-bck__1TWCA 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
.style_tooltip__3HJUe {
  display: none;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-shadow: var(--shadow);
  border-radius: 1rem;
  padding: 0.75rem 1rem;
  margin: -1rem;
  background: var(--bg);
  color: var(--color);
  overflow: hidden;
}
.style_tooltip__3HJUe > .style_value__R4Kpz {
  font-size: 34px;
  font-family: Inter;
  font-weight: bold;
  line-height: 128%;
}
.style_tooltip__3HJUe > .style_label__33siz {
  font-weight: normal;
  font-size: 20px;
  color: var(--grey100);
  letter-spacing: 0.0075em;
}
.style_tooltip__3HJUe > .style_year__3pYT4 {
  font-weight: normal;
  font-size: 1rem;
  letter-spacing: 0.0275em;
  color: var(--grey60);
}
@keyframes style_fade-in-bck__1TWCA {
  0% {
    transform: translateZ(80px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0);
    opacity: 1;
  }
}
@media (min-width: 768px) {
  .style_caption_dropdown__2baEX {
    display: block;
  }
  .style_tooltip__3HJUe {
    display: flex;
  }
}

.style_container__2N284 {
  width: 100%;
  max-width: 90vw;
  overflow-x: auto;
  box-shadow: none !important;
}

@media (min-width: 640px) {
  .style_container__2N284 {
    max-width: none;
    max-width: initial;
  }
}

.style_container__9scey {
  background: var(--grey0);
  border-radius: 0.5rem;
  padding: 1rem 0;
}
.style_separator__1gn6q {
  border: 1px solid var(--grey10);
}
.style_data__AetPU {
  display: flex;
  justify-content: space-around;
  margin: 1rem 1rem 0 1rem;
}
.style_data__AetPU > .style_item__H_QBB {
  flex: 1 1 auto;
  margin-bottom: 0.5rem;
}

.style_container__3h7FA {
  display: flex;
  justify-content: space-between;
  margin: 3.5rem 0 1rem 0;
}

.style_container__3v0CD {
  width: 100%;
  overflow-x: auto;
  max-width: 90vw;
  margin: 1rem 0 5rem;
  box-shadow: none !important;
}
.style_container_title__tZqfk {
  display: flex;
  justify-content: space-between;
  margin-top: 2.5rem;
}

@media (min-width: 640px) {
  .style_container__3v0CD {
    max-width: none;
    max-width: initial;
  }
}

.style_wrapper__yAzQx {
  padding: 0 0.5rem;
  padding-bottom: 6rem;
}

.style_container__3g30y {
  display: flex;
  padding: 0 1rem;
  flex-direction: column;
}

.style_sidebar__LzLZY {
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  max-width: 100%;
}
.style_avatar__1_Wmw {
  width: 236px;
  max-width: 236px;
  height: 236px;
  position: relative;
  margin: auto;
}
.style_avatar__1_Wmw > .style_button__2Ub4- {
  position: absolute;
  bottom: 0;
  right: 0;
}
.style_desc__3I5ru {
  margin-top: 8px;
}

.style_buttons-group__2IlOE {
  margin-top: 1.5rem;
}

.style_divider__1gRk5 {
  height: 1px;
  background: var(--grey10);
  margin: 2rem 0;
}

.style_label__Z48vK {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.style_label__Z48vK > .style_icon__41HHB {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: var(--color5);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
}

.style_section__2-zN9 {
  display: flex;
  flex: auto;
  flex-direction: column;
}

.style_section__2-zN9 .style_ref_switches__2-sFa {
  display: flex;
  margin-top: 1.5rem;
  flex-direction: column;
}
.style_section__2-zN9 .style_ref_switches__2-sFa .style_column__3E-vx {
  margin-right: 1rem;
}
.style_section__2-zN9 .style_ref_switches__2-sFa div[class*="container"] {
  margin-bottom: 1rem;
}

@media screen and (min-width: 640px) {
  .style_container__3g30y {
    flex-direction: row;
  }
  .style_sidebar__LzLZY {
    max-width: 236px;
  }
  .style_avatar__1_Wmw {
    margin: 0;
  }
  .style_section__2-zN9 .style_ref_switches__2-sFa {
    flex-direction: row;
  }
  .style_section__2-zN9 .style_ref_switches__2-sFa .style_column__3E-vx {
    flex: auto;
  }
}

.style_container__1CQWZ {
  background: var(--grey0);
  padding: 24px;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.style_title__2h4KP {
  justify-content: space-between;
}
.style_dividerBold__1UCy1 {
  height: 2px;
  background: var(--grey10);
  width: 100%;
  margin: 2rem 0;
}
.style_divider__14YVP {
  height: 1px;
  background: var(--grey10);
  width: 100%;
  margin: 1.25rem 0;
}
.style_container__1CQWZ > div[class*="container"] {
  box-shadow: none;
}
.style_container__1CQWZ > div[class*="container"] > label[class*="control"] {
  margin-left: 0 !important;
}

.style_email_checkbox_secondline__2p2zU {
  display: none;
}
.style_chips__1Q7sz {
  margin-top: 1rem;
  width: 100% !important;
}
.style_title_wrapper__3V14_ {
  margin-bottom: 1rem;
}

@media screen and (min-width: 640px) {
  .style_title__2h4KP {
    display: flex;
  }
  .style_chips__1Q7sz {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
  }
  .style_title_wrapper__3V14_ {
    margin-bottom: 0;
  }
  div[class*="_type_helperText"] {
    margin-top: 2px;
  }
}

@media screen and (min-width: 768px) {
  .style_email_checkbox_secondline__2p2zU {
    color: var(--grey60);
    display: inline;
  }
}

.style_tablist__1ore9 {
  margin-bottom: 2px;
  overflow: auto;
}
.style_tablist__1ore9
  div[class*="bp3-tab-list"]
  > div[class="bp3-tab"][aria-expanded="false"] {
  color: var(--grey70);
}
.style_badge__3bNMK {
  display: flex;
  align-items: center;
  padding: 0 7px;
  background: var(--grey5);
  color: var(--grey100);
  border-radius: 99px;
  height: 15px;
  margin-right: 6px;
}
.style_tab_title__2Aelc {
  display: flex;
  align-items: center;
  align-content: center;
}

.style_tabs_wrapper__VXQ2j {
  display: grid;
  margin-top: 1rem;
}

@media screen and (min-width: 640px) {
  .style_tabs_wrapper__VXQ2j {
    margin-top: 0;
    display: block;
  }
}

.style_container__1UbUq {
  background: var(--grey0);
  border-radius: 0.5rem;
  padding: 40px 0;
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.style_wrapper__1n2HJ {
  width: 100vw;
  max-width: 448px;
  padding: 0 1.5rem;
}
.style_card__2OAUy {
  margin-top: 1.5rem;
  box-shadow: none;
  background: transparent;
}
.style_symbol_counter__1bpSR {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1.5rem;
}
.style_additional_line__2eq97 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.75rem;
}

.style_no_account_wraper__1rRTO {
  display: flex;
  align-items: center;
  margin: 1.5rem 0 0 0;
  flex-direction: column;
}
.style_no_account_label__2l1tq {
  display: flex;
  align-items: center;
  width: 100%;
}
.style_no_account_line__1x5ny {
  flex: auto;
  background: var(--grey10);
  height: 1px;
}
.style_no_account_text__N5N4j {
  margin: 0 0.5rem;
}
.style_social_buttons__5AyyW {
  display: flex;
  margin: 1.5rem 0 0 0;
  justify-content: space-evenly;
  width: 100%;
}
@media (min-width: 540px) {
  .style_social_buttons__5AyyW {
    justify-content: space-between;
  }
  .style_wrapper__1n2HJ {
    padding: 0;
  }
  .style_card__2OAUy {
    padding: 40px;
    background-color: var(--white);
    box-shadow: var(--shadow2dp);
    border: none;
  }
}

.style_container__1e00j {
  background: var(--grey0);
  border-radius: 0.5rem;
  padding: 40px 0;
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
}
.style_wrapper__agX4V {
  width: 100vw;
  max-width: 448px;
  padding: 0 1.5rem;
}

.style_dropdown__3OmpD {
  max-width: 448px;
  width: calc(100vw - 3rem);
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.style_menu__2C0qY {
  max-width: 450px;
  width: calc(100vw - 3rem);
  margin-bottom: 0.25rem;
}

.style_link__3Dzut,
.style_link__3Dzut:hover {
  color: var(--blue50);
}
.style_line__1bX-0 {
  flex: auto;
  background: var(--grey10);
  height: 1px;
  margin: 2rem 0;
}

.style_text_input__1P_N4 {
  margin-bottom: 1.5rem;
}
.style_text_input_zip__36Ig5 {
  margin-bottom: 1.5rem;
  width: 50%;
}

.style_card_cvc_wrapper__322Ff {
  display: flex;
}
.style_card__exp__2QO-T {
  flex: 50% 1;
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
}

@media (min-width: 540px) {
  .style_wrapper__agX4V {
    padding: 0;
  }
}

.style_container__3D8Eu {
  background: var(--grey0);
  border-radius: 0.5rem;
  padding: 40px 0;
  display: flex;
  justify-content: center;
  margin-bottom: 8rem;
}
.style_wrapper__kiFpI {
  width: 100vw;
  max-width: 448px;
  padding: 0 1.5rem;
}

.style_button__1zABc {
  display: flex;
  margin-top: 1.5rem;
}
.style_button__1zABc > span:last-child {
  flex-grow: 1;
}

.style_dropdown__RhrUD {
  max-width: 448px;
  width: calc(100vw - 3rem);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.style_dropdown_item_content__10nmU {
  display: flex;
  align-items: center;
}
.style_dropdown_flag__1oGu0 {
  margin-right: 0.75rem;
}
.style_menu__1Yi-v {
  max-width: 450px;
  width: calc(100vw - 3rem);
  margin-bottom: 0.25rem;
}
.style_menu_flag__3X77S {
  margin: auto;
  margin-left: 0.75rem;
}

.style_line__3NgRn {
  flex: auto;
  background: var(--grey10);
  height: 1px;
  margin: 2rem 0;
}

.style_text_input__2sWCC {
  margin-bottom: 1.5rem;
}
.style_text_input_zip__26rhC {
  margin-bottom: 1.5rem;
  width: 50%;
}

.style_card_cvc_wrapper__2wdQ8 {
  display: flex;
}
.style_card__exp__122V1 {
  flex: 50% 1;
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
}

@media (min-width: 540px) {
  .style_wrapper__kiFpI {
    padding: 0;
  }
}

