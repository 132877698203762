/* OUTLINED STYLE*/
.outlined div[class*="timepicker-input-row"] {
  box-shadow: inset 0 0 0 1px var(--color30);
}
.outlined input[class*="timepicker-input"] {
  color: var(--color100);
}
.outlined span[class*="timepicker-divider-text"] {
  color: var(--color40);
}
.outlined input[class*="timepicker-input"]:focus {
  border: 2px solid var(--color80);
  box-shadow: var(--shadow4dp);
}
/*Arrow Button*/
.outlined div[class*="timepicker-arrow-row"] svg {
  color: var(--color70);
}
.outlined span[class*="timepicker-arrow-button"]:hover svg {
  color: var(--color100);
}

/**/
/*AM/PM*/
.outlined div[class*="ampm-select"] > select {
  background: var(--color5);
  border: none;
  box-shadow: none;
  color: var(--color70);
}
.outlined div[class*="ampm-select"] > span[class*="icon"] {
  color: var(--color70);
}

/*ERROR*/
.outlined input[class*="timepicker-input"][class*="danger"]:focus {
  border: 2px solid var(--red80);
  box-shadow: var(--redShadow4dp);
  /*transition: border 150ms cubic-bezier(0.19, 1, 0.22, 1);*/
}
.outlined input[class*="timepicker-input"][class*="danger"] {
  border: 1px solid var(--red30);
  box-shadow: none;
  /*transition: border 150ms cubic-bezier(0.19, 1, 0.22, 1);*/
}

/* END STYLE */
