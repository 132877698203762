.tablist {
  margin-bottom: 2px;
  overflow: auto;
}
.tablist
  div[class*="bp3-tab-list"]
  > div[class="bp3-tab"][aria-expanded="false"] {
  color: var(--grey70);
}
.badge {
  display: flex;
  align-items: center;
  padding: 0 7px;
  background: var(--grey5);
  color: var(--grey100);
  border-radius: 99px;
  height: 15px;
  margin-right: 6px;
}
.tab_title {
  display: flex;
  align-items: center;
  align-content: center;
}

.tabs_wrapper {
  display: grid;
  margin-top: 1rem;
}

@media screen and (min-width: 640px) {
  .tabs_wrapper {
    margin-top: 0;
    display: block;
  }
}
