/*FLAT STYLE*/
.flat {
  color: var(--color70);
}
/*END STYLE*/

/* FILLED STYLE*/
.filled {
  color: var(--color0);
  background: var(--color70);
}
/* END STYLE */

/*SMOOTH STYLE*/
.smooth {
  color: var(--color70);
  background: var(--color5);
}
/* END STYLE */

/* OUTLINED STYLE*/
.outlined {
  color: var(--color70);
  box-shadow: 0 0 0 1px var(--color30);
}
/* END STYLE */
